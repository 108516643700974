&--url-replacer {
  .search-result {
    padding: 5px;
    height: 40vh;
    @include media-breakpoint-down(sm) {
      height: 60vh;
    }
    overflow-y: scroll;

    &__offer-link {
      display: flex;
      align-items: center;
      gap: 5px;
      margin-bottom: 10px;
    }

    &__url-option {
      margin-top: 5px;
      margin-bottom: 5px;
      padding: 10px;
      background-color: rgba($g-color-secondary, 0.03);
      border-radius: 5px;
      &_highlight {
        background-color: rgba($g-color-purple, 0.15);
        border-radius: 5px;
      }
    }
  }
}
