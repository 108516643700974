.c-offline {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-align: center;
  padding: 10px;

  &__title {
    @include font-headline-1;
    margin-bottom: 10px;
  }

  &__text {
    @include font-body-3;
  }
}
