.c-sidebar {
  $root: &;
  min-width: 250px;
  max-width: 100%;
  height: 100%;
  position: relative;

  @include media-breakpoint-up(sm) {
    margin: 0;
  }

  &__overlay {
    @include media-breakpoint-up(sm) {
      height: 100%;
      width: 100%;
      opacity: 0;
      right: 0;
      top: 0;
      position: fixed;
      transform: translate(250px, 64px);
      z-index: 1;
    }

    @include media-breakpoint-up(lg) {
      background-color: $g-color-light-gray;
      opacity: 0.85;
      position: fixed;
      transform: none;
      left: 0;
      width: 250px;
    }
  }

  &__search {
    padding: 0 20px;
    margin-top: 20px;
    position: relative;
  }

  &__mobile {
    display: block;

    #{$root} {
      &__logo-menu {
        margin: 10px 0;
      }
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__desktop {
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  &__search-results {
    position: absolute;
    top: calc(100% + 10px);
    background-color: $g-color-white;
    border-radius: 5px;
    z-index: 1;
    width: calc(100% - 40px);
    border: 1px solid rgba(0, 14, 75, 0.2);
    box-sizing: border-box;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
    max-height: 300px;
    overflow-y: auto;
    left: 20px;
    @include h-general-transition;
    opacity: 0;
    visibility: hidden;

    &.is-shown {
      opacity: 1;
      visibility: visible;
    }
  }

  &__search-result {
    cursor: pointer;
    @include h-general-transition;
    display: flex;
    align-items: center;
    padding: 0 11px;
    height: 38px;
    border-bottom: 1px solid $g-color-gray;

    &:hover,
    &.is-selected {
      background-color: rgba($g-color-primary, 0.07);
      color: $g-color-primary;
    }

    &:last-child {
      border-bottom: none;
    }

    &.-not-found {
      opacity: 0.5;
    }
  }

  &__inner {
    height: 100%;
    width: 100%;
    flex: none;
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    background-image: url('../../../assets/new-img/sidebar/sidebar-bottom-bg.svg');
    background-repeat: no-repeat;
    background-position: right bottom;

    @include media-breakpoint-up(sm) {
      margin: 0;
    }
  }

  &__header {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid $g-color-gray;

    @include media-breakpoint-up(sm) {
      padding-top: initial;
    }

    @include media-breakpoint-up(lg) {
      align-items: center;
      padding-top: 20px;
      padding-bottom: 0;
      justify-content: space-between;
      border-bottom: none;
    }

    &:hover {
      #{$root}__header-btn::after {
        opacity: 1;
      }
    }
  }

  &__application-mobile {
    display: none;

    @media (max-width: 1024px) {
      display: block;

      .p500-ui-app-selector__header-inner-subtitle {
        text-transform: capitalize;
      }
    }
  }

  &__application-desktop {
    display: none;

    @media (min-width: 1024px) {
      display: block;
    }

    .p500-ui-app-selector .p500-ui-app-selector__inner {
      max-height: 123px;
    }
  }

  &__selector {
    width: 100%;

    @include media-breakpoint-up(lg) {
      width: 70px;
      justify-content: center;
      top: 88px;
      z-index: 12;
    }

    @include media-breakpoint-down(lg) {
      margin: 0 16px;
    }
  }

  &__control {
    @include font-headline-4;
    margin-bottom: 8px;
    color: rgba($g-color-secondary, 0.3);
  }

  &__control-btn,
  &__control-btn.p500-ui-text-button {
    @include font-headline-4;
    color: $g-color-primary;
  }

  &__navigation {
    height: 100%;
    overflow-y: scroll;
    @include h-scrolled;

    &::after {
      display: none;
    }

    @include media-breakpoint-up(lg) {
      top: 0;
      display: block;
    }
  }

  &__footer {
    position: relative;
    margin-top: auto;
    background-color: $g-color-white;
    border-top: 1px solid $g-color-gray;
    padding: 0;
    height: 50px;

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }

  &__pattern {
    display: none;
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    height: 10px;
    overflow: hidden;

    @include media-breakpoint-up(sm) {
      display: initial;
    }
  }

  &-menu {
    display: none;

    &.-isShow {
      display: block;
      margin-top: 10px;
    }
  }

  &__logo {
    flex-flow: column nowrap;
    position: relative;
    display: none;
    background-color: rgba($g-color-primary, 0.05);
    border-radius: 8px;
    padding: 12px;
    width: 100%;

    @include media-breakpoint-up(lg) {
      margin: 0 20px;
      display: flex;
      min-width: 202px;
    }

    &-text {
      width: 100%;
      margin-bottom: 20px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: -8px;
        left: 0;
        right: 0;
        border-bottom: 1px solid #d1ddf3;
      }
    }

    &-inner {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-title {
      @include font-headline-6;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      @include media-breakpoint-up(lg) {
        margin-bottom: 2px;
      }
    }
  }

  &__info {
    display: none;

    @include media-breakpoint-up(sm) {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
    }
  }

  &__tickers {
    z-index: 2;
  }

  &__datetime {
    z-index: 2;
    color: $g-color-primary;
  }

  &__notifications {
    z-index: 2;
    margin-left: auto;
  }

  &__account {
    display: flex;
    align-items: center;
  }

  &__logout {
    margin-left: auto;
  }

  &__navigation-footer {
    display: flex;
    margin-left: 15px;
    gap: var(--Space-400, 12px);

    .c-field-value,
    .c-link {
      @include font-headline-5;
    }
  }

  &__navigation-copyright {
    @include font-body-4;
    color: #757a90;
    gap: 4px;
    display: flex;
  }

  &__navigation-version,
  &__navigation-version:hover {
    color: #757a90;
  }

  &__navigation-button {
    background-color: $g-color-white;
    color: var(--Text-secondary, #3b4261);
    border: 1px solid var(--Border-tertiary, #dedfe4);
    padding: 0;
    width: var(--Space-900, 32px);

    &.-collapsed {
      margin-bottom: 28px;
    }
  }

  &__get-app-button {
    border: none;
    background: var(--Bg-alt-default, #e7effe);
    color: var(--Text-brand, #0f5ef7);
  }

  &__navigation-button,
  &__get-app-button {
    @include font-headline-4;
    border-radius: var(--Radius-medium, 4px);
    display: flex;
    height: var(--Space-900, 32px);
    padding: 6px var(--Space-500, 16px);
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  &__navigation-footer-buttons {
    display: flex;
    gap: var(--Space-500, 16px);
  }

  &.-open-selector {
    @include media-breakpoint-up(lg) {
      #{$root} {
        &__overlay {
          top: 60px;
        }
      }
    }
  }
}
