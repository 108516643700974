.c-form-field {
  $root: &;
  position: relative;
  display: block;

  label {
    margin: 0;
  }

  &__label {
    cursor: pointer;
    user-select: none;
  }

  &--collapse-button {
    @include h-general-transition(background-color, color);
    border-radius: 8px;
    background-color: rgba($g-color-primary, 0.1);
    border: none;
    color: $g-color-primary;
    width: 29px;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: $g-color-white;
      background-color: $g-color-primary;
    }
  }

  &--remove-button {
    display: flex;
    color: $g-color-white;
    border: none;
    border-radius: 8px;
    padding: 8px;

    background-color: rgba($g-color-red, 0.1);

    .p500-ui-icon {
      color: $g-color-red;
    }

    &:focus,
    &:hover {
      background-color: $g-color-red;

      .p500-ui-icon {
        color: $g-color-white;
      }
    }
  }

  @import 'c-form-field--d';
  @import 'c-form-field--select';
  @import 'c-form-field--search';
  @import 'c-form-field--state--error';
  @import 'c-form-field--search-wiki';
  @import 'c-form-field--clipboard';
  @import 'c-form-field--img-uploader';
  @import 'c-form-field--upload';
  @import 'c-form-field--condition';
  @import 'c-form-field--accordion-checkbox';
  @import 'c-form-field--card-settings';
  @import 'c-form-field--group-checkbox';
}
