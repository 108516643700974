@mixin h-gradient-1 {
  background: linear-gradient(to bottom, rgba(242, 242, 242, 0.4) 0%, rgba(255, 255, 255, 0) 35%);
}

@mixin h-gradient-2 {
  background: linear-gradient(to bottom, rgba(242, 242, 242, 0.4) 0%, rgba(255, 255, 255, 0) 35%), white;
}

@mixin h-gradient-3 {
  background: linear-gradient(to bottom, rgba(242, 242, 242, 0.6) 0%, rgba(255, 255, 255, 0) 70%), white;
}
