.c-card {
  $root: &;
  border-radius: $g-value-border-radius-xl;

  .c-card__image-container {
    width: 100%;
    .c-card__image-funnel {
      width: 100%;
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &__layout {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr;
    overflow: scroll;

    + .c-table__empty-block {
      border: none;
    }

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    }

    &.-layout-4 {
      @include media-breakpoint-up(md) {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      }
    }
  }

  &__inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 18px;
    border: 1px solid $g-color-gray;
    border-radius: $g-value-border-radius-xl;
    min-height: 144px;
    &:hover {
      #{$root}__button {
        opacity: 1;
      }
    }
  }

  &__condition {
    padding: 0 5px;
    background-color: $g-color-gray;
    border-radius: 5px;
  }

  @import 'c-card--wiki';
  @import 'c-card--terms';
  @import 'c-card--new-terms';
  @import 'c-card--settings';
  @import 'c-card--profile';
}
