@mixin skeleton($startColor: #ececec, $secondColor: #f8f8fb) {
  background-image: linear-gradient(110deg, $startColor 8%, $secondColor 18%, $startColor 33%);
  background-size: 200% 100%;
  animation: 1.2s skeleton-loading linear infinite;
}

@keyframes skeleton-loading {
  to {
    background-position-x: -200%;
  }
}
