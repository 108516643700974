.c-many-data {
  @include font-body-5;
  background-color: rgba(0, 9, 50, 0.03);
  border-radius: 10px;
  padding: 5px 10px;
  min-width: 150px;

  &__item {
    display: flex;

    &.-title {
      @include font-headline-6;
    }
  }

  &-popup {
    &.p500-ui-many-items__show.-container {
      border-radius: 10px;
    }

    .p500-ui-many-items__show-wrapper.-hint {
      padding: 5px;
    }
  }
}
