&--groups {
  #{$root} {
    &__header {
      align-items: flex-end;
      margin-bottom: 20px;
    }

    &__subheader {
      margin-bottom: 18px;
      padding: 10px 18px;
      border: 1px solid $g-color-gray;
      border-radius: 10px;
      display: flex;
      flex-direction: column;

      .dot {
        width: 4px;
        height: 4px;
        margin: 0 14px;
        vertical-align: middle;
        display: none;

        @include media-breakpoint-up(mx) {
          display: inline-block;
        }
      }

      .icon {
        color: $g-color-yellow;
        vertical-align: middle;
        margin-left: 8px;
        // todo: icon font-size
        font-size: 14px;

        &.-transparent {
          opacity: 0.3;
        }
      }

      @include media-breakpoint-up(mx) {
        flex-direction: row;
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      margin-bottom: 18px;

      border: none;

      @include media-breakpoint-up(mx) {
        flex-direction: row;
      }
    }

    &__body-wrapper {
      flex-grow: 1;
      margin-bottom: 10px;

      overflow-x: auto;

      @include media-breakpoint-up(mx) {
        &:not(:last-of-type) {
          margin-right: 18px;
        }
      }
    }

    &__table {
      width: 100%;

      border-collapse: separate;
      border: 1px solid $g-color-gray;
      border-radius: 10px;
      border-spacing: 0;
    }

    &__table-head {
      background-color: rgba($color: $g-color-secondary, $alpha: 0.02);

      th {
        padding: 16px 18px;

        border-bottom: 1px solid rgba($color: $g-color-gray, $alpha: 0.4);
      }
    }

    &__table-body {
      tr:last-of-type {
        td {
          border-bottom: none;
        }
      }
    }

    &__table-row,
    &__table-title {
      td {
        padding: 8px 18px;
        border-bottom: 1px solid rgba($color: $g-color-gray, $alpha: 0.4);

        &:not(:last-of-type) {
          width: 150px;

          border-right: 1px solid rgba($color: $g-color-gray, $alpha: 0.4);
        }
      }
    }

    &__table-title {
      td {
        @include font-headline-4;
        color: rgba($color: $g-color-secondary, $alpha: 0.5);
      }
      td.bg-color_grey {
        background-color: rgba($g-color-light-gray, 0.2);
        color: rgba($g-color-secondary, 0.5);
      }
    }

    &__table-row {
      color: $g-color-secondary;

      &:last-of-type {
        td {
          border-bottom: none;
        }
      }

      &.-empty {
        height: 37px;

        td {
          border-bottom: none;
        }
      }

      td.bg-color_grey {
        font-size: 13px;
        font-weight: 700;
        background-color: rgba($g-color-light-gray, 0.2);
        border-right: 1px solid $g-color-gray;
        border-top: none;
      }
      td {
        .color_grey {
          color: rgba($g-color-secondary, 0.3);
        }
      }
    }

    &__footer {
      position: relative;
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      padding: 12px 40px;
      background-color: #e7efff;
      align-items: center;
      flex-flow: column;
      border-radius: 10px;

      @include media-breakpoint-up(md) {
        margin: 0;
      }

      @media (min-width: 795px) {
        margin: 0;
        flex-flow: row nowrap;
        align-items: center;
      }

      @include media-breakpoint-up(lg) {
        padding: 16px 60px;
        padding-bottom: 6px;
        @media (min-width: 1025px) and (max-width: 1121px) {
          padding: 16px 40px;
        }
      }

      &:before {
        content: '';
        position: absolute;
        top: 8px;
        left: 8px;
        width: 15px;
        height: 15px;
        background-color: #0061ff;
        border-bottom-right-radius: 15px;
      }

      &:after {
        content: '';
        position: absolute;
        top: 8px;
        right: 8px;
        width: 15px;
        height: 15px;
        background-color: #000e4b;
        border-bottom-left-radius: 15px;
      }
    }

    &__help-links {
      display: flex;
      margin: 5px 0;
      @media (min-width: 795px) {
        margin: 0;
      }
    }

    &__help-card-title {
      @include font-headline-4;
      text-align: center;
      margin: 5px 0;
      @media (min-width: 795px) {
        margin: 0 40px 0 0;
      }
      @media (min-width: 1025px) and (max-width: 1121px) {
        white-space: nowrap;
        margin-right: 8px;
      }
    }

    &__help-link {
      @include font-headline-4;
      position: relative;
      display: inline-flex;
      align-items: center;
      text-decoration: none;
      white-space: nowrap;
      color: $g-color-primary;

      &:not(:last-child) {
        margin-right: 20px;
        @media (min-width: 1025px) and (max-width: 1121px) {
          margin-right: 8px;
        }
      }
    }

    &__icon {
      margin-right: 6px;
      // todo: icon font-size
      font-size: 12px;
    }
  }
}
