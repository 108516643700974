.c-empty {
  $root: &;

  min-height: 190px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: calc(1% - 33px);
  padding: 33px 15px 15px;
  border-bottom: 1px solid $g-color-gray;
  border-left: 1px solid $g-color-gray;
  border-right: 1px solid $g-color-gray;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  &.-no-border {
    border: none;
  }

  #{$root} {
    &__inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 250px;
    }

    &__icon {
      margin-bottom: 20px;
    }

    &__desc {
      @include font-body-4;
      text-align: center;
      margin-bottom: 14px;
      white-space: pre-wrap;
    }
  }
}
