.c-form {
  $root: &;

  .swap__btns__wrapper {
    display: flex;
    gap: 3px;
    margin-left: 10px;

    .swap__btn {
      @include reset-btn;
      color: rgba($g-color-secondary, 0.2);

      &:hover {
        color: $g-color-primary;
      }
    }
  }

  .csv-import-add-button {
    width: auto;
    height: 24px;
    span {
      @include font-headline-4;
    }
  }

  @import 'c-form--d';
  @import 'c-form--builder';
}
