&--info-new {
  @include font-body-4;
  max-width: 100%;
  display: grid;
  grid-template-columns: 6fr 5fr 2fr;
  column-gap: 20px;
  row-gap: 20px;
  grid-template-areas: 'first second third';

  & .-first {
    grid-area: first;
  }
  & .-second {
    grid-area: second;
  }
  & .-third {
    grid-area: third;
  }

  @media (max-width: 1910px) {
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(2, auto);
    grid-template-areas:
      'first second'
      'first third';
  }

  @media (max-width: 1440px) {
    grid-template-rows: repeat(2, auto);
    grid-template-areas:
      'first second'
      'third third';
  }

  @media (max-width: 1220px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
      'first'
      'second'
      'third';
  }

  &--block {
    padding: 18px;
    border-radius: 10px;

    @include media-breakpoint-down(md) {
      max-width: 100%;
    }

    &.-dark {
      background-color: rgba(25, 48, 144, 0.06);
    }

    &.-white {
      border: 1px solid $g-color-gray;
    }
  }

  &--cards {
    display: grid;
    margin-top: 10px;

    &.-basic {
      column-gap: 20px;
      row-gap: 20px;
      grid-template-columns: repeat(3, auto);
      grid-template-areas: 'first-basic-card second-basic-card third-basic-card';

      & .-first-basic-card {
        grid-area: first-basic-card;
      }
      & .-second-basic-card {
        grid-area: second-basic-card;
      }
      & .-third-basic-card {
        grid-area: third-basic-card;
      }

      @media (max-width: 1910px) {
        grid-template-columns: repeat(2, auto);
        grid-template-rows: repeat(2, 1fr);
        grid-template-areas:
          'first-basic-card second-basic-card'
          'third-basic-card third-basic-card';
      }

      @media (max-width: 1220px) and (min-width: 1080px), (max-width: 1024px) and (min-width: 820px) {
        grid-template-columns: repeat(3, auto);
        grid-template-rows: 1fr;
        grid-template-areas: 'first-basic-card second-basic-card third-basic-card';
      }

      @media (max-width: 540px) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, auto);
        grid-template-areas:
          'first-basic-card'
          'second-basic-card'
          'third-basic-card';
      }
    }

    &.-info {
      column-gap: 20px;
      grid-template-columns: repeat(3, auto);
      grid-template-areas: 'first-info-card second-info-card third-info-card';

      & .-first-info-card {
        grid-area: first-info-card;
      }
      & .-second-info-card {
        grid-area: second-info-card;
      }
      & .-third-info-card {
        grid-area: third-info-card;
      }

      @media (max-width: 1440px) {
        grid-template-columns: repeat(2, auto);
        grid-template-rows: repeat(2, 1fr);
        column-gap: 20px;
        grid-template-areas:
          'first-info-card second-info-card'
          'third-info-card third-info-card';
      }

      @media (max-width: 1220px) {
        grid-template-columns: repeat(3, auto);
        grid-template-rows: 1fr;
        grid-template-areas: 'first-info-card second-info-card third-info-card';
      }

      @media (max-width: 540px) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, auto);
        grid-template-areas:
          'first-info-card'
          'second-info-card'
          'third-info-card';
      }
    }
  }

  &--card {
    gap: 10px;

    &.-white {
      background-color: rgba($g-color-white, 0.8);
      padding: 10px;
      border-radius: 10px;
    }
  }

  &--row {
    display: flex;
    flex-direction: row;
    gap: 4px;

    & > span {
      color: rgba($g-color-black, 0.5);
      margin-right: 8px;
    }
  }

  .title {
    @include font-headline-2;
    margin-bottom: 10px;

    .-purple {
      color: rgba(102, 51, 203, 1);
    }

    .icon {
      margin-right: 10px;
      @include font-body-3;
    }
  }

  &.-quick-view {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
      'first'
      'second'
      'third';

    @media (max-width: 480px) {
      .-third {
        margin-bottom: 70px;
      }
    }
  }
}
