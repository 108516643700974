@include keyframes(slideInDown) {
	0% {
  	opacity: 0;
  	@include transform(translateY(-2000px));
  }
	100% {
  	@include transform(translateY(0));
  }
}

@mixin slideInDown($count: $countDefault, $duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(slideInDown);
  @include count($count);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
