.c-widget {
  $root: &;
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
  position: relative;

  .flex-1 {
    flex: 1;
  }

  &__table-cell {
    &.changed {
      background-color: rgba($g-color-purple, 0.14);
    }
  }

  &--d {
    #{$root} {
      &__body {
        padding: 18px;

        &--dashboard-chart {
          padding: 15px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }
  }

  .-quick-view {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    padding-bottom: 70px;

    ul {
      margin: 0 !important;
    }

    @include media-breakpoint-up(md) {
      grid-template-columns: 1fr 1fr;
      padding-bottom: 30px;
    }
  }

  &__body {
    margin: 0;
    position: relative;
    border: 1px solid $g-color-gray;
    padding-left: 0;
    padding-right: 0;
    overflow-x: auto;
    border-radius: $g-value-border-radius-xl;
    @include h-scrolled;

    @include media-breakpoint-up(md) {
      margin: 0;
      background-color: $g-color-white;
    }

    &.-statistics {
      .p500-ui-cube-preloader {
        margin: 25px 0;
      }
    }

    &.-white-bg {
      background-color: $g-color-white;
    }

    &.-gradient {
      border: none;
      @include h-gradient-2;

      ul {
        border: none;
      }
    }

    &.-scroll-x {
      overflow-x: scroll;
    }

    &--info {
      margin: 0;
      border-radius: $g-value-border-radius-xl;
      background-color: transparent;
    }

    &--mobile-widget {
      overflow-x: auto;
      position: relative;

      #{$root}__settings {
        cursor: pointer;
        position: absolute;
        top: 17px;
        right: 24px;
        z-index: 2;
        height: 20px;
        width: 20px;

        &,
        &:hover {
          background-color: $g-color-black;
        }

        @include media-breakpoint-down(md) {
          top: 10px;
          right: 19px;
        }
      }

      @include media-breakpoint-down(md) {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
        border-radius: $g-value-border-radius-xl;
        border: 1px solid $g-color-gray;
      }
    }

    .data-empty {
      @include font-body-4;
      color: rgba($g-color-secondary, 0.5);
      text-align: center;
    }

    &.-no-overflow-x {
      overflow-x: initial;
    }

    &.-no-bd {
      border: none;
    }

    &.-no-bg {
      background: none;
    }

    &.-no-bd-r {
      border-radius: 0;
    }

    &.-no-bd-r-top {
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      table {
        thead {
          th {
            border-left: none;
            background: $g-color-white;

            &:first-child {
              border-top-left-radius: 0;
            }

            &:last-child {
              border-top-right-radius: 0;
            }
          }
        }
      }
    }

    &.-radio-group-justify-start {
      p500-ui-radio-group .p500-ui-form-field__inner {
        justify-content: flex-start;
      }
    }

    &.-range-datepicker-column {
      p500-ui-range-datepicker-field-new {
        .p500-ui-form-field__daterange {
          width: fit-content;
        }

        .p500-ui-datepicker {
          flex-direction: column;
          gap: 12px;

          .c-date-interval.-reverse {
            margin-left: 0;

            .c-tabs {
              flex-wrap: wrap;
              gap: 8px;

              .c-tabs__item {
                margin-right: 0;
              }
            }
          }
        }
      }
    }

    .c-table {
      padding-top: 8px;

      @include media-breakpoint-up(sm) {
        padding-top: 0;
      }
    }

    .-gray-text {
      color: $g-color-gray-text;
    }
  }

  &__footer {
    overflow: scroll;
    @include h-scrolled;
  }

  &__actions-button {
    width: 30px;
    cursor: pointer;
    height: 30px;
    // todo: icon font-size
    font-size: 6px;
    display: none;
    text-align: center;
    line-height: 28px;
    color: $g-color-primary;
    border-radius: 8px;
    background-color: $g-color-light-blue;

    &.is-active {
      color: $g-color-white;
      background-color: $g-color-primary;
    }

    &.is-active + #{$root}__actions {
      display: flex;
    }

    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  &__action {
    display: flex;
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }

  &--is--loading {
    .c-widget__header {
      z-index: 21;
    }
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 18px;

    #{$root} {
      &__content-right {
        position: relative;
        display: flex;
        align-items: center;

        #{$root}__compare-dates {
          min-width: 240px;
        }
      }

      &__content-left {
        white-space: nowrap;
        display: flex;
        align-items: center;
        margin-right: 15px;

        .header__groups {
          @include font-headline-6;
          padding: 0 12px;
          border-left: 1px solid #e6e6e6;
        }

        .c-button--menu-section {
          @include font-headline-6;
          margin-left: 6px;
          height: 26px;
          width: 130px;
          padding: 5px 10px;
          justify-content: space-between;

          .-color-secondary:after {
            opacity: 1;
            font-weight: 400;
          }
        }
      }
    }
  }

  &__header-flex {
    display: flex;
    max-width: 100%;
    gap: 10px;
    margin-bottom: 20px;
    flex-wrap: wrap;

    &__title {
      display: flex;
      align-items: center;
      min-width: 150px;
    }
    &__tabs {
      display: flex;
      justify-content: end;
      gap: 10px;
      align-items: center;
      white-space: nowrap;
      margin-left: auto;
      flex-grow: 1;
      flex-wrap: wrap;
    }
    &__dates {
      flex-grow: 1;
    }

    #{$root}__compare-dates {
      min-width: 240px;
    }
  }

  &__title {
    @include font-headline-2;
    margin-right: 8px;

    &.-big {
      @include font-headline-1;
      margin-right: 14px;
      display: none;

      @include media-breakpoint-up(md) {
        display: initial;
      }
    }

    &.-dashboard {
      margin-right: 8px;
    }
  }

  &__subtitle {
    @include font-headline-4;

    & .-disabled {
      color: $g-color-text-disabled;
    }
  }

  &__simple-table-label {
    @include font-headline-4;
  }

  &__simple-table-description {
    @include font-body-3;
  }

  &__simple-table {
    .hover-highlight:hover {
      background-color: $g-color-gray-no-opacity;
    }
    .col-4 {
      min-width: 80px;
    }
  }

  &__add-icon {
    margin-right: 5px;
  }

  &__actions-icon {
    display: flex;

    gtd-action-button {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__link {
    color: $g-color-primary;
    @include h-general-transition(color);

    &:hover {
      cursor: pointer;
      color: $g-color-primary-hover;
    }
  }

  &__content-right {
    margin-left: auto;
  }

  &__header-btn {
    margin-right: 8px;
    display: flex;
    justify-items: center;

    &:last-child {
      margin-right: 0;
    }

    &--separator {
      width: 1px;
      height: 20px;
      background-color: $g-color-gray;
    }

    &.-big {
      margin-right: 14px;
    }
  }

  &__data-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba($g-color-secondary, 0.5);
    width: 100%;
  }

  &.-overflow-x {
    overflow-x: scroll;
    @include h-scrolled;
  }

  &__links {
    display: flex;
  }

  &__preloader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .c-preloader {
      margin: 0;
    }
  }

  &.-content-width {
    #{$root} {
      &__table {
        table {
          min-width: auto;
          white-space: nowrap;
        }
      }
    }
  }

  &__empty {
    padding: 50px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__empty-inner {
    width: 240px;
    max-width: 100%;
    text-align: center;
  }

  &__empty-icon {
    // todo: icon font-size
    font-size: 30px;
    margin-bottom: 20px;

    img {
      width: 40px;
      height: 40px;
      background-size: contain;
    }

    &.-circled {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: rgba($g-color-secondary, 0.1);
      // todo: icon font-size
      font-size: 14px;
    }
  }

  &__tab {
    @include font-headline-4;

    &:not(:last-child) {
      margin-right: 20px;
    }

    .p500-ui-button {
      padding: 0;
    }
  }

  &.c-widget--primary {
    .c-widget {
      &__body {
        background-color: #0f5ef7;
      }

      &__item {
        border-right: 1px solid #3a79f4;
        padding-left: 30px;
        padding-right: 30px;

        &--opened {
          margin-right: 0;
        }

        @include media-breakpoint-down(md) {
          border-right: none;
        }
      }

      &__key {
        margin-bottom: 6px;
        opacity: 1;

        i {
          color: #b7cefd;
          padding-right: 8px;
        }
      }

      &__label {
        color: #b7cefd;
      }

      &__value {
        color: #fff;
      }

      &--statistic__button {
        &-last {
          background-image: none;
          mask-image: url('../../../assets/new-img/icons/left.svg');
        }

        &-next {
          background-image: none;
          mask-image: url('../../../assets/new-img/icons/right.svg');
        }
      }
    }
  }

  &__remove-button {
    width: 24px;
    height: 24px;
    display: flex;
    color: $g-color-white;
    border: none;
    border-radius: 5px;
    padding: 6px;
    background-color: rgba($g-color-red, 0.1);
    .p500-ui-icon {
      color: $g-color-red;
    }
    &:focus,
    &:hover {
      background-color: $g-color-red;
      .p500-ui-icon {
        color: $g-color-white;
      }
    }
  }

  &__add-button {
    &.p500-ui-button--big {
      width: 90px;
      height: 24px;
    }
    span {
      @include font-headline-4;
    }

    &.w-auto {
      width: auto;
    }
  }

  &__label-with-button {
    display: flex;
    justify-content: space-between;

    @media (max-width: 375px) {
      flex-direction: column;

      .c-widget__title {
        margin-bottom: 12px;
      }
    }
  }

  &__sticky {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  @import 'c-widget__table';
  @import 'c-widget--new-statistic';
  @import 'c-widget--info';
  @import 'c-widget--info-new';
  @import 'c-widget--chart';
  @import 'c-widget--body-simple';
  @import 'c-widget--permissions';
  @import 'c-widget--table-facade';
  @import 'c-widget__tab';
  @import 'c-widget--reports-creator';
  @import 'c-widget--dashboard-link-generator';
  @import 'c-widget__dashboard-top';
  @import 'c-widget--groups';
  @import 'c-widget--lead-profile';
}
