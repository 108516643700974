.tree {
  $root: &;
  $color-orange: #ef5736;
  $name-width: 23vw;
  $name-min-width: 400px;
  $pql-width: 17vw;
  $pql-min-width: 300px;
  $settings-width: 14vw;
  $settings-min-width: 250px;
  $row-section-padding: 15px;

  background-color: rgba($g-color-dark-blue, 0.03);
  padding: 0 32px;
  z-index: 1024;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: -$x-main-content-mobile-padding;
  margin-right: -$x-main-content-mobile-padding;

  @include media-breakpoint-up(md) {
    margin-left: -$x-main-content-table-padding;
    margin-right: -$x-main-content-table-padding;
  }

  @include media-breakpoint-up(lg) {
    margin-left: -$x-main-content-padding;
    margin-right: -$x-main-content-padding;
  }

  &-main {
    overflow: hidden;
    height: 100%;

    gtd-flat-tree {
      display: block;
      height: 100%;

      #{$root}-wrapper {
        height: 100%;

        cdk-virtual-scroll-viewport {
          min-height: 300px;
          height: 100%;
        }
      }
    }
  }

  @import './header';
  @import './home-header';
  @import './row';
  @import './states';
}
