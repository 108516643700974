&--new-version {
  position: absolute;
  width: 100%;
  display: flex;
  padding: var(--Space-400, 12px) 0;
  justify-content: center;
  align-items: center;
  gap: var(--Space-400, 12px);
  top: 0;
  z-index: 1020;
  border-radius: 0 0 var(--Radius-large, 8px) var(--Radius-large, 8px);
  border-bottom: 1px solid #b8d6ff;
  background: #f3f8ff;
  box-shadow: 0 var(--Elevation-xs-y, 2px) var(--Elevation-xs-blur, 6px) 0 var(--Shadow-primary, rgba(0, 9, 50, 0.15));

  &__text {
    @include font-headline-3;
    display: flex;
    gap: 4px;
  }

  @media (max-width: 480px) {
    flex-direction: column;

    &__text {
      flex-direction: column;

      span {
        display: flex;
        justify-content: center;
      }
    }
  }
}
