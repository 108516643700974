.c-currency-select {
  &__hint {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 24px;
    border-radius: 50%;
    font-size: 12px;
    margin-left: 8px;
    color: $g-color-secondary;
    background-color: $g-natural-light-gray;
    cursor: pointer;
    border: none;
    &:hover {
      color: $g-color-white;
      background-color: $g-color-secondary;
    }
  }

  &__beta {
    // todo: use new mixin
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 8px;
    background-color: $g-color-primary;
    color: $g-color-white;
    border-radius: 360px;
    padding: 0 4px;
    height: 16px;
  }
}
