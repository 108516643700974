.с-pql-field-new {
  $root: &;

  position: relative;
  background-color: transparent;
  z-index: 1;
  white-space: nowrap;

  &:before {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: -2px;
    right: -2px;
    z-index: -1;
  }

  &.-cyan {
    &:before {
      background-color: $g-color-light-cyan;
    }
  }

  &.-violet {
    &:before {
      background-color: $g-color-light-purple;
    }
  }

  &.-orange {
    &:before {
      background-color: $g-color-light-orange;
    }
  }

  &.-yellow {
    &:before {
      background-color: $g-color-light-yellow;
    }
  }

  &.-green {
    &:before {
      background-color: $g-color-light-green;
    }
  }

  &.-gray {
    &:before {
      background-color: $g-color-light-gray2;
    }
  }

  @import 'c-pql-field--typed-new';
}
