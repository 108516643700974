&--tool {
  padding-top: 22px;
  padding-right: 0;

  @include media-breakpoint-up(xl) {
    padding-right: 32px;
  }

  .c-form-field {
    min-width: 150px;
  }

  #{$root} {
    &__inner {
      &.-opacity-table {
        opacity: 0;
      }
    }

    &__rules-section {
      &:first-child {
        margin-top: 12px;
      }
    }

    &__head {
      margin-bottom: 18px;

      &.-flex {
        display: flex;
        justify-content: space-between;
      }

      &__with-button {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    &__title {
      @include font-headline-2;

      &--back-icon {
        display: inline;
        vertical-align: middle;
      }
    }

    &__result {
      .input {
        @include font-headline-4;
        width: 100%;
        color: $g-color-secondary;
        padding: 0 12px;
        border-radius: 5px;
        min-height: 34px;
        display: flex;
        align-items: center;
        background-color: rgba($g-color-secondary, 0.02);

        .right {
          margin-left: auto;
        }
      }
    }

    &__delete-selector {
      position: absolute;
      right: -32px;
      top: 27px;
    }

    &__subtitle {
      @include font-body-4;
      color: rgba($g-color-secondary, 0.5);
    }

    &__body {
      margin-bottom: 18px;

      @include media-breakpoint-up(md) {
        margin-bottom: 28px;
      }
    }

    &__body-title {
      @include font-headline-3;
      margin-bottom: 18px;
      display: flex;

      .title {
        margin-right: 8px;
      }

      .actions {
        display: flex;
        align-items: center;
      }

      .action {
        display: flex;
      }

      .subtitle {
        margin-top: 6px;
        color: rgba($g-color-black, 0.3);
      }
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: center;

      @include media-breakpoint-up(sm) {
        justify-content: flex-start;
      }
    }

    &__action {
      margin-right: 10.5px;

      &:last-child {
        margin-right: 0;
      }
    }

    &__form-section {
      &:not(:last-child) {
        margin-bottom: 18px;
      }

      &.-radio-group {
        .p500-ui-form-field__inner {
          justify-content: start;
          flex-direction: row-reverse;
        }
      }

      &.-radio {
        .c-form-field__body {
          margin: 0 -15px;
          padding: 20px 15px;
          background: rgba($g-color-secondary, 0.02);

          @include media-breakpoint-up(md) {
            margin: initial;
            padding: initial;
            background: initial;
          }
        }
      }

      .-number-icon {
        @include font-headline-4;
        color: $g-color-violet;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .-number-icon-bg {
        background-color: rgba($g-color-violet, 0.1);
        border-radius: 5px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
      }

      .-required::after {
        color: $g-color-red;
        opacity: 0.6;
        margin-left: 2px;
        content: '*';
      }

      .-dark-bg {
        background-color: rgba($g-color-secondary, 0.02);
        border-radius: 4px;

        .p500-ui-form-field__hint {
          background-color: $g-color-white;

          &:hover {
            color: $g-color-white;
            background-color: $g-color-secondary;
          }
        }

        &.-radio-group {
          padding: 2px 8px;
        }

        &.-types {
          padding: 14px 14px 0;
        }
      }

      &.-view-card {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .c-view-card {
          max-width: 100%;
          flex: 1;

          &:not(:last-child) {
            margin-bottom: 18px;
          }
        }
      }
    }

    &__form-info {
      margin-bottom: 0;
      padding-left: 0;

      &:not(:last-child) {
        padding-bottom: 18px;
      }

      > li {
        list-style-type: none;
        position: relative;
        padding-left: 14px;
        @include font-body-3;

        &::before {
          content: '';
          display: block;
          background: $g-color-secondary;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 8px;
        }
      }
    }

    &__row {
      &.-company {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;

        @include media-breakpoint-up(md) {
          border-top: 1px solid rgba($g-color-gray, 0.4);
          border-bottom: 1px solid rgba($g-color-gray, 0.4);
        }

        #{$root} {
          &__col {
            display: flex;
            flex-direction: column;
            flex: 0 0 100%;
            max-width: 100%;

            &:first-child {
              margin-bottom: 20px;
            }

            @include media-breakpoint-up(md) {
              padding: 20px 30px;
              flex: 0 0 50%;
              max-width: 50%;

              &:first-child {
                padding-left: 0;
                border-right: 1px solid rgba($g-color-gray, 0.4);
                margin-bottom: 0;
              }

              &:last-child {
                padding-right: 0;
              }
            }
          }

          &__form-section {
            order: 1;

            &:last-child:not(:first-child) {
              margin-bottom: 18px;
            }

            @include media-breakpoint-up(md) {
              order: initial;

              &:last-child:not(:first-child) {
                margin-bottom: 0;
              }
            }
          }

          &__form-info {
            order: 2;
            background: rgba($g-color-secondary, 0.02);
            padding-left: 15px;
            padding-right: 15px;
            margin-right: -15px;
            margin-left: -15px;

            &.-first {
              padding-top: 20px;
              @include media-breakpoint-up(md) {
                padding-top: 0;
              }
            }

            &.-second {
              padding-bottom: 20px;
              @include media-breakpoint-up(md) {
                padding-bottom: 0;
              }
            }

            @include media-breakpoint-up(md) {
              order: initial;
              background: initial;
              padding-left: initial;
              padding-right: initial;
              margin-right: initial;
              margin-left: initial;
            }
          }
        }
      }
    }

    &__rules {
      padding: 20px 0;
      border-top: 1px solid $g-color-gray;

      &.-last {
        border-bottom: 1px solid $g-color-gray;
        margin-bottom: 18px;
      }

      &-head {
        display: flex;
      }

      &-title {
        @include font-headline-3;
        margin-right: 10px;
      }

      &-section {
        width: calc(100% - 32px);

        &:not(:last-child) {
          margin-bottom: 18px;
        }
      }
    }
  }

  &.-table {
    padding-top: 0;
    height: 100%;

    @include media-breakpoint-up(xl) {
      padding-right: 0;
    }

    position: relative;
    #{$root} {
      &__bg,
      &__inner {
        margin: 0 -15px;

        @include media-breakpoint-up(md) {
          margin: initial;
        }
      }

      &__inner {
        height: 100%;
        padding-top: 18px;
        padding-left: 15px;
        padding-right: 15px;

        @include media-breakpoint-up(md) {
          padding-left: 32px;
          padding-right: 32px;
        }
      }

      &__bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($g-color-secondary, 0.02);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .c-button {
          transform: rotate(90deg);

          @include media-breakpoint-up(md) {
            transform: initial;
          }
        }

        @include media-breakpoint-up(md) {
          flex-direction: row;
        }
      }

      &__text {
        @include media-breakpoint-up(sm) {
          margin-left: 10px;
        }
      }
    }
  }

  &.-gray {
    #{$root} {
      &__inner {
        background-color: rgba($g-color-secondary, 0.02);
        color: $g-color-secondary;
      }
    }
  }
}
