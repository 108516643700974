.c-auth-form {
  background-color: $g-color-white;
  border-radius: 0;
  display: flex;
  box-shadow: 0 20px 100px 0 rgba(0, 0, 0, 0.15);
  height: calc(100% - 54px);
  align-items: center;

  @include media-breakpoint-up(md) {
    border-radius: $g-value-border-radius-xl;
    height: initial;
    align-items: initial;
  }

  &__inner {
    text-align: center;
    width: 100%;
    padding: 30px 50px;

    @include media-breakpoint-up(md) {
      padding: 30px;
      text-align: initial;
      width: auto;
    }

    @include media-breakpoint-up(mx) {
      padding: 60px;
    }
  }

  &__title {
    @include font-headline-1;
    margin-bottom: 30px;
  }

  &__form {
    margin-bottom: 30px;
  }

  &__footer {
    padding-top: 30px;
    border-top: 1px solid $g-color-gray;

    span {
      color: rgba($g-color-secondary, 0.5);
      margin-right: 2px;
    }
  }

  &__loading {
    &::after {
      overflow: hidden;
      display: inline-block;
      vertical-align: bottom;
      animation: ellipsis steps(4, end) 1s infinite;
      content: '\2026'; /* ascii code for the ellipsis character */
      width: 0;
    }

    @keyframes ellipsis {
      to {
        width: 1.1em;
      }
    }
  }

  &__input {
    margin: 0 auto 22px;
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 220px;
    }
  }

  &__image {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    display: none;
    justify-content: center;
    width: 100%;
    background-color: $g-color-light-blue;

    &.-cube {
      background-image: url('../../../assets/new-img/art/cube.svg');
      background-repeat: no-repeat;
      background-size: cover;
    }

    @include media-breakpoint-up(md) {
      display: flex;
    }

    img {
      width: 90%;

      @include media-breakpoint-up(mx) {
        width: initial;
      }
    }
  }
}

.c-auth-form__link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px 30px;
  color: $g-color-white;
  background-color: $g-color-primary;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  @include h-general-transition(background-color);

  @include media-breakpoint-up(md) {
    left: 5%;
    width: 89%;
    padding: 20px 30px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  @include media-breakpoint-up(mx) {
    width: 675px;
    left: 30px;
  }

  &:hover {
    color: $g-color-white;
    text-decoration: none;
    background-color: $g-color-primary-hover;
  }

  i {
    font-size: 8px;
    float: right;
  }
}
