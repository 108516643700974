&-header {
  @include font-headline-4;
  color: rgba($g-color-dark-blue, 0.3);
  display: flex;
  margin-bottom: 15px;

  .-section {
    &.name {
      min-width: calc(#{$name-min-width} + #{$row-section-padding} + 8px);
      width: calc(#{$name-width} + #{$row-section-padding} + 8px);
    }

    &.pql {
      min-width: $pql-min-width;
      width: $pql-width;
    }

    &.settings {
      min-width: $settings-min-width;
      width: $settings-width;
    }
  }
}
