@mixin h-general-transition($properties...) {
  @if length($properties) == 0 {
    $properties: all;
  }

  @include media-breakpoint-up(md) {
    transition-property: $properties;
    transition-duration: $g-value-animation-duration;
    transition-timing-function: $g-value-animation-function;
  }
}

@mixin h-scrolled {
  overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch;
}
