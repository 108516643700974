&-home__header {
  @include font-headline-1;
  @include h-general-transition;
  position: sticky;
  top: 0;
  margin: 0 -32px;
  margin-bottom: 30px;
  z-index: 1024;

  &.-scroll {
    box-shadow: 0 2px 6px #0f5ef712;
  }

  .top,
  .bottom {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 32px;
    z-index: 1;
    flex-wrap: wrap;
  }

  .top {
    background-color: rgb(247, 247, 249);

    &__ico {
      &__button {
        display: flex;

        &,
        &:active,
        &:focus,
        &:hover {
          border: none;
          background-color: transparent;
        }

        .icon {
          height: 20px;
        }
      }
    }

    &__hint {
      position: relative;
      top: -7px;
      left: -5px;
    }

    .filters {
      &,
      &__search-btns {
        gap: 10px;
      }

      &,
      &__search,
      &__search-btns {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }

      &__search {
        position: relative;
        margin-bottom: 0;

        input {
          @include h-general-transition;
          @include font-body-3;
          background: rgba($g-color-dark-blue, 0.03);
          border: 1px solid transparent;
          border-radius: 8px;
          padding: 6px;
          padding-left: 30px;

          &::placeholder {
            color: rgba($g-color-secondary, 0.6);
          }

          &:active,
          &:focus {
            border-color: rgba($g-color-dark-blue, 0.05);
          }
        }

        &.ico {
          position: absolute;
          left: 10px;

          .p500-ui-icon {
            @include font-headline-5;
          }
        }
      }

      &__button {
        @include reset-btn;
        @include h-general-transition;
        @include font-body-3;
        position: relative;
        background-color: $g-color-white;
        padding: 6px;
        gap: 10px;
        width: 30px;
        height: 30px;
        border-radius: 8px;

        p500-ui-icon {
          color: $g-color-primary;
        }

        &:hover,
        &:active {
          background-color: $g-color-primary;

          &,
          p500-ui-icon {
            color: $g-color-white;
          }
        }

        .-normalize {
          transform: translateY(-1px);
        }
      }
    }
  }

  .bottom {
    background-color: $g-color-white;
    display: block;
    padding-top: 0;

    button {
      white-space: nowrap;
    }

    &__tabs {
      &__top,
      &__bottom {
        display: flex;
        flex-wrap: wrap;
      }

      &__top {
        margin-bottom: 12px;
        gap: 30px;
        flex-wrap: nowrap;
        overflow: auto;
        height: 52px;

        &__tab__button {
          @include reset-btn;
          @include h-general-transition;
          @include font-headline-4;
          color: rgba($g-color-dark-blue, 0.3);
          position: relative;

          &:after {
            position: absolute;
            content: '';
            background-color: $g-color-primary;
            border-radius: 5px;
            height: 2px;
            width: 100%;
            left: 0;
            bottom: 0;
            transform: scaleX(0);
            transition: all 0.2s ease;
          }

          &.-active {
            color: $g-color-dark-blue;

            &:after {
              transform: scaleX(1);
            }
          }
        }
      }

      &__bottom {
        gap: 10px;

        &.--no-sub-tabs {
          padding-top: 15px;
        }
      }
    }
  }
}
