.c-time {
  @include font-body-4;
  border-radius: 8px;

  &__container {
    display: flex;
    align-items: center;
    gap: 5px;
    line-height: 1;
  }

  &__dot {
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background-color: rgba($g-color-secondary, 0.3);
  }
}
