.dashboard-header-notifications {
  $primary-black: #000932;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;

    .left {
      display: flex;
    }

    .right {
      @include font-headline-5;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .ico {
        &,
        &:hover,
        &:active,
        &:focus {
          outline: none;
          border: none;
          background: none;
        }

        position: relative;
        margin-bottom: -4px;

        &.new::after {
          content: '';
          display: block;
          position: absolute;
          top: -4px;
          right: -4px;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: #ef5736;
        }

        &:hover {
          color: $g-color-primary;
        }
      }

      .open_button {
        &,
        &:hover,
        &:active,
        &:focus {
          outline: none;
          border: none;
          background: none;
        }

        @include h-general-transition(color);
        display: flex;
        justify-content: flex-start;
        align-items: center;

        span {
          @include font-headline-4;
        }

        &:hover {
          color: $g-color-primary;
        }
      }
    }
  }

  .card-wrapper {
    display: flex;
    flex-direction: column;
    gap: 7px;

    .card {
      padding: 8px 10px;
      background: rgba($primary-black, 0.03);
      border-radius: $g-value-border-radius-xl;
      overflow: hidden;

      .header {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 5px;

        .type {
          @include font-headline-5;
          padding: 2px 5px 0;
          border-radius: 4px;
          color: #2db1aa;
          background-color: rgba(89, 211, 204, 0.2);
          margin-right: 10px;
        }

        .incident {
          background-color: rgba(239, 87, 54, 0.2);
          color: #ef5736;
        }

        .date {
          @include font-body-4;
          margin-bottom: -3px;
        }
      }

      .main {
        display: flex;
        justify-content: space-between;

        .left {
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-right: 7px;
        }

        .right {
          .details_button {
            &,
            &:hover,
            &:active,
            &:focus {
              outline: none;
              border: none;
            }

            @include h-general-transition;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-radius: 6px;
            padding: 3px 5px;
            background-color: rgba(15, 94, 247, 0.05);
            color: $g-color-primary;

            i {
              font-size: 14px;
              margin-right: 5px;
            }

            span {
              @include font-headline-5;
            }

            &:hover {
              background-color: $g-color-primary;
              color: $g-color-white;
            }
          }
        }
      }
    }
  }
}
