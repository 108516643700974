&--info {
  #{$root} {
    &__message {
      position: relative;
      margin-bottom: 10px;
      overflow-wrap: break-word;
      border-radius: $g-value-border-radius-xl;
    }
  }

  &.-info {
    #{$root} {
      &__message {
        padding: 14px;
        background-color: rgba($g-color-black, 0.03);
      }
    }
  }

  &.-danger {
    #{$root} {
      &__title {
        color: $g-color-pattern-red;
      }
      &__message {
        padding: 14px;
        background-color: $g-color-red-no-opacity;
      }
    }
  }

  &.-warning {
    .p500-ui-modal__inner {
      z-index: 1024;
      height: 100vh;
    }

    @include media-breakpoint-up(sm) {
      .p500-ui-modal__inner {
        position: absolute;
        margin-left: 50vw;
        transform: translateX(-50%);
        height: initial;
      }

      &::before {
        content: '';
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba($g-color-black, 0.2);
        z-index: 1024;
      }
    }

    .p500-ui-modal__footer {
      background-color: rgba($g-color-red, 0.05);
    }
  }

  .p500-ui-modal__body {
    height: initial;
    .c-link {
      position: absolute;
      right: 54px;
      margin-top: 16px;
    }

    &.-metrics {
      padding: 0;

      .c-card__inner {
        border: none;
      }

      .p500-ui-modal__close {
        right: 20px;
        top: 20px;
      }
    }
  }
}
