&--new-terms {
  width: 100%;

  #{$root} {
    &__square {
      color: rgba($g-color-secondary, 0.5);
      @include font-headline-2;
    }

    &__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &__header-left {
      display: flex;
      flex-direction: row;

      gtd-dot-separator {
        margin-top: -1px;
      }
    }

    &__title {
      @include font-headline-2;
      max-width: 180px;
      white-space: nowrap;
      word-break: unset;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 6px;
      display: inline;
      margin-left: 8px;

      &.-is-required {
        &:after {
          color: $g-color-red;
          margin-left: 2px;
          content: '*';
        }
      }
    }

    &__info-row {
      display: flex;
      flex-direction: row;
      @include font-body-4;
      color: rgba($g-color-dark-blue, 0.5);

      .one-row {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .calculator {
        width: 100%;
      }
    }
  }
}
