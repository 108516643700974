.c-dropdown {
  $root: &;
  position: relative;

  @import 'c-dropdown--menu';
  @import 'c-dropdown--list';
  @import 'c-dropdown--tables';
  @import 'c-dropdown--chart';

  &--list {
    #{$root} {
      &__body {
        padding: 5px;
      }

      &__item {
        white-space: nowrap;
        cursor: pointer;

        &:after {
          margin: 4px 0;
          display: block;
          height: 1px;
          width: 100%;
          background-color: $g-color-gray;
          opacity: 0.4;
          content: '';
        }

        &:last-child {
          &:after {
            display: none;
          }
        }

        &.-button {
          background-color: unset;
          border: unset;
          color: unset;
        }
      }

      &__item-text {
        display: block;
        padding: 4px 8px;
        border-radius: $g-value-border-radius-xl;
        text-align: left;
        min-width: 120px;
        @include font-body-3;
        @include h-general-transition;

        &:hover {
          color: $g-color-primary;
          background-color: #ebf8ff;
        }
      }

      &__item-button {
        padding: 5px 10px;
        border-radius: $g-value-border-radius-xl;
        display: block;
        text-align: left;
        color: $g-color-secondary;

        &.is-active {
          color: $g-color-primary;
        }

        &:hover {
          background-color: rgba(15, 94, 247, 0.07);
        }
      }

      &__footer {
        display: flex;
        justify-content: end;
        width: 100%;
        padding: 14px 10px;
        background-color: rgba($g-color-primary, 0.05);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
}

.mobile-template {
  margin-left: auto;
  display: flex;
  @include media-breakpoint-up(mx) {
    display: none;
  }
}

.desktop-template {
  display: none;
  @include media-breakpoint-up(mx) {
    display: flex;
  }
}
