.c-popup-dropdown {
  $root: &;

  &.wrapper {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  &__show {
    z-index: 1020;
    position: absolute;
    background-color: var(--g-color-white);
    box-shadow: 0 2px 15px #00093226;
    border-radius: 4px;
    visibility: hidden;

    &.-show {
      visibility: visible;
    }

    #{$root} {
      &__wrapper {
        display: flex;
        flex-direction: column;
      }

      &__item {
        padding: 8px 12px;
        cursor: pointer;

        &:hover {
          background-color: #f1f3fe;
        }

        &-text {
          display: flex;
          gap: 8px;
          align-items: center;
        }
      }
    }
  }
}
