&--terms {
  width: 100%;

  #{$root} {
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      align-items: flex-start;
      position: relative;
      width: 100%;
      height: 70px;
      background: rgba($g-color-secondary, 0.03);
      border-radius: $g-value-border-radius-xl;
      padding-right: 14px;
      padding-left: 14px;
      margin-bottom: 14px;
      overflow: hidden;
    }

    &__formula {
      @include font-headline-5;
      margin-top: 8px;
      display: inline-block;
      word-break: break-word;
    }

    &__data {
      @include font-body-3;
      color: $g-color-secondary;
      opacity: 0.3;
      position: relative;
      left: 20px;
    }

    &__format {
      @include font-headline-4;
      color: $g-color-secondary;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        border-radius: 50%;
        width: 4px;
        height: 4px;
        display: flex;
        left: 119%;
        top: 9px;
        background: $g-color-secondary;
        opacity: 0.1;
      }
    }

    &__tooltip {
      @include font-headline-4;
      position: absolute;
      right: 10px;
      top: 55%;
      width: 20px;
      height: 20px;
      color: $g-color-primary;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      align-items: center;
      background: rgba($g-color-primary, 0.1);
      border-radius: 2px;
    }

    &__add-info {
      display: flex;
      align-items: center;
    }

    &__footer {
      display: flex;
      flex-direction: column;
    }

    &__description {
      display: flex;
      margin-top: 8px;
      &-left {
        @include font-headline-4;
        color: rgba($g-color-secondary, 0.3);
      }
      &-right {
        margin-left: 6px;
      }
    }

    &__left {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      width: 100%;
      word-break: break-all;
      margin-bottom: 14px;
    }

    &__left-body {
      display: inline-block;
      position: relative;
      width: 100%;
    }

    &__square {
      @include font-headline-3;
      width: 30px;
      height: 30px;
      margin-right: 10px;
      left: 0;
      top: 0;
      flex-shrink: 0;
      background-color: $g-color-primary;
      color: $g-color-white;
      box-shadow: 0 5px 10px rgba($g-color-primary, 0.2);
      border-radius: $g-value-border-radius-xl;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    &__icons--profile {
      @include font-body-1;
    }

    &__icons--terms {
      @include font-body-2;
    }

    &__title {
      @include font-headline-3;
      height: 20px;
      max-width: 120px;
      white-space: nowrap;
      word-break: unset;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 6px;
      display: inline;

      &.-is-required {
        &:after {
          color: $g-color-red;
          margin-left: 2px;
          content: '*';
        }
      }
    }

    &__subtitle {
      @include font-body-4;
      height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      margin-left: 6px;
      opacity: 0.3;
    }

    &__separator {
      opacity: 0.3;
      display: inline;
    }

    &__body {
      width: 54%;
      word-break: break-all;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__right {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__button {
      opacity: 0.2;
      margin-left: auto;
      display: inline;
      position: absolute;
      right: 0;

      [class^='h-icon-'],
      [class*=' h-icon-'] {
        line-height: 1;
      }
      @include h-general-transition(opacity);
    }
  }
}
