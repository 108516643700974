.c-collapsed-navigation {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: visible;

  &__section {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    @include h-general-transition();
    margin-bottom: 20px;
    cursor: pointer;

    &:hover {
      background: rgba($g-color-primary, 0.1);
    }

    &.-incidents {
      color: $g-color-red;
    }

    &.is-active {
      background: $g-color-primary;
      color: $g-color-white;
    }
  }

  &__footer {
    margin-top: auto;
  }
}
