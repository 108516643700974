&.--dashboard {
  background: unset;

  table {
    thead {
      th {
        background: #fafafb;
        border-top: 1px solid $g-color-gray;

        &:first-child {
          border-top-left-radius: 0;
        }

        &:last-child {
          border-top-right-radius: 0;
        }
      }
    }

    tr {
      &:hover {
        cursor: pointer;
      }

      td {
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;

        &:first-child {
          border-left: 4px solid transparent;
        }
      }

      &.active {
        background-color: rgba($g-color-primary, 0.1);

        td {
          border-top-color: rgba($g-color-primary, 0.3);
          border-bottom-color: rgba($g-color-primary, 0.6);

          &:first-child {
            border-left-color: #000e4b;
          }
        }
      }
    }
  }
}