.overlay-background {
  background-color: rgba($g-color-light-gray, 0.9);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
  display: flex;
  //align-items: center;
  overflow-y: auto;
  justify-content: center;
  z-index: $z-overlay;
  visibility: visible;
  transform: translate3d(0, 0, 0);
  @include h-general-transition;
  @include fadeIn($duration: $g-value-animation-duration, $function: $g-value-animation-function);

  .c-modal {
    transform: translate3d(0, 0, 0);
    visibility: visible;
    @include fadeIn($duration: $g-value-animation-duration, $function: $g-value-animation-function);
  }

  &--is--closing {
    visibility: hidden;
    @include fadeOut($duration: $g-value-animation-duration, $function: $g-value-animation-function);

    .c-modal {
      visibility: hidden;
      @include fadeOut($duration: $g-value-animation-duration, $function: $g-value-animation-function);
    }
  }
}
