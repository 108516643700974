.c-statistics-filters {
  $root: &;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  &__header {
    @include font-body-4;
    display: flex;
    padding: 20px;
    border: 1px solid $g-color-gray;
    background-color: $g-color-pale-blue;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .c-field-value__shape-circle {
      width: 8px;
      height: 8px;
    }

    @media (max-width: 1440px) {
      justify-content: space-between;
    }
  }

  &__header-title {
    @include font-body-3;
  }

  &__body {
    width: 100%;
    padding: 20px 12px;
    display: flex;
    justify-content: space-between;
    border: 1px solid $g-color-gray;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    gap: 20px;

    @media (max-width: 1105px) and (min-width: 1025px), (max-width: 755px) {
      flex-direction: column;

      gtd-earning-statistics {
        margin-top: 20px;
      }
    }

    @media (max-width: 480px) {
      padding: 12px;

      .mt-sm-10 {
        margin-top: 10px;
      }
    }

    @media (max-width: 1440px) {
      .mt-xxl-10 {
        margin-top: 10px;
      }
    }
  }

  &__earning-filters {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__datepicker {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    gtd-select-with-icon-with-hint {
      flex-grow: 1;
      min-width: 240px;
    }
  }

  @media (max-width: 375px) {
    &__header {
      padding: 10px 12px;
      flex-direction: column;
    }

    &__header-title {
      margin-bottom: 8px;
    }
  }
}
