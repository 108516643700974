.c-calculator {
  $root: &;

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 4px;

    .title {
      @include font-body-4;
      color: rgba($g-color-dark-blue, 0.3);
      margin-top: 4px;
    }

    button {
      @include font-headline-3;
      background: rgba($g-color-dark-blue, 0.05);
      width: auto;
      height: 24px;
      border-radius: 6px;
      border: none;
      margin-right: 4px;
      color: rgba($g-color-dark-blue, 0.5);
    }
    button:disabled {
      opacity: 0.4;
    }
  }

  &__formula {
    min-height: 90px;
    width: 100%;
    background: rgba($g-color-dark-blue, 0.05);
    border-radius: 4px;

    .content {
      padding: 8px 12px;
      line-height: 30px;

      .continue {
        color: rgba($g-color-black, 0.5);
      }

      span {
        margin-right: 12px;
        @include font-body-4;

        &.selected:after {
          content: '';
          margin-left: 12px;
          border-top: 3px solid transparent;
          border-left: 1px solid black;
          border-right: 3px solid transparent;
          animation: opacityOn 2s normal forwards;
          animation-iteration-count: infinite;
        }

        &.operator {
          @include font-body-4;
          color: $g-color-black;
        }
      }
    }

    &.-view-only {
      max-height: 90px;
      overflow: auto;
    }
  }

  &__options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 12px;

    button {
      background: rgba($g-color-dark-blue, 0.05);
      width: 24px;
      height: 24px;
      border-radius: 6px;
      @include font-headline-3;
      border: none;
      margin-right: 4px;
      color: rgba($g-color-dark-blue, 0.5);
    }
    button:disabled {
      opacity: 0.4;
    }
  }

  &__metrics {
    margin-top: 16px;

    .title {
      @include font-body-4;
      color: rgba($g-color-dark-blue, 0.3);
    }

    .list {
      margin-top: 10px;
    }
  }

  .metric {
    padding: 2px 4px;
    margin-right: 12px;
    border-radius: 5px;
    background: #a057fb;
    color: $white;
    border: none;
    white-space: nowrap;
  }
}

@keyframes opacityOn {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
