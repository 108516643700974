&--action-form {
  #{$root} {
    &__body {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: space-between;
      position: relative;

      gtd-ui-preloader {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);

        @include media-breakpoint-up(sm) {
          transform: translate(-50%, -50%);
        }
      }

      &--no-flex {
        display: block;
      }

      &--help-info {
        padding-bottom: 20px;
      }
    }

    &__group {
      display: flex;
      align-items: center;
      text-align: center;
      margin-bottom: 8px;
      font-weight: 700;

      &::after {
        content: '';

        flex-grow: 1;
        flex-shrink: 0;
        width: 50px;
        height: 1px;
        margin-left: 15px;

        background-color: $g-color-gray;
      }
    }

    &__description {
      @include font-body-4;
      margin-bottom: 14px;
      color: rgba($g-color-secondary, 0.5);
    }

    &__form-field {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
}
