&--deal-info {
  .deal-info-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      &__row {
        display: flex;
        align-items: flex-start;
        gap: 2px;
        align-self: stretch;
      }
      .data-row {
        @include font-body-3;
        display: block;
        margin-bottom: 6px;
        letter-spacing: 0;
        color: rgba($g-color-secondary, 0.5);

        &:last-child {
          margin-bottom: 0;
        }
      }

      .title {
        @include font-body-3;
        margin-bottom: 6px;
        display: flex;
        align-items: center;
      }
    }

    .count {
      display: flex;
      padding: 8px;
      align-items: center;
      gap: var(--Space-50, 4px);
      align-self: stretch;
      color: $g-color-primary !important;
      background-color: rgba($g-color-secondary, 0.02);
      text-align: center;
      border-radius: 5px;

      span {
        font-size: 28px;
        color: $g-color-primary !important;
        @include font-headline-3;
      }
    }
  }

  #{$root} {
    &__body {
      margin-bottom: 20px;
    }
  }
}
