&--select {
  .disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &:hover {
    #{$root} {
      &__label {
        opacity: 1;
      }
    }
  }

  #{$root} {
    &__inner {
      display: flex;
      flex-flow: column wrap;
    }

    &__input {
      width: 100%;
      color: $g-color-secondary;
      border-radius: 5px;
      border: solid 1px $g-color-gray;
      background-color: $g-color-white;
      padding: 4px 12px;
      @include h-general-transition(box-shadow, border-color, background-color);

      &:focus,
      &:active {
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.07);
        border-color: rgba($g-color-secondary, 0.2);
        background-color: $g-color-light-gray;

        & + #{$root}__label {
          opacity: 1;
        }
      }
    }

    &__label {
      display: block;
      opacity: 0.7;
      margin-bottom: 5px;
      order: -1;
      @include h-general-transition(opacity);
    }

    &__header {
      display: flex;
    }

    &__actions {
      @include font-body-4;
      margin-left: auto;
      cursor: pointer;

      .action {
        margin-left: 4px;
        opacity: 0.3;
        &:hover {
          opacity: 1;
        }
      }
    }

    &__values {
      margin-top: 5px;
      margin-bottom: -4px;
      display: flex;
      flex-flow: row wrap;
    }

    &__value {
      margin-bottom: 4px;
      margin-right: 4px;
    }
  }

  &.is-required {
    #{$root} {
      &__label {
        &:after {
          color: rgba($g-color-red, 0.3);
          margin-left: 2px;
          content: '*';
        }
      }
    }
  }

  &.is-invalid {
    #{$root} {
      &__label {
        opacity: 1;
      }

      &__input,
      &__select,
      &__textarea {
        border-color: $g-color-red;
      }

      &__input {
        &--big {
          background-color: $g-color-white;
        }
      }
    }
  }
}
