&--small-tab {
  @include font-headline-5;
  color: rgba($g-color-secondary, 0.5);

  &:hover {
    color: $g-color-secondary;
  }

  &.is-active {
    color: $g-color-primary;
  }
}
