&--builder {
  display: flex;
  align-items: center;
  justify-content: center;

  [class^='h-icon-'],
  [class*=' h-icon-'] {
    line-height: 1;
  }

  #{$root} {
    &__icon {
      font-size: 18px;
      color: $g-color-primary;
    }
  }
}
