.c-filters-row {
  width: 100%;
  flex-grow: 1;

  &__wrapper {
    display: flex;
    align-items: flex-start;
    gap: 18px;
    margin-bottom: 12px;
    padding: 10px 18px;
    border-radius: $g-value-border-radius-xl;
    background-color: rgba($g-color-primary, 0.05);

    &.-collapsed {
      max-height: 44px;
      overflow: hidden;
    }
  }

  &__expand {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    min-width: 24px;
    height: 24px;
    border-radius: 8px;
    color: $g-color-primary;
    background-color: rgba($current-bg-gray-3, 0.06);
  }

  &__values {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  &__item {
    @include font-headline-5;
    display: flex;
    align-items: baseline;
    gap: 6px;
    padding: 4px 6px;
    border-radius: 5px;
    background-color: rgba($g-color-dark-blue, 0.08);
  }

  &__item-icon {
    cursor: pointer;
    color: rgba($g-color-black, 0.3);
  }

  &__divider {
    align-self: stretch;
    margin-left: auto;
    width: 1px;
    background-color: rgba($g-color-secondary, 0.06);
  }

  button.c-filters-row__reset,
  &__reset {
    @include font-headline-4;
    align-self: center;
    padding: 2px 8px;
  }
}
