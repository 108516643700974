.c-support-menu {
  &__unread-indicator {
    position: absolute;
    width: 7px;
    height: 7px;
    top: 5px;
    left: 28px;
    border-radius: 50%;
    background: var(--color-icon-danger, #f0560e);
  }
}
