.c-label {
  $root: &;

  &--d {
    background-color: $g-color-secondary;
    color: $g-color-white;
    display: inline-flex;
    border-radius: 5px; // TODO change new variables
    padding: 1px 6px; // TODO change new variables
    @include font-body-4;

    &#{$root}--color {
      &--red,
      &--danger,
      &--default {
        background-color: $g-color-red; // TODO change new variables
      }

      &--green,
      &--success {
        background-color: $g-color-green; // TODO change new variables
      }
      &--primary,
      &--info {
        background-color: $g-color-primary; // TODO change new variables
      }

      &--yellow,
      &--offer-country,
      &--warning {
        background-color: $g-color-yellow; // TODO change new variables
      }

      &--black {
        background-color: $g-color-black; // TODO change new variables
      }

      &--cyan,
      &--warning-3,
      &--country {
        background-color: $g-color-cyan;
      }

      &--cerise,
      &--affiliate-aff-sub {
        background-color: $g-color-cerise;
      }

      &--purple,
      &--affiliate-country {
        background-color: $g-color-purple;
      }

      &--indigo-blue,
      &--affiliate {
        background-color: $g-color-indigo-blue;
      }

      &--offer {
        background-color: $g-color-dark-cyan-2;
      }

      &--tooltip-header {
        background-color: $g-color-secondary;
        color: $g-color-white;

        &:hover {
          background-color: $g-color-white;
          color: $g-color-secondary;
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
        }
      }

      &--orange {
        background-color: $g-color-orange;
      }

      &--gray {
        background-color: $g-color-gray;
        color: rgba($g-color-secondary, 0.5);
      }
    }
  }
}
