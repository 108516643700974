&--settings {
  @include h-general-transition(background-color);
  padding: 18px 22px;
  background-color: #f8f8fb;
  border: 1px solid rgba(0, 14, 75, 0.05);
  border-radius: $g-value-border-radius-xl;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  min-width: 285px;
  $root: &;

  &__header {
    @include font-headline-4;
    display: flex;
    justify-content: space-between;
    color: $g-color-secondary;
    border-radius: $g-value-border-radius-xl;
    margin-bottom: 14px;

    &-left {
      display: flex;
      align-items: center;

      &--id {
        @include font-body-4;
        height: 28px;
        width: 33px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px 6px 0;
        background-color: rgba(0, 9, 50, 0.05);
        border-radius: 10px;
        margin-right: 8px;
      }

      &--beta {
        // todo: use new mixin
        font-size: 12px;
        line-height: 18px;
        font-weight: 700;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 8px;
        background-color: $g-color-primary;
        color: $g-color-white;
        border-radius: 360px;
        padding: 0 4px;
        height: 16px;
      }
    }

    &-right {
      display: flex;
      align-items: center;

      p500-ui-icon {
        color: $g-color-secondary;
      }
    }
  }

  &__main {
    @include font-body-4;
    color: rgba($g-color-secondary, 0.6);
    margin-bottom: 14px;
    display: flex;
    flex-direction: column;
    flex: 1;

    &-description {
      min-height: 36px;
      margin-bottom: 10px;
    }

    &-line {
      border-bottom: 1px solid rgba($g-color-secondary, 0.08);
      border-radius: 2px;
      margin-top: auto;
      margin-bottom: 14px;
    }

    &-footer {
      display: flex;
      align-items: center;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;

      p500-ui-icon {
        color: $g-color-secondary;
        margin-right: 6px;
      }
    }
  }

  &__footer {
    .p500-ui-confluent-button.p500-ui-confluent-button--primary.p500-ui-confluent-button--transparent {
      background-color: rgba(25, 48, 144, 0.06);
    }
  }

  &:hover {
    background-color: #f4f3ff;

    #{$root}__header {
      &-left {
        &--id {
          background-color: rgba(102, 51, 203, 0.1);
        }
      }

      &-right {
        p500-ui-icon {
          color: $g-color-primary;
        }
      }
    }

    #{$root}__footer {
      .p500-ui-confluent-button.p500-ui-confluent-button--primary.p500-ui-confluent-button--transparent {
        background-color: $g-color-primary;

        .p500-ui-confluent-button__text {
          color: $g-color-white;
        }
      }
    }
  }

  &.-loading {
    min-width: 285px;
    height: 199px;
    border-radius: 10px;
    background-image: linear-gradient(110deg, #ececec 8%, #f8f8fb 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.2s shine linear infinite;
  }

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
}
