&--circle-icon {
  font-size: 20px;
  line-height: 1em;

  &.-red {
    color: $g-color-red;

    &:hover {
      color: $g-color-red-hover;
    }
  }

  &.-red-2 {
    color: rgba($g-color-red, 0.3);

    &:hover {
      color: $g-color-red;
    }
  }

  &.-red-3 {
    color: rgba($g-color-red, 0.15);

    &:hover {
      color: $g-color-red;
    }
  }

  &.-green {
    color: $g-color-green;

    &:hover {
      color: $g-color-green-hover;
    }
  }

  &.-primary {
    color: $g-color-primary;

    &:hover {
      color: $g-color-primary-hover;
    }
  }

  &.-primary-2 {
    color: rgba($g-color-primary, 0.5);

    &:hover {
      color: $g-color-primary;
    }
  }

  &.-primary-3 {
    color: rgba($g-color-primary, 0.15);

    &:hover {
      color: $g-color-primary;
    }
  }

  &.-refresh {
    font-size: 22px;
  }

  &.-refresh-widget {
    font-size: 16px;
  }

  &.-add-widget {
    font-size: 17px;
  }

  &.-large {
    font-size: 26px;

    @include media-breakpoint-up(sm) {
      font-size: 24px;
    }
  }

  &.-small {
    font-size: 12px;
  }

  &.-msmall {
    font-size: 14px;
  }

  &.-lsmall {
    font-size: 16px;
  }

  &.-help {
    font-size: 18px;
  }

  &.-medium {
    color: $g-color-white;
    font-size: 8px;
    line-height: 1em;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    background-color: $g-color-primary;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: $g-color-primary-hover;
    }
  }
}
