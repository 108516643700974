&--tool {
  overflow-x: scroll;
  margin: 0 (-$x-main-content-mobile-padding);

  @include media-breakpoint-up(sm) {
    margin: initial;
  }

  @include h-scrolled;

  .action-remove {
    float: right;
  }

  table {
    tr {
      &:nth-child(even) {
        background-color: initial;
      }
    }

    thead {
      background-color: transparent;

      th {
        color: $g-color-secondary;
      }
    }
  }

  tbody tr {
    &:hover {
      .h-icon-edit {
        color: $g-color-primary;
      }

      .h-icon-remove {
        color: $g-color-red;
      }
    }
  }

  thead,
  tbody {
    .select-cell {
      padding-left: 28px;
    }

    tr {
      th,
      td {
        padding: 8px 18px;
      }

      td:last-child {
        padding-right: 0;
      }
    }
  }
}
