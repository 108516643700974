.c-drilldown-quick-filters {
  $root: &;
  // overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  background-color: $g-color-pale-blue;
  padding: 15px 20px 10px;
  border: 1px solid $g-color-gray;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: unset;

  &.-earnings {
    padding: 16px 20px;

    .p500-ui-dropdown__body-inner {
      right: auto;
      padding: 0;
    }

    .c-dropdown__item {
      padding: 10px;
    }

    .filters-block {
      gap: 16px;
      display: flex;
      flex-wrap: wrap;

      &__title {
        @include font-body-4;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      @media (min-width: 1500px) {
        flex-wrap: nowrap;

        #{$root}__wrapper {
          &__field {
            flex-shrink: 0;
          }
        }
      }
    }
  }

  .c-form__section__preset {
    flex: unset;
  }

  .separator {
    border-left: 1px solid $g-color-gray;
  }

  &__wrapper {
    $btn-size: 12px;

    &__empty {
      width: $btn-size;
      height: $btn-size;
    }

    &__field {
      flex-basis: 150px;

      &__label {
        @include font-body-4;
        color: #000e4b;
        opacity: 0.7;
        display: flex;
        gap: 5px;
        align-items: center;
      }
    }
  }
}
