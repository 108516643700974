.c-link {
  $root: &;

  position: relative;
  cursor: pointer;
  user-select: none;
  @include h-general-transition;

  &:hover {
    text-decoration: none;
  }

  @import 'c-link--primary';
}
