.c-filters {
  $root: &;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-filters;
  width: 100%;
  height: 100%;

  @include media-breakpoint-up(sm) {
    border-radius: $g-value-border-radius-xl;
    border: 10px solid $g-color-white;
    left: auto;
    width: 370px;
    right: 26px;
    top: 170px;
    height: calc(100% - 170px);
  }

  @include media-breakpoint-up(md) {
    top: 128px;
    height: calc(100% - 128px);
  }

  @include media-breakpoint-up(lg) {
    height: calc(100% - 64px);
    top: 64px;
  }

  .c-form-field__label {
    display: none;
  }

  .c-form-field--d {
    margin-bottom: 0;
    margin-top: 10px;
  }

  &__content-left {
    display: flex;
  }

  &__body {
    height: 100%;
    overflow-y: auto;

    @include media-breakpoint-up(sm) {
      border-bottom: 0;
      margin-bottom: 20px;
    }
  }

  &__tag {
    margin-bottom: 4px;
  }

  &__content-right {
    display: flex;
    margin-left: auto;
  }

  &__toggle-button {
    display: flex;
    align-items: center;
  }

  &__delete {
    font-size: 12px;
  }

  &__content {
    display: none;
  }

  &__group {
    border-bottom: 1px solid $g-color-gray;
  }

  &__group-title {
    @include font-headline-3;
    cursor: pointer;
    color: $g-color-black;
    margin: 25px 0 10px 15px;

    @include media-breakpoint-up(sm) {
      margin: 25px 0 10px 19px;
    }
  }

  &__item {
    padding: 13px 0;
    margin: 0 15px;

    @include media-breakpoint-up(sm) {
      padding: 14px 20px;
      margin: 0;
    }

    &.dropdown--is--shown {
      #{$root} {
        &__open-icon {
          display: none;
        }

        &__close-icon {
          display: flex;
        }

        &__content {
          display: block;
        }
      }
    }

    &:last-child {
      border-bottom: none;
    }

    &__header {
      display: flex;
    }
  }

  &__counter {
    text-align: center;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: $g-color-gray;
    margin-right: 10px;
  }

  &__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 0;
    background-color: $g-natural-light-gray;

    @include media-breakpoint-up(sm) {
      border-radius: $g-value-border-radius-xl;
      padding: 14px 0;
    }
  }

  &__key {
    margin-right: 6px;
  }

  &__title {
    @include font-headline-3;
    display: flex;
    align-items: center;
    padding: 20px 0;
    margin: 0 15px;
    border-bottom: 1px solid $g-color-gray;

    @include media-breakpoint-up(sm) {
      padding: 0 20px 14px 16px;
      margin: 0;
      border-bottom: 1px solid $g-color-gray;
    }

    &-caption {
      margin-right: 20px;
    }

    &-search {
      flex: 1;
    }

    &-arrow {
      line-height: 24px;
      font-size: 14px;
      margin-right: 8px;
      color: $g-color-primary;
    }
  }

  &__select {
    display: flex;
    padding: 14px 20px;
    border-bottom: 1px solid $g-color-gray;
  }

  &__label {
    @include font-body-4;
  }

  &__footer {
    margin: auto 15px 0 15px;
    padding: 20px 0 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid $g-color-gray;
    flex-direction: column;

    @include media-breakpoint-up(sm) {
      padding: 0 20px;
      border-top: none;
      margin: auto 0 0 0;
      display: block;
    }
  }

  &__apply {
    text-align: center;
    margin-bottom: 10px;
    width: 100%;
  }

  &__reset {
    text-align: center;
  }

  &__open-icon {
    font-size: 10px;
  }

  &__close-icon {
    font-size: 10px;
    display: none;
  }
}
