&--condition {
  #{$root} {
    width: 100%;

    &__groups-conditions {
      display: flex;
      flex-direction: column;

      @media (min-width: 760px) {
        align-items: flex-start;
      }
    }

    &__condition {
      display: grid;
      grid-column: auto;
      grid-row: auto;
      grid-row-gap: 7px;
    }

    &__config {
      width: 100%;
      display: inline-flex;
      align-items: center;
    }

    &__radio-line {
      height: 1px;
      width: auto;
      background-color: $g-color-gray;
      margin: 15px 0;

      &.-dividing {
        flex: 0 0 10px;
      }
    }

    &__fields {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -14px;
    }

    &__field {
      display: inline-block;
      max-width: 100%;
      flex: 0 1 100%;
      margin-bottom: 14px;

      @media (min-width: 760px) {
        width: 225px;
        flex: 1 0 225px;
        margin-right: 20px;
      }

      &--option {
        max-width: 100%;
        flex: 0 0 100%;
        @media (min-width: 760px) {
          max-width: 140px;
          flex: 0 0 140px;
        }
      }
    }

    &__delete {
      justify-content: center;
      flex: 0 0 100%;
      display: flex;
      align-items: flex-start;
      margin-bottom: 14px;
      margin-right: 20px;

      .-remove-remove {
        display: none;
      }

      @media (min-width: 760px) {
        justify-content: initial;
        margin-top: 27px;
        flex: initial;
        margin-bottom: 0;
        .-remove-mobile {
          display: none;
        }
        .-remove-remove {
          display: flex;
        }
      }
    }
  }
}
