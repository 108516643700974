.p-settings {
  $root: &;
  height: 100%;
  display: flex;
  flex-direction: column;

  .c-page-header__page-title.-settings {
    flex: 1;
  }

  .c-navigation-trees__title {
    padding-left: 20px;
  }

  &__nav {
    background-color: rgba(0, 14, 75, 0.03);
    border-radius: 4px;
    padding: 7px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    white-space: nowrap;

    &-wrapper {
      @include font-body-4;
      display: flex;
      align-items: center;
      gap: 8px;

      a {
        @include font-headline-5;
        color: rgba(0, 9, 50, 0.4);

        &.-black {
          color: $g-color-secondary;
        }

        &:hover {
          color: $g-color-primary;
          text-decoration: none;
        }
      }
    }
  }

  &__bg {
    display: flex;
    justify-content: center;
    background-image: url('../../assets/new-img/crm-settings/crm-setting-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: $g-value-border-radius-xl;
    margin: 32px 0;
    position: relative;
    min-height: 100px;

    .img-wrapper {
      position: absolute;
      top: -18px;
    }
  }

  main {
    flex: 1;

    #{$root}__card-wrapper {
      display: grid;
      gap: 20px;
      grid-template-columns: repeat(auto-fill, minmax(285px, 1fr));

      .card {
        @include h-general-transition;
        padding: 22px;
        background: $g-color-white;
        border: 1px solid rgba(0, 14, 75, 0.1);
        box-shadow: 0 0 20px rgba(0, 14, 75, 0.05);
        border-radius: $g-value-border-radius-xl;
        display: flex;
        flex-direction: column;
        color: $g-color-secondary;
        cursor: pointer;

        .header {
          @include font-body-3;
          display: flex;
          justify-content: space-between;
          margin-bottom: 19px;

          .left {
            display: flex;
            gap: 13px;

            .ico {
              font-size: 18px;
            }

            .title {
              @include font-headline-3;
            }
          }

          .right {
            .p500-ui-button-icon {
              color: inherit;
            }
          }
        }

        .main {
          margin-bottom: 16px;

          .description {
            @include font-body-4;
          }
        }

        .footer {
          margin-top: auto;

          .p500-ui-confluent-button.p500-ui-confluent-button--big .p500-ui-confluent-button__text {
            @include font-headline-4;
          }
        }

        &:hover {
          background-color: #f4f3ff;

          .header {
            .left {
              .ico .p500-ui-icon {
                color: #6633cb;
              }
            }

            .right .p500-ui-button-icon {
              color: $g-color-primary;
            }
          }

          .footer {
            .p500-ui-confluent-button.p500-ui-confluent-button--primary.p500-ui-confluent-button--transparent {
              background-color: $g-color-primary;

              .p500-ui-confluent-button__text {
                color: $g-color-white;
              }
            }
          }
        }
      }
    }

    #{$root}__body {
      #{$root}__content-main,
      #{$root}__content-aside {
        #{$root}__title {
          @include font-headline-2;
          display: flex;
          align-items: center;
          gap: 10px;
          margin-bottom: 20px;
          white-space: nowrap;
        }
      }

      #{$root}__content-main {
        margin-bottom: 32px;

        #{$root}__title {
          color: #6633cb;
        }
      }

      #{$root}__content-aside {
        #{$root}__title {
          color: #ef5736;
        }

        .card:hover {
          background-color: #fff6f5;

          .header {
            .left {
              .ico .p500-ui-icon {
                color: #ef5736;
              }
            }
          }
        }
      }
    }
  }
}
