&__dashboard-top {
  display: flex;
  flex-direction: column;
  flex: 1;

  .flex-1 {
    flex: 1;
  }

  .chart {
    background-color: $g-color-map-notification-backdrop;
    padding: 18px;
    height: 248px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .c-widget {
    border-radius: 10px;
    border: 1px solid $g-color-gray;
    overflow: hidden;

    &__header {
      min-height: 32px;
    }

    &.bottom-gray {
      border-bottom: 4px solid $g-natural-light-gray;
    }

    &__body {
      overflow-x: scroll;
      border: unset;
      border-radius: 0;
      background-image: linear-gradient(to bottom, rgba(0, 14, 75, 0.02) 39px, transparent 1px),
        linear-gradient(to bottom, white 0, white 0);
      background-size: 100% 78px;
    }

    &__select {
      @include font-body-3;
    }
  }
}
