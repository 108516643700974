&--tooltip {
  $root-tooltip: &;
  padding: 2px 4px !important;
  background-color: $g-color-white !important;
  color: unset !important;
  box-shadow: 0 0 20px rgba($g-color-secondary, 0.15);

  &::after {
    content: none !important;
  }
}
