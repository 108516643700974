gtd-profile-menu {
  .c-menu-list {
    @include media-breakpoint-up(sm) {
      position: absolute;
    }

    &.-floating {
      @include media-breakpoint-up(lg) {
        bottom: 0;
        left: 0;
      }

      @include media-breakpoint-down(mx) {
        top: -4px;
        right: 0;
      }
    }
  }
}
