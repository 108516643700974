.c-dot-separator {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: rgba($g-color-secondary, 0.2);
  margin-left: 8px;
  &__small {
    width: 3px;
    height: 3px;
  }
}
