.c-drag-list {
  &__container {
    max-width: 100%;
    display: inline-block;
    vertical-align: top;

    .drag {
      &__list {
        border: 1px solid $g-color-gray;
        min-height: 40px;
        background: $g-color-white;
        border-radius: 4px;
        overflow: hidden;
        display: block;
      }
    }
  }
}

.c-drag-list__item {
  @include font-body-3;
  padding: 10px;
  border-bottom: 1px solid $g-color-gray;
  color: $g-color-black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: $g-color-white;
  z-index: 2000 !important;

  &:last-child {
    border: none;
  }
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.offer-affiliate-access-select,
.affiliate-offer-access-select {
  .p500-ui-form-field__values {
    display: none;
  }
}
