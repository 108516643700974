.c-app {
  $root: &;

  position: relative;
  margin: -22px -32px;

  // Wrap for Mobile and Desktop application
  .c-app__wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100%;
  }

  .c-app__wrap--mobile {
    background-color: $g-color-primary;
    margin-left: -10px;

    @media screen and (max-width: 991px) {
      margin-left: 0;
    }
  }

  .c-app__wrap--desktop {
    margin-right: -10px;

    @media screen and (max-width: 991px) {
      margin-right: 0;
    }
  }

  #{$root} {
    &__info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      // Color font for info
      &--mobile {
        #{$root} {
          &__info-version {
            color: rgba(255, 255, 255, 0.3);
          }

          &__info-title {
            color: $g-color-white;
          }
        }
      }

      &--desktop {
        #{$root} {
          &__info-version {
            color: rgba(0, 14, 75, 0.3);
          }

          &__info-title {
            color: $g-color-secondary;
          }
        }
      }
    }

    &__info-version {
      @include font-headline-3;
      margin-top: 42px;
    }

    &__info-title {
      @include font-headline-1;
      margin-top: 12px;
    }

    &__img {
      img {
        width: 100%;
        height: auto;
      }
    }

    // Text
    &__text {
      @include font-body-3;
      display: flex;
      justify-content: center;
      text-align: center;

      p:last-child {
        margin-top: 20px;
      }

      &__inner {
        max-width: 350px;

        @media screen and (max-width: 380px) {
          padding-left: 10px;
          padding-right: 10px;
          min-width: 200px;
        }

        @media screen and (max-width: 335px) {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }

    &__text--desktop {
      color: $g-color-secondary;

      margin-left: 32px;
      margin-right: 32px;
      padding-bottom: 30px;
      border-bottom: 1px solid $g-color-gray;
    }

    &__text--mobile {
      color: $g-color-white;

      margin-left: 32px;
      margin-right: 32px;
      padding-bottom: 30px;
      border-bottom: 1px solid rgba($g-color-white, 0.1);
    }

    // Buttons
    &__buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      margin: 30px 0;

      .c-button:first-child {
        margin-right: 30px;
      }

      @media screen and (max-width: 1158px) {
        flex-direction: column;

        .c-button:first-child {
          margin-right: 0;
        }

        .c-button:last-child {
          margin-top: 20px;
        }
      }

      @media screen and (max-width: 1024px) {
        flex-direction: row;

        .c-button:first-child {
          margin-right: 30px;
        }

        .c-button:last-child {
          margin-top: 0;
        }
      }

      @media screen and (max-width: 466px) {
        flex-direction: column;

        .c-button:first-child {
          margin-right: 0;
        }

        .c-button:last-child {
          margin-top: 20px;
        }
      }
    }
  }
}
