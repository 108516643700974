* {
  outline: none !important;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    @include h-general-transition;
    cursor: pointer;
    border: 3px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    background-color: rgba($g-color-secondary, 0.1);
    border-radius: $g-value-border-radius-xl;

    &:hover {
      background-color: rgba($g-color-secondary, 0.5);
    }
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background: none;
  }
}

html {
  height: 100%;
}
body {
  height: 100%;
  color: $g-color-secondary;
  background-color: $g-color-white;
  -webkit-font-smoothing: antialiased;
  @include font-body-3;
}

p {
  margin-bottom: 0;
}

a,
label {
  -webkit-tap-highlight-color: transparent;
  padding: 0;
  word-break: normal;
}

textarea,
input {
  padding: 0;
  -webkit-appearance: none !important;
  border-radius: 5px;
}

th[data-tag='table_column_private_ips'],
th[data-tag='table_column_private_value'] {
  & > .th__content::before {
    content: '';
    background: url('../../../assets/new-img/icons/secure-1.svg') no-repeat 50%;
    background-size: contain;
    width: 14px;
    margin-right: 7px;
  }
}

td[gtd-remove-row-button],
td[gtd-edit-row-button-unsecure],
td[gtd-edit-row-button] {
  width: 40px;
}

.p500-ui-table table {
  .sticky {
    position: sticky;
    right: 0;
    border-left: 1px solid var(--Border-secondary, #bfc2cc);
    z-index: 1001;
    width: 0;
  }

  tr {
    td[gtd-sticky-row-actions] {
      position: sticky;
      right: 0;
      padding: 0;
      background-color: $g-color-white;

      .action {
        &:first-child {
          border-left: 1px solid var(--Border-secondary, #bfc2cc);
          border-bottom-left-radius: 0;
        }

        &:last-child {
          border-right: none;
        }

        border-bottom: none;
        height: 36px;
        padding: 7px 10px;
      }
    }

    &:nth-child(even) {
      td[gtd-sticky-row-actions] td {
        background-color: rgba($g-color-secondary, 0.02);
      }
    }

    &:hover {
      background-color: $g-color-white;

      td[gtd-sticky-row-actions] td {
        background-color: rgba(var(--RGB_g-color-primary), 0.12);
      }
    }
  }
}

p500-ui-new-table.--sticky-actions {
  table thead.p500-ui-table__header {
    z-index: 1010;
  }
}
