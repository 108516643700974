&__tab {
  .p500-ui-button--transparent.p500-ui-button--primary .p500-ui-button__text {
    color: $g-color-secondary;
    opacity: 0.3;
    transition: opacity 0.15s;
    &:hover {
      color: $g-color-secondary;
      opacity: 0.8;
    }
  }
  .p500-ui-button--transparent.p500-ui-button--primary.-active .p500-ui-button__text,
  .-active {
    color: $g-color-primary;
    opacity: 1;
    height: unset;
  }
}
