&--offer-links {
  #{$root} {
    &__head {
      background: unset;
      height: unset;
      margin-bottom: unset;
    }
    &__title,
    &__subtitle,
    &__close {
      display: none;
    }
    &__inner {
      overflow-y: unset;
      margin-top: unset;
      margin-bottom: unset;
      border-radius: unset;
      height: unset;
      background: unset;
      box-shadow: unset;
    }
  }
}
