.c-tabs {
  $root: &;

  display: flex;
  white-space: nowrap;

  &__item {
    @include font-body-3;
    margin-right: 8px;
    padding: 5px 6px;
    background-color: transparent;
    border: 1px solid $g-color-gray;
    color: rgba($g-color-secondary, 0.3);
    border-radius: 5px;

    &:hover,
    &:active {
      color: $g-color-secondary;
      border-color: $g-color-secondary;
    }

    &--selected {
      background-color: $g-color-primary;
      border-color: $g-color-primary;
      color: $g-color-white;

      &:hover,
      &:active {
        border-color: $g-color-primary;
        color: $g-color-white;
      }
    }

    @include media-breakpoint-up(xxl) {
      &:last-child {
        margin-right: 0;
      }
    }
    &.disabled {
      opacity: 0.3;
      &:hover {
        color: inherit;
      }
    }
  }

  &__wrapper {
    white-space: nowrap;
  }

  &--big {
    overflow-x: auto;
    border-radius: $g-value-border-radius-xl;
    padding: 16px 10px 0;

    background-color: rgba($g-color-light-blue, 0.8);

    @include media-breakpoint-down(md) {
      margin-left: -$x-main-content-mobile-padding;
      margin-right: -$x-main-content-mobile-padding;
      border-radius: 0;
    }

    #{$root} {
      &__item {
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &--sub-tabs {
    padding: 7px 20px;
    background-color: rgba($g-color-light-blue, 0.5);
    margin-top: -20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: relative;

    @include media-breakpoint-down(md) {
      margin-right: -$x-main-content-mobile-padding;
      margin-left: -$x-main-content-mobile-padding;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: -10px;
      width: 10px;
      height: 10px;
      background-color: rgb(241, 247, 255);
    }

    &::before {
      content: '';
      position: absolute;
      right: 0;
      top: -10px;
      width: 10px;
      height: 10px;
      background-color: rgb(241, 247, 255);
    }

    #{$root} {
      &__item {
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__hr {
    position: absolute;
    top: -2px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: rgba($g-color-primary, 0.1);
    z-index: 1;
  }

  .collapsed-intervals__content {
    .c-button--small-tab {
      color: $g-color-secondary;
      width: 100%;
      padding: 5px;
      border-bottom: 1px solid rgba($g-color-gray, 0.5);
      display: block;
      text-align: left;

      &.is-active {
        color: $g-color-primary;
      }

      &:last-child {
        border: none;
      }
    }
  }

  @media (max-width: 1399px) {
    .p500-ui-button.c-tabs__item-collapsed {
      display: none;
    }
  }
  .collapsed-intervals__content {
    display: flex;
    flex-direction: column;
  }
}
