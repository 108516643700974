.c-banner {
  $root: &;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #b8d6ff;
  background: #f3f8ff;
  position: relative;

  &.notifications-margin {
    margin: 0 16px 16px;
  }

  &__main {
    display: flex;
    min-width: 181px;
    align-items: stretch;
    gap: 16px;
    flex: 1 0 0;
  }

  &__close-button {
    position: absolute;
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 4px;
    align-self: stretch;
    flex-wrap: wrap;
    top: 16px;
    right: 24px;

    p500-ui-icon {
      cursor: pointer;
    }
  }

  &__buttons {
    display: flex;
    gap: 16px;
  }

  &__left {
    margin: 16px 0 16px 20px;
    display: flex;
    min-width: 181px;
    align-items: center;
    align-content: center;
    gap: 16px;
    flex: 1 0 0;
    flex-wrap: wrap;
  }

  &__right {
    display: flex;
    flex: 1 0 0;
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: 44%;
    margin-left: -55px;
    background-size: 358px 200px;
  }

  &__text {
    display: flex;
    min-width: 181px;
    max-width: 400px;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
  }

  &__title {
    align-self: stretch;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  &__subtitle {
    font-size: 12px;
    line-height: 18px;
  }
}
