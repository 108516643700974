.dashboard-pending-resources {
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
  }

  .card-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    .card {
      padding: 10px 8px;
      border-radius: $g-value-border-radius-xl;
      background-color: #f7f7f9;
      display: flex;
      flex: 1;
      align-items: center;

      .left {
        margin-right: 14px;
      }

      .right {
        .title {
          @include font-body-4;
        }

        .subtitle {
          @include font-headline-3;
        }
      }
      &:hover {
        @include h-general-transition(color);
        cursor: pointer;
        .title {
          color: $g-color-primary;
        }
      }
    }
  }
}
