&--search-wiki {
  width: 100%;

  #{$root} {
    &__input {
      border-width: 0;
      background-color: transparent;
      color: $g-color-white;
      width: 100%;

      &::placeholder {
        color: rgba($g-color-white, 0.3);
      }
    }
  }
}
