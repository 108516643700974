&--upload {
  .c-form-field {
    &__inner {
      cursor: pointer;
      position: relative;
      z-index: 1;
      width: 100%;
      height: 126px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 28px 60px;
      border: 1px dashed rgba(#000e4b, 0.3);
      background: #eef5ff;
      border-radius: $g-value-border-radius-xl;
    }
    &__body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &__icon {
      color: #0f5ef7;
      font-size: 32px;
      margin-bottom: 10px;
    }
    &__info {
    }

    &__info-info {
      @include font-body-4;
      letter-spacing: 0;
      text-align: center;
      color: rgba(#000e4b, 0.5);
    }

    &__input {
      position: absolute;
      z-index: -1;
      clip: rect(0 0 0 0);
      width: 1px;
      height: 1px;
      margin: -1px;
    }
  }
}
