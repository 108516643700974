&--clipboard {
  #{$root} {
    &__inner {
      position: relative;

      .copy_button {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &__input {
      padding-right: 30px;
    }

    &__help {
      margin-top: 8px;
      color: rgba($g-color-secondary, 0.3);
      display: inline-flex;
      align-items: center;

      &-icon {
        display: inline-flex;
        align-items: center;
        &::before {
          font-family: 'platform500';
          content: '\ebb0';
          font-size: 16px;
          margin-right: 6px;
        }
      }

      &-text {
        @include font-body-4;
      }
      &.-error {
        color: $g-color-red;
      }
    }
  }
}
