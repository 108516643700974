.pre {
  white-space: pre-line;
}

.ws-nowrap {
  white-space: nowrap;
}

.pre-wrap {
  white-space: pre-wrap;
}

.fw-bold {
  font-weight: 700 !important;
}

.font-body-2 {
  @include font-body-2;
}

.font-body-4 {
  @include font-body-4;
}

.font-body-5 {
  @include font-body-5;
}
.font-headline-2 {
  @include font-headline-2;
}
.font-headline-3 {
  @include font-headline-3;
}

.font-headline-4 {
  @include font-headline-4;
}

.font-headline-5 {
  @include font-headline-5;
}

.fw-normal {
  font-weight: 400;
}

.capitalize {
  text-transform: capitalize;
}

.flex-1 {
  flex: 1;
}
