@mixin fontMixin($size, $lineHeight, $fontWeight) {
  font-size: $size + px;
  line-height: $lineHeight + px;
  font-weight: $fontWeight;
}

@mixin calculateFontMixin($size, $sizeTablet, $sizeDesktop, $lineHeight, $lineHeightTablet, $lineHeightDesktop, $fontWeight) {
  font-size: $size + px;
  line-height: $lineHeight + px;
  font-weight: $fontWeight;

  @media (min-width: ($mobile-width + 1px)) {
    font-size: $sizeTablet + px;
    line-height: $lineHeightTablet + px;
  }

  @media (min-width: ($tablet-width + 1px)) {
    font-size: $sizeDesktop + px;
    line-height: $lineHeightDesktop + px;
  }
}

@mixin font-body-1 {
  @include calculateFontMixin(16, 16, 18, 22, 22, 26, 400);
}

@mixin font-body-2 {
  @include calculateFontMixin(14, 14, 16, 20, 20, 22, 400);
}

@mixin font-body-3 {
  @include fontMixin(14, 20, 400);
}

@mixin font-body-4 {
  @include fontMixin(12, 18, 400);
}

@mixin font-body-5 {
  @include fontMixin(10, 16, 400);
}

@mixin font-headline-1 {
  @include calculateFontMixin(20, 20, 24, 26, 26, 30, 700);
}

@mixin font-headline-2 {
  @include calculateFontMixin(16, 16, 18, 22, 22, 26, 700);
}

@mixin font-headline-3 {
  @include calculateFontMixin(14, 14, 16, 20, 20, 22, 700);
}

@mixin font-headline-4 {
  @include fontMixin(14, 20, 700);
}

@mixin font-headline-4-sm {
  @include fontMixin(14, 20, 600);
}

@mixin font-headline-5 {
  @include fontMixin(12, 18, 700);
}

@mixin font-headline-6 {
  @include fontMixin(10, 16, 700);
}
