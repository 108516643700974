&--offers {
  max-width: min-content;

  .c-modal {
    &__head {
      background-image: url('../../../assets/new-img/modals/offer-modal.svg');
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: cover;
      height: 120px;
      margin-bottom: 30px;
      padding: 0;
      display: flex;

      &.-first-step {
        background-image: url('../../../assets/new-img/modals/offer-modal-start.svg');
      }
      &.-middle-step {
        background-image: url('../../../assets/new-img/modals/offer-modal-middle.svg');
      }
      &.-last-step {
        background-image: url('../../../assets/new-img/modals/offer-modal-end.svg');
      }
    }
    &__head-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin-left: 38px;
    }
    &__title {
      @include font-headline-2;
    }
    &__subtitle {
      @include font-body-3;
      color: $g-color-black;
      max-width: calc(95px + 45%);
      text-align: left;
    }
  }

  .loading {
    opacity: 0.3;
    position: relative;
  }

  .loader {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    p500-ui-cube-preloader {
      position: sticky;
      top: 30%;
    }
  }

  .form {
    border: 1px solid transparent;
    padding: 10px;
    border-radius: $g-value-border-radius-xl;
    width: 100%;

    &.failed {
      border-color: $g-color-red;
      margin-bottom: 10px;
    }
  }

  .tabs {
    .p500-ui-tabs__wrapper {
      background-color: $g-color-white;
      padding: 0;

      ul {
        padding: 0;
        align-items: stretch;
        background-color: rgba($g-color-primary, 0.04);
        user-select: none;

        li {
          @include font-headline-5;
          counter-increment: number;
          padding: 16px 20px;
          display: flex;
          align-items: center;
          white-space: pre;
          color: rgba($g-color-black, 0.55);

          &::before {
            @include font-headline-4;
            content: counter(number);
            width: 30px;
            min-width: 30px;
            height: 30px;
            border-radius: 6px;
            background-color: rgba($g-color-primary, 0.05);
            color: rgba($g-color-black, 0.3);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 13px;
          }

          &.has-changes::before {
            background-color: rgba(255, 166, 64, 0.2);
            color: #f6a343;
          }

          &::after {
            display: none;
            opacity: 1;
          }

          &:not(:first-child) {
            padding-left: 39px;
            &::after {
              display: block;
              font-family: 'platform500';
              content: '\eb43';
              font-size: 20px;
              border-bottom: none;
              transform: none;
              background: none;
              height: initial;
              width: initial;
              left: 12px;
              bottom: initial;
              color: rgba($g-color-black, 0.1);
            }
          }

          &.is-active {
            background-color: rgba($g-color-primary, 0.04);
            color: $g-color-primary;

            &::before {
              background-color: $g-color-primary;
              color: $g-color-white;
            }
            &::after {
              color: $g-color-primary;
            }
          }
        }
      }
    }
  }

  .c-dot-separator {
    background-color: rgba($g-color-primary, 0.1);
  }
}
