&--search {
  #{$root} {
    &__inner {
      align-items: center;
      width: 100%;
    }

    &__input {
      text-align: left;
      width: 100%;
      min-width: 150px;
      color: $g-color-secondary;
      @include font-body-3;
      border-radius: 5px;
      border: solid 1px $g-color-gray;
      background-color: rgba($g-color-light-gray, 0.5);
      padding: 4px 34px;
      @include h-general-transition(box-shadow, border-color);

      &:focus,
      &:active {
        background-color: $g-color-white;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.07);
        border-color: rgba($g-color-secondary, 0.2);

        & + #{$root}__label {
          opacity: 1;
        }

        & + #{$root}__icon {
          color: $g-color-secondary;
        }
      }

      &:hover {
        background-color: $g-color-white;
      }

      &::placeholder {
        color: rgba($g-color-secondary, 0.3);
      }
    }

    &__icon {
      display: flex;
      align-items: center;
      height: 100%;
      position: absolute;
      left: 10px;
      top: 0;
      @include font-headline-4;
      color: rgba($g-color-secondary, 0.5);
      @include h-general-transition(color);
    }

    &.-white {
      #{$root} {
        &__input {
          background-color: $g-color-white;
        }
      }
    }
  }
}
