&--list {
  .search-wrapper {
    gap: 5px;
    display: grid;
    grid-template-columns: minmax(100px, 150px) minmax(150px, 1fr);

    &--dropdown {
      grid-template-columns: 1fr;
      min-width: 250px;

      @media (max-width: 450px) {
        min-width: 150px;
      }
    }
  }

  #{$root} {
    &__item {
      white-space: nowrap;
      cursor: pointer;

      &:after {
        margin: 4px 0;
        display: block;
        height: 1px;
        width: 100%;
        background-color: $g-color-gray;
        opacity: 0.4;
        content: '';
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }

    &__item-text {
      display: block;
      padding: 4px 8px;
      border-radius: $g-value-border-radius-xl;
      text-align: left;
      min-width: 120px;
      @include font-body-3;
      @include h-general-transition;

      &:hover {
        color: $g-color-primary;
        background-color: #ebf8ff;
      }
    }

    &__item-button {
      padding: 5px 10px;
      border-radius: $g-value-border-radius-xl;
      display: block;
      text-align: left;
      color: $g-color-secondary;
      @include drop-button-styles;
      @include font-body-3;

      &.is-active,
      &:hover {
        color: $g-color-primary;
      }

      &:hover {
        background-color: rgba(15, 94, 247, 0.07);
      }
    }

    &__icon {
      font-size: 7px;
      align-items: center;
      margin-left: 4px;
      display: flex;
    }

    &__header-value {
      color: $g-color-primary;
      display: inline-flex;
      align-items: center;
      @include font-headline-4;
    }

    &__grey-dot-button {
      width: 32px;
      height: 32px;
      background-color: rgba($g-color-primary, 0.15);
      color: $g-color-primary;

      &:hover {
        color: $g-color-white;
        background-color: $g-color-primary;
      }
    }
  }
}
