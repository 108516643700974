@font-face {
    font-family: 'Roboto Flex', system-ui;
    src: url(/assets/fonts/roboto-flex/RobotoFlex-VariableFont.ttf) format('truetype');
}

*,
.p500-ui-roboto-flex {
    font-family: "Roboto Flex", system-ui;
    font-optical-sizing: auto;
    font-variation-settings:
        "slnt" 0,
        "wdth" 100,
        "GRAD" 0,
        "XOPQ" 96,
        "XTRA" 468,
        "YOPQ" 79,
        "YTAS" 750,
        "YTDE" -203,
        "YTFI" 738,
        "YTLC" 514,
        "YTUC" 712;
}
