.c-page-header {
  margin-bottom: 12px;
  $root: &;

  gtd-ui-auth-as .p500-ui-icon-auth-as {
    padding: 0 13px;
    background-color: transparent;
    border: 1px solid rgba(0, 14, 75, 0.3);
    height: 32px;
    border-radius: 16px;
    align-items: center;
  }

  @include media-breakpoint-up(sm) {
    margin-bottom: 18px;
  }

  @include media-breakpoint-down(xs) {
    &.-hide-on-mobile {
      display: none;
    }
  }

  &__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }

  &__back {
    // todo: icon font-size
    font-size: 12px;
    margin-right: 10px;

    @include media-breakpoint-up(md) {
      font-size: 20px;
      margin-right: 14px;
    }
  }

  &__page-title {
    display: flex;
    align-items: center;
  }

  &__status {
    display: flex;
    flex-direction: row;
  }

  &__hint-button {
    position: relative;
    left: 5px;

    @media screen and (max-width: 480px) {
      display: none;
    }
  }

  &__control-button {
    align-self: center;
    margin-left: 16px;
  }

  &__title {
    @include font-headline-1;
    display: flex;

    span {
      color: rgba($g-color-secondary, 0.5);
      display: none;

      @include media-breakpoint-up(md) {
        display: initial;
      }
    }
  }

  &__subtitle {
    @include font-body-2;
    display: inline-flex;
    align-items: center;
    text-align: center;
  }

  &__page-actions {
    margin-left: auto;
    display: flex;
    align-items: center;

    &.--desktop {
      display: none;

      @media (min-width: 795px) {
        display: flex;
        align-items: center;
      }
    }
  }

  &__action {
    display: flex;
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
    &_collapsed {
      display: flex;
      height: 38px;
      padding: 8px 12px;
      align-items: center;
      gap: 8px;
    }
  }

  &__add {
    @media (min-width: 600px) {
      margin-left: 14px;
    }
  }

  &__refresh,
  &__action-title {
    display: none;

    @include media-breakpoint-up(sm) {
      display: flex;
    }
  }

  &__content-right {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 15px;

    &--sm-center {
      margin-right: auto;

      @include media-breakpoint-up(sm) {
        margin-right: 0;
      }
    }

    .changelog-select {
      width: 75px;
    }
  }

  &--tool {
    padding-bottom: 22px;
    margin-bottom: 0;
    border-bottom: 1px solid $g-color-gray;
  }

  &--changelog {
    padding-bottom: 22px;
    margin-bottom: 22px;
    border-bottom: 1px solid $g-color-gray;

    #{$root} {
      &__inner {
        flex-direction: column;
        align-items: start;

        @include media-breakpoint-up(md) {
          flex-direction: row;
          align-items: center;
        }
      }

      &__content-right {
        margin-top: 4px;
        margin-left: 0;

        @include media-breakpoint-up(md) {
          margin-top: 0;
          margin-left: auto;
        }
      }
    }
  }
}
