@use "sass:math";
.l-page {
  $root: &;
  position: relative;

  #{$root} {
    &__inner {
      display: flex;
      flex-flow: row wrap;
      margin: 0 math.div(-$grid-gutter-width, 2);
      margin-bottom: -$grid-gutter-width;
    }

    &__content {
      margin: math.div($grid-gutter-width, 2);
      max-width: 700px;
      width: 100%;

      &--large {
        max-width: 780px;
      }
    }
  }

  &--full {
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    #{$root} {
      &__header {
        margin: 0;
      }

      &__content {
        display: flex;
        margin: 0;
        max-width: initial;
        width: 100%;
        flex: 0 1 100%;
        height: 100%;
      }
    }
  }

  &--tool {
    #{$root} {
      &__content {
        flex-direction: column;
        @include media-breakpoint-up(xl) {
          flex-direction: row;
        }
      }

      &__section {
        padding-bottom: 22px;
        width: 100%;
        @include media-breakpoint-up(md) {
          height: 100%;
        }

        &.-left-equal {
          @include media-breakpoint-up(xl) {
            flex: 0 0 50%;
          }
        }

        &.-right-equal {
          @include media-breakpoint-up(xl) {
            flex: 0 0 50%;
          }
        }

        &.-left {
          @include media-breakpoint-up(xl) {
            flex: 0 0 40%;
            width: 40%;
          }
        }

        &.-right {
          @include media-breakpoint-up(xl) {
            flex: 0 0 60%;
            width: 60%;
          }
        }
      }
    }
  }
}
