&--link-generator {
  .select {
    .ng-option-label {
      display: block;
      width: 100%;
    }

    .ng-value {
      width: 100%;
      padding-right: 30px;

      &-label {
        width: 100%;
      }
    }

    &__value {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    &__type {
      color: $g-color-green;
    }
  }

  .warning-list {
    li {
      @include font-body-4;
      color: $g-color-red;
    }
  }
}
