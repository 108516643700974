.is-disabled {
  pointer-events: none;
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

button {
  &[disabled='disabled'],
  &:disabled {
    pointer-events: none;
    opacity: 0.1;
  }
}
p500-ui-icon {
  &.--disabled {
    opacity: 0.1;
    pointer-events: none;
  }
}
