.cdk-overlay-container {
  position: relative;
}
.p-drilldown {
  &__content {
    display: flex;
    gap: 16px;
    margin-left: 0;
  }
  &__filters {
    border: 1px solid $g-color-gray;
    background-color: $g-color-white;
    border-radius: 8px;
    position: relative;

    .view-report-button {
      width: 100%;
      padding: 0 24px 16px 16px;
    }
  }
  &__filters-menu {
    height: 80vh;
    overflow: auto;
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 8px;
    .label {
      color: #000;
      @include font-headline-3;
      margin-bottom: 16px;
    }
    .item {
      width: 100%;
    }

    .buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      align-self: stretch;
    }
  }

  &__filters-menu-group {
    .groups-form {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .add-group-button {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 16px;
      align-self: stretch;
      margin-top: 8px;

      button {
        @include font-headline-4;
      }
    }
    .control-buttons {
      display: flex;
      width: 30px;
      height: 30px;
      padding: 6px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 4px;
      border: 1px solid #dedfe4;
      background-color: $g-color-white;
    }

    .selector-with-control-buttons {
      display: flex;
      align-items: end;
      gap: 4px;
      align-self: stretch;
    }
  }

  &__filters-menu-date {
    .groups-form {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .date-control {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      gap: 16px;
    }

    .date-range {
      display: flex;
      order: 2;
      flex-wrap: wrap;
      @media (min-width: 1441px) {
        flex-direction: column;
        align-items: baseline;
        gap: 4px;
      }
      @media screen and (min-width: 320px) and (max-width: 390px) {
        flex-direction: column;
        align-items: baseline;
        gap: 4px;
      }
      .c-tabs {
        margin-bottom: 4px;
      }
    }
    .p500-ui-form-field.p500-ui-form-field--range-datepicker-new .p500-ui-form-field__daterange {
      width: 100%;
    }
  }

  &__filters-menu-condition {
    .add-condition-button {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: var(--Space-500, 16px);
      align-self: stretch;
      margin-top: 8px;

      button {
        @include font-headline-4;
      }
    }

    .p500-ui-form-field.p500-ui-form-field--presets {
      .p500-ui-form-field__field--option {
        max-width: 100%;
        flex: 0 1 100%;
        width: 100%;
      }
    }

    .p500-ui-form-field--presets {
      .p500-ui-form-field__field-with-remove {
        display: block !important;
        width: 100%;

        .p500-ui-form-field__field {
          width: 100%;
        }

        .p500-ui-form-field__delete {
          visibility: hidden;
          height: 0;
        }
      }
    }
  }

  &__filters-menu-preset {
    .preset-label {
      display: flex;
      gap: 16px;
      @include font-headline-3;
      margin-bottom: 16px;
      &__label {
        flex: 1 1 0;
      }
      &__icon {
        cursor: pointer;
      }
    }
    .preset-list {
      display: flex;
      align-items: flex-start;
      align-content: flex-start;
      gap: 8px;
      align-self: stretch;
      flex-wrap: wrap;

      &__item {
        @include font-headline-5;
        display: flex;
        height: 28px;
        padding: 4px 8px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        border: 1px solid #dedfe4;
        cursor: pointer;
        &_selected {
          background: $g-color-primary;
          color: $g-color-white;
        }
      }
      &__dropdown {
        @media (max-width: $mobile-width) {
          .p500-ui-dropdown__body-inner {
            right: 0;
            left: unset;
          }
        }
      }
    }
    .preset-empty {
      color: #757a90;
      @include font-body-3;
    }
  }

  &__filters-settings {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }

  &__table-container {
    overflow: hidden;
    overflow-y: auto;
    height: 88vh;
  }

  &__table {
    border-radius: 8px;
    overflow: auto;
    background-color: $g-color-white;
    min-height: 300px;
    border: 1px solid #e6e6e6;

    &.-empty {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #e6e6e6;
    }

    .table-row {
      display: flex;
      height: 32px;

      &:hover .table-cell {
        background-color: #ebebef;
      }

      &__total {
        &:hover .table-cell {
          background-color: #f0f5fe;
        }

        background-color: #f0f5fe;
        height: 36px;
      }
    }

    .table-header {
      .table-row {
        height: 52px;
        &:hover .table-cell {
          background-color: initial;
          cursor: pointer;
        }
      }

      .table-cell {
        border-bottom: 1px solid #e6e6e6;
        .table-cell-text {
          width: 100%;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
          word-break: break-word;
          margin-right: 8px;
          @include font-headline-5;
        }
      }
    }

    .table-cell {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-self: stretch;
      flex-shrink: 0;
      border-right: 1px solid #e6e6e6;
      width: 120px;
      padding: 8px;
      text-align: right;
      @include font-body-3;

      &_bolded {
        @include font-headline-4;
        background-color: #f0f5fe;
      }
    }

    .table-cell:last-child {
      border-right: none;
    }

    .table-cell:first-child {
      max-width: 256px;
      min-width: 150px;
      text-align: left;
    }

    .table-cell_loading {
      display: flex;

      .loader {
        @include skeleton(rgba($g-color-gray, 0), rgba($g-color-gray, 0.8));
        background-color: $g-color-light-gray;
        opacity: 1;
        height: 16px;
        width: 100%;
        border-radius: 8px;
      }
    }

    .table-cell__grouper-cell {
      flex: 1 1 auto;
      min-width: 0;
      max-width: 100%;
    }
    .table-cell__relation {
      max-width: 256px;
      min-width: 150px;
    }
    .table-cell_sorted {
      width: 140px;
    }
  }

  &__quick-filters {
    .filters {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-bottom: 24px;
    }
  }

  &__beta {
    padding: 0 4px;
    border-radius: 360px;
    border: 1px solid $g-color-primary;
    color: $g-color-primary;
    text-align: center;
    @include font-headline-5;
  }

  &__faq {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #b8d6ff;
    background: #f3f8ff;
    @media (min-width: 1441px) {
      margin-bottom: 16px;
    }

    .faq {
      &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
      }
      &__content {
        display: flex;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;
      }
      &__questions {
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: space-between;
      }
      &__answer-question {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
      &__video {
        .frame-wrapper {
          position: relative;
          width: 100%;
          padding-top: 56.25%;
          border: #0f5ef7;
          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
          }
        }
      }
    }
  }
  &__dropdown-container {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: none;
    .p500-ui-form-field__datepicker-dropdown {
      z-index: 100;
      position: absolute;
      margin: 4px 0;
      padding: 20px 10px;
      background-color: $g-color-white;
      border-radius: 8px;
      border: 1px solid $g-color-gray;
    }
  }
}
