.notifications-settings {
  display: flex;
  flex-wrap: wrap;

  .loading {
    position: relative;
    &::after {
      content: '';
      border-radius: 10px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1024;
      @include skeleton(rgba($g-color-white, 0), rgba($g-color-white, 0.8));
    }
  }

  &__channel {
    display: flex;
    flex-direction: column;
    width: 400px;
    max-width: 100%;
    padding: 5px 18px 18px;
    margin-right: 30px;
    margin-bottom: 30px;
    margin-top: 30px;
    background: #fff;
    border: 1px solid rgba(0, 14, 75, 0.1);
    border-radius: 10px;
  }
  &--channels {
    width: 600px;
  }
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    & > div {
      flex: 1;
      min-width: 200px;
    }
  }
  &__top {
    display: flex;
    justify-content: space-between;
    min-height: 60px;
    border-bottom: 1px solid #f2f3f6;
    margin-bottom: 5px;
  }
  &__title {
    @include font-headline-2;
    align-items: center;
    display: flex;
    margin: 0;
    img {
      height: 22px;
      margin-right: 15px;
    }
  }
  &__workspace {
    margin-left: 10px;
    background: #f2f3f6;
    border-radius: 5px;
    padding: 2px 8px;
    font-size: 13px;
  }
  &__settings {
    display: flex;
    align-items: center;
    align-self: center;
    background: $g-color-secondary;
    color: #fff;
    font-size: 13px;
    padding: 4px 10px;
    border-radius: 8px;
    font-weight: bold;
    &:hover {
      cursor: pointer;
      text-decoration: none;
      background: $g-color-primary;
      color: #fff;
    }
    i {
      margin-right: 4px;
      font-size: 12px;
    }
  }
  &__heading {
    display: flex;
    align-items: center;
    i {
      padding-right: 7px;
    }
  }
  &__address {
    background: #f7f7f9;
    padding: 12px;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  &__input {
    width: 240px;
    border: none;
    outline: none;
    box-shadow: none;
    background: none;
  }
  &__img {
    width: 100%;
    max-width: 100%;
    margin-bottom: 40px;
  }
  &__no-data {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    img {
      height: 80px;
    }
    p {
      margin: 20px 0 15px;
      color: $g-color-secondary;
    }
    a {
      @include font-headline-4;
      display: flex;
      align-items: center;
      color: $g-color-primary;
      &:hover {
        color: $g-color-primary-hover;
        text-decoration: none;
      }
    }
  }
  .p500-ui-form-field--group-checkbox.p500-ui-form-field--indeterminate {
    background: #f7f7f9;
    .p500-ui-form-field__group-title {
      margin-left: 10px;
      line-height: 12px;
    }
  }
  .p500-ui-form-field__group-head,
  .p500-ui-form-field__inner {
    flex-direction: row-reverse;
  }
  .p500-ui-form-field.p500-ui-form-field--checkbox .p500-ui-form-field__inner.-reverse .p500-ui-form-field__label {
    flex-direction: row;
    &::before {
      margin-left: 0;
      margin-right: 10px;
    }
  }
}

.notifications-channels {
  &__remove {
    @include font-body-4;
    border: none;
    box-shadow: none;
    padding: 1px 6px;
    border-radius: 5px;
    background: rgba(0, 14, 75, 0.05);
    &:hover {
      background: rgba(247, 15, 15, 0.05);
      color: #f70f0f;
      transition: 0.2s ease-in-out;
    }
  }
  .notifications-settings {
    &__address {
      font-weight: bold;
    }
  }
  .p500-ui-form-field .p500-ui-form-field__input {
    padding: 8px 12px;
  }
}
