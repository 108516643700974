&--term {
  position: relative;

  #{$root} {
    &__term {
      &:not(:last-child) {
        &:after {
          content: '';
          display: block;
          height: 1px;
          background-color: rgba($g-color-gray, 0.4);
          margin: 6px 0;
        }
      }
    }
  }

  .cdk-drag-placeholder {
    .inner {
      height: 64px;
      background-color: $g-color-white;
      border: 1px dashed $g-color-primary;
      box-sizing: border-box;
      border-radius: $g-value-border-radius-xl;
    }

    .border {
      display: block;
      height: 1px;
      background-color: rgba($g-color-gray, 0.4);
      margin: 6px 0;
    }
  }
}
