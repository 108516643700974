.c-repush-settings {
  display: flex;

  &__sidebar {
    background-color: $g-color-light-blue;
    border-radius: 8px;
    text-align: right;

    &-head {
      @include font-headline-5;
      white-space: nowrap;
      width: 100%;
      padding: 10px 14px;
      border-bottom: 1px solid rgba($g-color-secondary, 0.1);
    }

    &-body {
      padding: 15px 14px;
    }
  }

  &__field-name {
    white-space: nowrap;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &__body {
    padding-top: 10px;
    flex-basis: 100%;
    margin-left: 20px;

    &-head {
      @include font-headline-5;
      margin-bottom: 21px;

      &:after {
        margin-left: 4px;
        content: '*';
        color: $g-color-red;
      }
    }
  }

  &__select {
    &:not(:last-child) {
      margin-bottom: 14px;
    }
  }

  &__unique-title {
    @include font-headline-3;
  }
}
