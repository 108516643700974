.c-simple-card {
  $root: &;
  $primary-black: #000932;
  @include font-body-4;

  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 10px 20px;
  background: rgba($primary-black, 0.03);

  &__header {
    @include font-body-3;
    border-bottom: 1px solid $g-color-gray;
    padding-bottom: 3px;
  }

  .-line-height-16 {
    line-height: 16px;
  }
  .-line-height-10 {
    line-height: 10px;
  }
  .-margin-top-15 {
    margin-top: 15px;
  }

  &__body {
    display: flex;
  }

  &__info {
    display: flex;
    flex-direction: column;
    margin-right: 14px;
    margin-top: 5px;
  }

  &__history {
    display: flex;
    flex-direction: column;
    margin-left: 14px;
  }

  &__text {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 80px;
  }
}
