&--builder {
  display: flex;
  flex-wrap: wrap;

  &__top {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
    background-color: $g-color-pale-blue;
    border-bottom: 1px solid $g-color-gray;
    margin: -14px -18px 14px -14px;
    padding: 14px;
    border-radius: 10px 10px 0 0;

    &__btn {
      @include font-body-3;
      @include h-general-transition;
      background-color: $g-color-white;
      display: flex;
      align-items: center;
      padding: 4px 10px;
      gap: 8px;
      border-radius: 5px;
      border: 1px solid $g-color-gray;

      &:hover,
      &.-active {
        background-color: $g-color-primary;
        color: $g-color-white;
        border-color: $g-color-primary;
      }
    }
  }

  &__filter-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 8px;

    &.-compare-with {
      @media (max-width: 1280px) and (min-width: 1025px), (max-width: 910px) {
        flex-direction: column;
        width: auto;
        gap: 4px;

        .c-line--split-label {
          flex-direction: row;
          height: 50px;
          margin: 0;
        }

        .c-line__line {
          height: 1px;
          width: auto;
        }

        .c-line__label {
          margin: 0 10px;
        }
      }
    }

    &__label {
      @include font-body-4;
      flex-direction: column;
      display: flex;
      justify-content: center;
      margin-bottom: 4px;
      color: rgba($g-color-dark-blue, 0.7);
    }

    &__date_interval {
      display: flex;
      flex-direction: column;
      justify-content: end;
      margin-right: 16px;

      p500-ui-select-field {
        min-width: 140px;

        @include media-breakpoint-down(xs) {
          width: 100%;
        }
      }

      @include media-breakpoint-down(xs) {
        width: 100%;
        max-width: initial;
        margin-right: 0;

        &:not(:last-child) {
          margin-bottom: 14px;
        }
      }
    }

    &__currency_select {
      width: 357px;
    }

    .-grouper-select {
      width: 160px;
      @media (min-width: 373px) and (max-width: 586px) {
        width: 240px;
      }
    }

    .-visible-groupers {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }
  }

  #{$root} {
    &__section {
      flex: 0 0 100%;
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }

      @media (min-width: 480px) {
        flex: 0 0 220px;
        max-width: 220px;
      }

      &.-comparing-analytics {
        .-preset-select {
          max-width: 160px;

          @media (max-width: 481px) {
            max-width: 100%;
            width: 100%;
          }
        }

        @media (min-width: 480px) {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }

      &__preset-mobile-button {
        display: none;

        @include media-breakpoint-down(xs) {
          display: flex;
          margin-bottom: 0;

          .p500-ui-button {
            width: 100%;
            justify-content: center;
          }
        }
      }

      &__preset {
        display: flex;
        align-items: center;
        margin: 0;

        &__icon {
          @include font-body-4;
          color: rgba($g-color-secondary, 0.8);
          display: flex;
          align-items: center;
          gap: 10px;
          margin-right: 10px;
          line-height: 1;

          &__ico {
            width: 30px;
            height: 30px;
            background: rgba(0, 14, 75, 0.05);
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  &.-preset {
    #{$root} {
      &__section {
        @media (min-width: 786px) {
          &:nth-child(3n) {
            margin-right: 0;
          }
        }
      }
    }
  }
}
