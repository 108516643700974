&--drilldown-preset {
  .fields {
    justify-content: center;
    gap: 16px;
    .preset-date {
      display: flex;
      gap: 6px;
      flex-direction: column;
      .c-tabs {
        flex-wrap: wrap;
        margin-bottom: 4px;
        .c-tabs__item {
          margin-top: 6px;
        }
      }
    }
  }
}
