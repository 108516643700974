/**
 * Variables
 */

// Global Colors

$g-color-table-hover: #e2ecfe;
$g-color-sidebar-primary: #f7fafe;
$g-color-primary: #0f5ef7;
$g-color-primary-hover: #0d54dd;
$g-color-secondary: #000932; // -- ??
$g-color-secondary-dark: #859af3;
$g-color-dark-blue: #000e4b;
$g-color-white: #ffffff;
// $g-color-black: #000000;
$g-color-black-icon-background: #193090;
$g-color-black: #000932; // new redesign color
$g-color-bg: #fcfcfc;
$g-color-sidebar-bg: #f7f9fa;
$g-color-gray: #e6e6e6;
$g-color-light-gray: #f1f1f1;
$current-bg-gray-3: #193090;
$g-color-light-blue: #eef5ff;
$g-color-pale-blue: #f7f8fa;
$g-color-red: #f70f0f;
$g-color-red-no-opacity: #ffecec;
$g-color-red-hover: #dd0d0d;
$g-color-orange: #f0560e;
$g-color-additional-orange: #ff7534;
$g-color-yellow: #f8ae6a;
$g-color-green: #20b506;
$g-color-green-no-opacity: #eef9ec;
$g-color-green-hover: #1ca205;
$g-color-cyan: #0fd8bb;
$g-color-turquoise: #2db1aa;
$g-color-dark-cyan: #37c0e5;
$g-color-dark-cyan-2: #09bcfa;
$g-color-indigo-blue: #2617d8;
$g-color-purple: #c94ffd;
$g-color-violet: #6633cb;
$g-color-cerise: #d6138e;
$g-color-hotpink: #e782f0;
$g-natural-light-gray: #f4f5f7;
$g-color-gray-text: #666b84;
$g-color-text-disabled: #b0b3bf;
$g-color-gray-no-opacity: #e6edff;

$g-color-pattern-gold: #ffdda6;
$g-color-pattern-blue: #0061ff;
$g-color-pattern-red: #fc5151;

$g-color-map-notification-backdrop: #fafafb;
$g-color-gray-background: #f7f7f9;

// Tree palitre
$g-color-light-yellow: #f8d8bc;
$g-color-light-orange: #ffc3b6;
$g-color-light-purple: #dccbff;
$g-color-light-green: #adf3e2;
$g-color-light-cyan: #aae5f5;
$g-color-light-gray2: #dfe2f1;

// Global Values
$g-value-border-radius: 4px;
$g-value-border-radius-xl: 10px;
$g-value-animation-duration: 0.15s;
$g-value-animation-function: ease;
$g-value-transition: $g-value-animation-duration $g-value-animation-function;

$y-main-content-mobile-padding: 20px;
$y-main-content-padding: 22px;
$x-main-content-mobile-padding: 15px;
$x-main-content-table-padding: 18px;
$x-main-content-padding: 32px;
$height-top-bar: 64px;

// Z-index
$z-table: 10;
$z-widget-report-builder: 11;
$z-helpdesk: 12;
$z-sidebar: 13;
$z-top-bar: 13;
$z-settings: 20;
$z-filters: 20;
$z-datepicker: 30;
$z-widget-actions: 1000;
$z-overlay: 1010;
$z-float-notification: 1020;
$z-tree-filters: 20;

// Bootstrap Settings
$grid-breakpoints: (
  xs: 0,
  sm: 480px + 1px,
  md: 600px + 1px,
  mx: 794px + 1px,
  lg: 1024px + 1px,
  xl: 1280px + 1px,
  xxl: 1440px + 1px,
  xxxl: 1630px + 1px,
);

$grid-gutter-width: 20px;
