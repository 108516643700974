.c-drilldown-data {
  $root: &;

  &__table {
    position: relative;

    box-sizing: border-box;
    width: 100%;
    margin: 0 auto;

    text-align: left;

    background-color: $g-color-white;

    overscroll-behavior: contain;

    .-additional {
      flex-grow: 2.5;
      min-width: 150px;
      text-align: left;
    }
  }

  &__empty {
    border: 1px solid $g-color-gray;
    border-radius: 8px;
  }

  &__header {
    position: sticky;
    top: -24px;
    z-index: 3;

    border-radius: 8px;

    #{$root} {
      &__scroll {
        &.-scrolled {
          border-top-right-radius: 0;
          border-top-left-radius: 0;

          #{$root} {
            &__th {
              &:first-child {
                border-top-left-radius: 0;
              }

              &:last-child {
                border-top-right-radius: 0;
              }
            }
          }
        }
      }
    }
  }

  &__scroll {
    display: flex;

    overflow: hidden;

    background-color: $g-color-white;
  }

  &__body {
    overflow: auto;

    .c-drilldown-data__td {
      background-color: $g-color-white;
    }

    .report-row:nth-child(2n) > div[class*='c-drilldown-data__tr-level-'] > .c-drilldown-data__tr {
      #{$root} {
        &__td {
          background-color: $g-color-map-notification-backdrop;

          &.-dark-bg {
            background-color: rgba($g-color-secondary-dark, 0.12);
          }
        }
      }
    }

    .has-child.is-opened {
      #{$root} {
        &__tr-child {
          display: block;

          .report-row:nth-of-type(2n) > {
            #{$root} {
              &__tr > {
                #{$root} {
                  &__td {
                    background-color: $g-color-map-notification-backdrop;
                  }
                }
              }
            }
          }
        }
      }
    }

    .report-row > .has-child.is-opened[class*='c-drilldown-data__tr-level-'] > {
      #{$root} {
        &__tr {
          #{$root} {
            &__td {
              &,
              .p500-ui-icon,
              .c-field-value__text.-light-gray {
                color: $g-color-white;
              }

              background-color: $g-color-secondary;

              &:first-child {
                &::before {
                  color: $g-color-white;
                  transform: rotate(-90deg);
                }
              }
            }
          }
        }
      }
    }

    .has-child > {
      #{$root} {
        &__tr > {
          #{$root} {
            &__td:first-child {
              &::before {
                content: '\eb43';
                position: absolute;
                left: 8px;
                width: 12px;
                font-family: 'platform500';
                font-size: 16px;
                color: $g-color-primary;
              }
            }
          }
        }
      }
    }
  }

  &__tr {
    position: relative;
    display: flex;
    cursor: pointer;

    &-level-1 {
      &:first-child #{$root} {
        &__td:first-child {
          flex-basis: 240px;
          min-width: 140px;
          padding-left: 34px;

          &::before {
            left: 17px !important;
          }
        }
      }
    }

    &-level-2 {
      &:first-child #{$root} {
        &__td:first-child {
          flex-basis: 230px;
          min-width: 130px;
          padding-left: 44px;

          &::before {
            left: 27px !important;
          }
        }
      }
    }

    &-level-3 {
      &:first-child #{$root} {
        &__td:first-child {
          flex-basis: 220px;
          min-width: 120px;
          padding-left: 54px;

          &::before {
            left: 37px !important;
          }
        }
      }
    }

    &-level-4 {
      &:first-child #{$root} {
        &__td:first-child {
          flex-basis: 210px;
          min-width: 110px;
          padding-left: 64px;

          &::before {
            left: 47px !important;
          }
        }
      }
    }

    &-level-5 {
      &:first-child #{$root} {
        &__td:first-child {
          flex-basis: 200px;
          min-width: 100px;
          padding-left: 74px;

          &::before {
            left: 57px !important;
          }
        }
      }
    }

    &-child {
      position: relative;
      display: none;
    }
  }

  &__th {
    white-space: nowrap;
    box-sizing: content-box;
    flex-grow: 1;
    flex-basis: 250px;
    min-width: 100px;
    padding: 14px 8px 14px 24px;
    word-break: break-word;
    text-align: right;
    color: $g-color-secondary;
    border: 1px solid $g-color-gray;
    border-left: none;
    background-color: $g-color-white;

    & > gtd-ui-field-default-string-whitespace-or-empty > div > div {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.-comparing-analytics {
      min-width: 300px;
      background-color: $g-color-light-blue;

      &.-dark-bg {
        background-color: rgba($g-color-primary, 0.12);
      }
    }

    &:first-child {
      border-left: 1px solid $g-color-gray;
      border-top-left-radius: 8px;
    }

    &:last-child {
      border-top-right-radius: 8px;
    }
  }

  &__td {
    position: relative;
    z-index: 0;

    box-sizing: content-box;
    flex-grow: 1;
    flex-basis: 250px;
    min-width: 100px;
    padding: 9px 8px 9px 24px;

    display: flex;
    flex-direction: column;

    word-break: break-word;
    text-align: right;

    border-right: 1px solid $g-color-gray;

    &.-comparing-analytics {
      min-width: 300px;

      .-total-cell {
        .-half-column {
          padding: 8px 0;
        }

        .-left {
          padding-right: 8px;
          width: 65%;
          border-right: 1px solid $g-color-gray;
        }

        .-right {
          width: 32%;
        }
      }
    }

    &.-dark-bg {
      background-color: rgba($g-color-secondary-dark, 0.05);
    }

    &.-clear-paddings {
      padding-top: 0;
      padding-bottom: 0;
    }

    &:first-child {
      border-left: 1px solid $g-color-gray;
    }

    .key {
      opacity: 0.6;
      white-space: nowrap;
    }
  }

  &__footer {
    position: sticky;
    bottom: -24px;
    z-index: 3;

    border-radius: 8px;

    #{$root} {
      &__scroll {
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;

        &.-scrolled {
          box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, 0.15);
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;

          #{$root} {
            &__td {
              &:first-child {
                border-bottom-left-radius: 0;
              }

              &:last-child {
                border-bottom-right-radius: 0;
              }
            }
          }
        }
      }

      &__td {
        @include font-headline-4;

        background-color: $g-color-light-blue;
        border-top: 1px solid $g-color-gray;
        border-bottom: 1px solid $g-color-gray;

        &:first-child {
          border-bottom-left-radius: 8px;
        }

        &:last-child {
          border-bottom-right-radius: 8px;
        }

        &.-dark-bg {
          background-color: rgba($g-color-primary, 0.12);
        }
      }
    }
  }

  &__sticky-column {
    position: sticky;
    left: 0;
    z-index: 2;
  }

  &__tr-wrapper {
    flex-grow: 1;
  }
}
