&--chart {
  #{$root} {
    &__body {
      overflow: auto;
    }

    &__chart {
      &.-reg {
        .c-empty {
          height: 170px;
          @media (max-width: 1560px) {
            height: 162px;
          }
        }

        #{$root} {
          &__chart {
            #{$root} {
              &__empty {
                padding: 15px;
              }
            }
          }
        }

        max-height: 413px;
        padding-top: 25px;
        overflow-y: hidden;

        @media (min-width: 1560px) {
          max-height: 423px;
        }
      }
      min-width: 768px;
    }

    &__select {
      @include font-body-3;
    }
  }

  &.-pie {
    #{$root} {
      &__chart {
        min-width: initial;
      }
    }
  }
}
