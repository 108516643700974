&.c-dropdown--menu {
  background: $g-color-white;
  box-shadow: 0 10px 30px rgba(0, 14, 75, 0.15);
  border-radius: 10px;
  padding: 16px 32px;
  min-width: 210px;

  #{$root} {
    &__title {
      @include font-headline-6;
      color: rgba($g-color-secondary, 0.3);
      margin-bottom: 21px;
    }

    &__item {
      @include font-body-2;
      cursor: pointer;

      &:hover {
        color: $g-color-primary;
      }

      &.-active {
        color: $g-color-primary;
        font-weight: 700;
      }

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
}
