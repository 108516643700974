.-relative {
  position: relative;
}

.-absolute {
  position: absolute;
}

.-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
