.is--overlay {
  .c-table__filters {
    .c-button--icon.-blue {
      color: $g-color-secondary;
    }
  }
}

.c-table {
  $root: &;
  position: relative;
  z-index: $z-table;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .ui-table-limit-sting-length {
    display: block;
    max-width: 340px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__main-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
    height: calc(100vh - 54px);

    &.widget {
      height: auto;

      #{$root}__table-container {
        overflow: visible;
      }
    }
  }

  &__table-container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: hidden;
  }

  &__table-body {
    overflow: auto;
    max-height: 100%;
  }

  &__pagination {
    flex-shrink: 0;

    &.widget {
      height: auto;
    }
  }

  &__header {
    top: -$y-main-content-mobile-padding;
    padding-top: $y-main-content-mobile-padding;
    margin-top: -$y-main-content-mobile-padding;
    left: 0;
    background-color: $g-color-white;
    z-index: 31;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    @include media-breakpoint-up(sm) {
      padding-top: $y-main-content-padding;
      margin-top: -$y-main-content-padding;
      top: -$y-main-content-padding;
      position: sticky;
      position: -webkit-sticky;
    }

    #{$root} {
      &__content-right {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding-bottom: 20px;

        @include media-breakpoint-up(sm) {
          padding-bottom: 18px;
        }
      }

      &__content-left {
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        width: auto;
        min-height: 43px;

        .c-button--circle-icon.-large .h-icon-refresh {
          display: none;
        }

        @include media-breakpoint-up(sm) {
          margin-right: 20px;
          padding-bottom: 18px;

          .c-button--circle-icon.-large .h-icon-refresh {
            display: inherit;
          }
        }
      }
    }

    @include media-breakpoint-down(mx) {
      min-width: 0;
    }

    &__quick-filters {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
      gap: 10px;
      margin-bottom: 18px;
    }
  }

  &__body {
    position: relative;
    overflow-x: scroll;
    margin-left: -$x-main-content-mobile-padding;
    margin-right: -$x-main-content-mobile-padding;
    @include h-scrolled;

    &--empty {
      flex-grow: 1;

      &-item {
        padding-top: 60px;
      }

      table {
        min-width: 988px;
        height: 100%;
      }
    }

    @include media-breakpoint-up(sm) {
      margin: 0;
    }
  }

  &__empty-title {
    display: none;
  }

  &__empty {
    display: flex;
    justify-content: center;

    #{$root} {
      &__empty-item {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &__add-button {
        height: 26px;
        display: block;
      }

      &__empty-title {
        display: initial;
      }
    }

    table {
      height: 100%;
    }

    .c-button__action {
      justify-content: center;
    }
  }

  &__icon {
    display: inline-block;
    margin-bottom: 20px;
  }

  &__description {
    max-width: 226px;
    margin-bottom: 14px;
    text-align: center;
    white-space: pre-wrap;
  }

  &__title {
    @include font-headline-1;
    margin-right: 15px;

    @include media-breakpoint-down(xs) {
      display: none;
    }
  }

  &__counter {
    margin-right: 28px;

    @include media-breakpoint-down(md) {
      display: none;

      &--mobile {
        display: block;
        margin-right: 28px;

        @include media-breakpoint-down(xs) {
          display: none;
        }
      }
    }
  }

  &__reset-filters {
    margin-right: 28px;
  }

  &__settings {
    &-inner {
      right: 32px;
      z-index: 2;
      display: none !important;

      &.is-open {
        display: block !important;
      }
    }
  }

  &__filters {
    margin-right: 10px;

    @include media-breakpoint-down(lg) {
      margin-right: 0;
    }
  }

  &__search {
    display: block;
    margin-bottom: $y-main-content-mobile-padding;
    width: 100%;
    order: 1;

    @include media-breakpoint-up(sm) {
      display: none;
      margin-bottom: 0;
      order: 0;
    }

    @include media-breakpoint-up(xl) {
      width: 230px;
      margin-right: 10px;
      display: block;
    }

    &-mobile {
      .dropdown__body {
        transform: none !important;
      }

      .dropdown--is--shown .dropdown__header {
        .c-button--icon.-blue {
          color: $g-color-secondary;
        }
      }

      @include media-breakpoint-down(xs) {
        display: none;
      }
    }
  }

  table {
    white-space: nowrap;
    text-align: left;
    border-collapse: separate;
    border-spacing: 0;
    box-sizing: border-box;
    width: 100%;
    position: relative;
    transform-style: preserve-3d;

    &.-full-height {
      height: 100%;
    }

    thead {
      background-color: $g-color-white;
      z-index: 10;

      th {
        @include font-headline-4;
        color: rgba($g-color-secondary, 0.3);
        border-bottom: 1px solid $g-color-gray;
        position: relative;
        padding: 8px 10px;

        @include media-breakpoint-up(sm) {
          padding: 0 10px 8px 10px;
        }

        &:first-child {
          padding-left: 5px;

          @include media-breakpoint-up(sm) {
            padding-left: 0;
          }

          padding-right: 15px;
        }

        .th__content {
          display: flex;
        }

        .th__hint-header {
          padding-left: 4px;
          display: flex;
          align-items: center;
        }

        .th__hint-question {
          @include font-body-4;
          border-radius: 50%;
          width: 16px;
          height: 16px;
          color: $g-color-secondary;
          background-color: $g-natural-light-gray;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          user-select: none;

          @include h-general-transition(color, background-color);

          &:hover {
            background-color: $g-color-secondary;
            color: $g-natural-light-gray;
          }
        }

        .th__hint-body {
          display: none;
          position: absolute;
          top: calc(100% + 2px);
          z-index: 20;
          left: 50%;
          transform: translateX(-50%);
        }

        .th__hint {
          position: relative;
          display: flex;
          align-items: center;
        }

        .th__hint:hover {
          > .th__hint-body {
            display: flex;
          }
        }

        &.order {
          display: flex;

          &--asc {
            .th__content {
              color: $g-color-secondary;
            }
          }

          &--desc {
            .th__content {
              color: $g-color-secondary;

              &::before {
                content: '\e940';
              }
            }
          }
        }
      }
    }

    tr {
      td {
        position: relative;
        border-bottom: 1px solid rgba($g-color-gray, 0.4);
        padding: 7px 10px;

        &:first-child {
          padding-left: 15px;

          @include media-breakpoint-up(sm) {
            padding-left: 10px;
          }
        }

        &:last-child {
          border-right: none;
        }

        &.td--first {
          padding-left: 16px;
        }

        .status {
          position: absolute;
          top: 0;
          left: 0;

          width: 4px;
          height: 100%;

          background-color: rgba($color: $g-color-secondary, $alpha: 0.2);

          &.--green {
            background-color: $g-color-green;
          }

          &.--red {
            background-color: $g-color-red;
          }

          &.--orange {
            background-color: $g-color-orange;
          }

          &.--yellow {
            background-color: $g-color-yellow;
          }
        }
      }

      &:last-child {
        td {
          border-bottom: none;
        }
      }

      &:nth-child(even) {
        background-color: rgba($g-color-secondary, 0.02);
      }

      &:hover {
        .c-button--circle-icon {
          .-large {
            height: 25px;
            display: block;
          }

          .h-icon-edit {
            color: $g-color-primary;
          }

          .h-icon-remove,
          .h-icon-controls-stop {
            color: $g-color-red;
          }
        }
      }
    }
  }

  &__preloader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 30;
    display: flex;
    align-items: center;
    justify-content: center;

    .c-preloader {
      position: absolute;
      top: 50%;
      margin: 0;
    }
  }

  &__content-search {
    display: flex;
    width: 580px;
    height: 48px;
    padding: 12px 20px;
    justify-content: space-between;
    align-items: center;
    border: none;
    margin-bottom: 20px;

    border-radius: 30px;
    background: rgba(25, 48, 144, 0.06);

    @media (max-width: 861px) {
      width: 100%;
    }

    input {
      border: none;
      background-color: transparent;
      width: 95%;
    }

    p500-ui-icon {
      cursor: pointer;
    }
  }

  @import 'c-table--tool';
}

.table-icon--short {
  td:last-child {
    width: 1%;
  }
}

.p500-ui-table__cell {
  display: flex;
  align-items: center;
}
