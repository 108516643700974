&--bordered {
  &#{$root} {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    background-color: transparent;
    color: $g-color-primary;
    border-radius: 5px;
    border: 1px solid $g-color-gray;

    &:hover {
      border-color: $g-color-primary;
    }
  }
}
