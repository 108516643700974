&--d {
  margin-bottom: 14px;

  &.-no-mb {
    margin-bottom: 0;
  }

  #{$root} {
    &__inner {
      display: flex;
      flex-flow: column wrap;
      &.-row {
        flex-flow: initial;
      }
    }

    &__subtitle {
      margin-top: 10px;
      color: rgba($g-color-secondary, 0.5);

      &.-big-mb {
        margin-bottom: 32px;
      }

      b {
        color: $g-color-secondary;
      }
    }

    &__label {
      display: block;
      color: $g-color-secondary;
      opacity: 0.7;
      @include font-body-4;
      margin-bottom: 5px;
      order: -1;
      @include h-general-transition(opacity);

      &--big {
        @include font-headline-5;
        display: flex;
        align-items: center;

        #{$root} {
          &__add-icon {
            margin-left: 8px;
          }
        }
      }
    }

    &__label-inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: rgba($g-color-secondary, 0.7);
      @include font-body-4;

      &.-payout {
        .payout {
          color: $g-color-primary;
        }
      }
    }

    &__input,
    &__select,
    &__textarea {
      width: 100%;
      color: $g-color-secondary;
      border-radius: 5px;
      border: solid 1px $g-color-gray;
      background-color: $g-color-white;
      padding: 4px 12px;
      @include h-general-transition(box-shadow, border-color);

      &.-auto-width {
        min-width: auto;
      }

      &::placeholder {
        color: rgba($g-color-secondary, 0.3);
      }

      &:focus,
      &:active {
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.07);
        border-color: rgba($g-color-secondary, 0.2);

        & + #{$root}__label {
          opacity: 1;
        }
      }

      &:hover {
        border-color: rgba($g-color-secondary, 0.2);

        & + #{$root} {
          &__label,
          &__actions {
            opacity: 1;
          }
        }
      }
    }

    &__input {
      &--big {
        padding: 7px 14px;
        background-color: $g-color-bg;

        &::placeholder {
          color: rgba($g-color-secondary, 0.3);
        }

        &:hover,
        &:focus,
        &:active {
          background-color: $g-color-white;
        }
      }
    }

    &__textarea {
      min-height: 107px;

      &--pql {
        padding-left: 60px;
        min-height: auto;
        background-color: #f7f7f7;
      }
    }

    &__checkbox {
      width: auto;
      display: none;

      & + #{$root} {
        &__label-inner {
          color: $g-color-secondary;
        }

        &__label {
          margin: 0;
          opacity: 1;
          display: flex;
          align-items: center;
          color: $g-color-secondary;

          &:before {
            width: 18px;
            height: 18px;
            border-radius: 5px;
            border: 1px solid $g-color-gray;
            background-color: $g-color-white;
            font-size: 10px;
            line-height: 1em;
            color: $g-color-white;
            content: '\eb52';
            font-family: 'platform500';
            display: flex;
            flex: none;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            @include h-general-transition(border-color, background-color, color);
          }

          &:after {
            display: none;
          }

          &:hover {
            &:before {
              border-color: $g-color-primary;
            }
          }
        }
      }

      &:checked + #{$root} {
        &__label {
          &:before {
            border-color: $g-color-primary;
            background-color: $g-color-primary;
          }
        }
      }

      &--permission {
        & + #{$root} {
          &__label {
            &:before {
              font-size: 10px;
              content: '\eb5f';
              font-family: 'platform500';
              background-color: $g-color-white;
              border-color: $g-color-primary;
              color: $g-color-primary;
            }
          }
        }
      }
    }

    &__radio {
      width: auto;
      display: none;

      & + #{$root} {
        &__label {
          margin: 0;
          opacity: 1;
          display: flex;
          align-items: center;

          &:before {
            width: 18px;
            height: 18px;
            border-radius: 50%;
            border: 1px solid $g-color-gray;
            background-color: $g-color-white;
            font-size: 8px;
            line-height: 1em;
            content: '';
            display: flex;
            flex: none;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            box-shadow: inset 0 0 0 3px $g-color-white;
            @include h-general-transition(border-color, background-color, box-shadow);
          }

          &:after {
            display: none;
          }

          &:hover {
            &:before {
              border-color: $g-color-primary;
            }
          }
        }
      }

      &:checked + #{$root} {
        &__label {
          &:before {
            box-shadow: inset 0 0 0 3px $g-color-white;
            border-color: $g-color-primary;
            background-color: $g-color-primary;
          }
        }
      }
    }

    &__tooltip {
      width: 16px;
      height: 16px;
      margin-left: 6px;
      background-color: $g-color-light-gray;
      color: $g-color-secondary;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 11px;
    }

    &__img--pql {
      position: absolute;
      top: 30px;
      left: 10px;
    }
  }

  &.is-required {
    #{$root} {
      &__label {
        &:after {
          color: $g-color-red;
          margin-left: 2px;
          content: '*';
        }
      }
    }
    #{$root} {
      &__tooltip {
        margin-left: 14px;
        position: absolute;
      }
    }
  }

  &.is-disabled {
    #{$root} {
      &__inner {
        pointer-events: none;
        opacity: 0.3;
      }
    }
  }

  &.is-invalid {
    #{$root} {
      &__label {
        opacity: 1;
      }

      &__input,
      &__select,
      &__textarea {
        border-color: $g-color-red;
      }

      &__input {
        &--big {
          background-color: $g-color-white;
        }
      }
    }
  }

  #{$root} {
    &__input,
    &__select,
    &__textarea {
      &.ng-invalid.ng-touched {
        border-color: $g-color-red;
      }
    }
  }

  &.-min-width {
    min-width: 150px;

    &-220 {
      min-width: 220px;
    }
  }

  .json-textarea {
    width: 100%;
    border: none;
  }

  .-truncated {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.-switch-mode {
    margin-bottom: 0;
    width: 100%;

    #{$root} {
      &__inner {
        width: 100%;
      }

      &__checkbox {
        & + #{$root} {
          &__label {
            width: 100%;
            justify-content: space-between;
            flex-direction: row-reverse;

            &:before {
              margin-right: 0;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
