&--with-dropdown {
  $root: &;
  // todo: use font-headline-4
  //@include font-headline-4;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;

  &__text,
  &__dropdown {
    //@include button-reset;
    height: 32px;
    padding: 6px 8px 6px 8px;
  }

  &__dropdown {
    border-left: 1px solid #4686ff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 0 4px 4px 0;
  }

  &__text {
    display: flex;
    gap: 8px;
    border-radius: 4px;
    padding-left: 12px;
    color: $g-color-white;

    p500-ui-icon {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 16px;
    }

    &.-with-dropdown {
      border-radius: 4px 0 0 4px;
    }
  }

  &--primary {
    background-color: $g-color-primary;

    #{$root} {
      &__text {
        color: $g-color-white;
      }

      &__dropdown .p500-ui-icon {
        font-weight: 700;
        color: $g-color-white;
      }

      &__text,
      &__dropdown {
        &:focus,
        &:hover {
          background-color: $g-color-primary-hover;
        }
      }
    }
  }

  &--warn {
    background-color: $g-color-red;

    #{$root} {
      &__text {
        color: $g-color-white;
      }

      &__icon {
        background: rgba($g-color-white, 0.1);
        color: $g-color-white;
      }

      &__dropdown {
        border-left: 1px solid #d90000;

        .p500-ui-icon {
          font-weight: 700;
          color: $g-color-white;
        }
      }
    }

    &__text,
    &__dropdown {
      &:focus,
      &:hover {
        background-color: $g-color-red-hover;
      }
    }
  }

  &--white {
    background-color: $g-color-white;

    #{$root} {
      &__text {
        color: $g-color-secondary;
      }

      &__dropdown {
        border-left: 1px solid #ffffff;
      }

      &__icon {
        background-color: transparent;
        color: $g-color-secondary;
      }
    }
  }

  &--secondary {
    background-color: $g-color-secondary;

    #{$root} {
      &__text {
        color: $g-color-white;
      }

      &__dropdown {
        border-left: 1px solid #283673;

        .p500-ui-icon {
          font-weight: 700;
          color: $g-color-white;
        }
      }

      &__icon {
        background-color: transparent;
        color: $g-color-white;
      }
    }
  }

  &--green {
    background-color: $g-color-green;

    #{$root} {
      &__text {
        color: $g-color-white;
      }

      &__dropdown {
        border-left: 1px solid #029700;

        .p500-ui-icon {
          font-weight: 700;
          color: $g-color-white;
        }
      }

      &__icon {
        background-color: rgba($g-color-white, 0.1);
        color: $g-color-white;
      }
    }

    &__text,
    &__dropdown {
      &:focus,
      &:hover {
        background-color: $g-color-green-hover;
      }
    }
  }

  &--big {
    padding-left: 8px;
    padding-right: 8px;
    height: 32px;
    width: 100%;
    border-radius: 4px;
    text-align: center;

    #{$root} {
      &__text {
        width: 100%;
        text-align: center;
        // todo: use font-body-2
        //@include font-body-2;
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
      }
    }
  }

  &--small {
    padding-left: 14px;
    padding-right: 14px;
    height: 26px;
    border-radius: 16px;
    text-align: center;

    #{$root} {
      &__text {
        width: 100%;
        text-align: center;
        // todo: use font-headline-6
        //@include font-headline-6;
        font-size: 10px;
        line-height: 16px;
        font-weight: 700;
      }
    }
  }

  &--disabled {
    opacity: 0.1;
    cursor: default;
    pointer-events: none;
  }

  .p500-ui-dropdown {
    & > .p500-ui-dropdown__header {
      display: flex;
    }

    &__body {
      .p500-ui-dropdown__body-inner {
        box-shadow: 0 2px 15px 0 rgba(0, 9, 50, 0.15);
        border-radius: 4px;
        padding: 0;

        .p500-ui-dropdown__item {
          padding: 8px 12px;

          &:hover {
            color: $g-color-primary;
            background-color: #f1f3fe;
          }

          &.-green-background {
            color: #009a84;
            background-color: #ecf5ea;

            .p500-ui-dropdown__item-text,
            .p500-ui-dropdown__item-text:hover {
              justify-content: center;
            }
          }
        }

        .p500-ui-dropdown__item:after {
          display: none;
        }

        .p500-ui-dropdown__item-text,
        .p500-ui-dropdown__item-text:hover {
          // todo: use font-body-3
          //@include font-body-3;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          display: flex;
          justify-content: flex-start;
          gap: 8px;
          padding: 0;
          transition-property: none;
          color: inherit;
          background-color: inherit;

          p500-ui-icon {
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 14px;
          }
        }
      }
    }
  }
}
