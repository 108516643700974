@import 'global/global';
@import 'mixins/mixins';
@import 'atomic/atomic';
@import 'vendor/vendor';
@import 'layouts/layouts';
@import 'components/components';
@import 'pages/pages';

.-global-loading {
  position: relative;

  &::before {
    content: '';
    @include skeleton(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5));
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 10px;
    z-index: 1024;
  }
}

#front-chat-iframe {
  @media (max-width: 480px) {
    bottom: 45px !important;
  }
}
