&.--chart {
  #{$root} {
    &__button {
      @include font-body-3;
      .p500-ui-button__text {
        display: flex;
      }
    }
  }

  .p500-ui-button {
    @include font-body-4;
    width: 100%;
    color: $g-color-dark-blue;
    border-radius: 5px;
    border: solid 1px $g-color-gray;
    background-color: $g-color-white;
    padding: 4px 12px;
    transition-property: box-shadow, border-color;
    transition-duration: 0.15s;
    transition-timing-function: ease;

    .c-dropdown__button-text {
      opacity: 0.3;
      transition-property: box-shadow, border-color, opacity;
      transition-duration: 0.15s;
      transition-timing-function: ease;
    }

    .p500-ui-button__text {
      opacity: 1;
      @include font-body-3;
      .p500-ui-button-icon--primary {
        color: $g-color-secondary;
      }
    }

    &:hover,
    &:active {
      color: $g-color-secondary;
      border-color: $g-color-secondary;

      .c-dropdown__button-text {
        opacity: 1;
      }
    }

    &.-active {
      background-color: $g-color-primary;
      border-color: $g-color-primary;

      .c-dropdown__button-text {
        opacity: 1;
      }

      .p500-ui-button-icon--primary,
      .p500-ui-button__text {
        color: $g-color-white;
      }
    }
  }

  .p500-ui-button {
    height: unset;
  }

  &.-new-dropdown-buttons {
    .main-button-text .c-dropdown__button-text {
      @include font-headline-4;
    }

    .p500-ui-dropdown {
      & > .p500-ui-dropdown__header {
        display: flex;
      }

      &__body {
        .p500-ui-dropdown__body-inner {
          box-shadow: 0 2px 15px 0 rgba(0, 9, 50, 0.15);
          border-radius: 4px;
          padding: 0;

          .p500-ui-dropdown__item {
            padding: 8px 12px;

            &:hover {
              color: $g-color-primary;
              background-color: #f1f3fe;
            }

            &.-green-background {
              color: #009a84;
              background-color: #ecf5ea;

              .p500-ui-dropdown__item-text,
              .p500-ui-dropdown__item-text:hover {
                justify-content: center;
              }
            }
          }

          .p500-ui-dropdown__item:after {
            display: none;
          }

          .p500-ui-dropdown__item-text,
          .p500-ui-dropdown__item-text:hover {
            @include font-body-3;
            display: flex;
            justify-content: flex-start;
            gap: 8px;
            padding: 0;
            transition-property: none;
            color: inherit;
            background-color: inherit;
            min-width: 0;

            p500-ui-icon {
              display: flex;
              flex-direction: column;
              justify-content: center;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
