&--new-statistic {
  position: relative;
  $primary-black: #000932;

  &.-new {
    display: flex;
  }

  &.old {
    opacity: 0.5;
  }

  .settings__button {
    @include h-general-transition;
    opacity: 0;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 3px;
    border-radius: 6px;
    background-color: $g-color-white;
    color: $primary-black;
    z-index: 1;

    &:hover {
      background-color: $primary-black;
      color: $g-color-white;
    }
  }

  .settings__button-new {
    background-color: $g-color-white;
    color: $primary-black;
    padding: 8px;
    border-radius: 6px;

    &:hover {
      background-color: $g-color-white;
      color: $primary-black;
    }
  }

  &:hover > .settings__button {
    opacity: 1;
  }

  .card-wrapper {
    color: $primary-black;
    display: flex;
    gap: 23px;
    overflow-y: auto;

    &.-new {
      gap: 16px;
    }

    &.-column {
      flex-direction: column;
      width: 240px;
    }

    .card-new {
      display: flex;
      width: 156px;
      padding: var(--Space-400, 12px);
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Space-200, 8px);
      border-radius: var(--Radius-large, 8px);
      border: 1px solid var(--Border-tertiary, #dedfe4);
      background: var(--Text-primary-invert, $g-color-white);
      margin-bottom: 8px;

      &.-primary {
        border: 1px solid var(--Bg-alt-hover, #b5cdfd);
        background: var(--Tag-Bg-primary, #e7effe);
      }

      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        ng-component {
          display: none;
        }

        &-tooltip {
          display: flex;
          margin-top: 2px;
          cursor: pointer;
        }
      }

      &__name {
        max-width: 110px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &__stats {
        display: flex;
        width: 132px;
        flex-direction: column;
        align-items: flex-end;

        &-changes {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 2px;
          align-self: stretch;
        }

        &-value {
          @include font-headline-2;
          display: flex;
          align-items: flex-end;
          gap: 10px;
        }

        &-percent {
          @include font-headline-6;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: var(--Space-50, 4px);

          &.green {
            color: var(--link-success, #3b892c);
          }

          &.red {
            color: var(--link-critical, #d33e33);
          }
        }

        &-old-value {
          @include font-body-5;
          color: var(--Text-tertiary, $g-color-gray-text);
        }

        &-arrow {
          color: #85899d;
          margin-top: 1px;
        }
      }
    }

    .card {
      white-space: nowrap;
      border-radius: 10px;
      padding: 16px 20px;
      background: rgba($primary-black, 0.03);
      flex: 1;

      &.-less-paddings {
        padding: 10px 20px;
      }

      &.-earning-total {
        @include font-body-4;
        padding: 18px 20px;
        display: flex;
        flex: 1 0 100px;
        flex-direction: column;
        justify-content: space-between;

        .header {
          @media (max-width: 1440px) {
            min-width: 0;
            text-wrap: unset;
            white-space: normal;
          }
        }

        .main {
          @include font-headline-1;
          display: flex;

          .c-field-value__text {
            &.-red {
              color: $g-color-black;
            }
          }

          .icon-block {
            border-radius: 5px;
            padding: 5px;
            height: 23px;
            width: 23px;
            margin: 0 0 0 5px;

            @media (max-width: 1440px) {
              margin-left: 0;
            }

            .icon {
              width: 13px;
              height: 13px;
              background-repeat: no-repeat;
              background-size: contain;
            }

            &.-positive {
              color: $g-color-turquoise;
              background-color: rgba($g-color-turquoise, 0.14);

              .icon {
                background-image: url(../../../assets/new-img/icons/statistics/up.svg);
              }
            }

            &.-negative {
              color: $g-color-black;
              background-color: rgba($g-color-black-icon-background, 0.06);

              .icon {
                background-image: url(../../../assets/new-img/icons/statistics/down-blue.svg);
              }
            }
          }

          @media (max-width: 1440px) {
            flex-direction: column-reverse;

            gtd-ui-field-default-money {
              @include font-headline-4;
              margin-top: 10px;
            }
          }
        }
      }

      &.primary {
        background-image: url('../../../assets/new-img/dashboard/statistic/secondary-bg.svg');
        background-repeat: no-repeat;
        background-position: right bottom;
        background-color: $primary-black;

        .header__left,
        .main,
        .footer .value {
          color: $g-color-white;
        }

        .footer .key {
          color: rgba($g-color-white, 0.55);
        }

        .header__left {
          .icon-help {
            background-image: url('../../../assets/new-img/dashboard/statistic/help-secondary.svg');
          }
        }
      }

      .header {
        @include font-headline-5;
        min-width: 150px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        white-space: nowrap;
        gap: 10px;

        &.-bottom-border {
          border-bottom: 1px solid $g-color-gray;
          padding-bottom: 4px;
          margin-bottom: 0;
        }

        &__left {
          @include font-headline-5;
          display: flex;
          align-items: center;

          .icon-help {
            display: inline-block;
            width: 16px;
            height: 16px;
            background-image: url('../../../assets/new-img/dashboard/statistic/help-primary.svg');
            background-repeat: no-repeat;
            background-size: contain;
          }
        }

        &__right {
          @include font-headline-5;
          padding-left: 15px;
          background-repeat: no-repeat;
          background-image: url('../../../assets/new-img/dashboard/statistic/up.svg');
          color: #2db1aa;

          &.down {
            background-image: url('../../../assets/new-img/dashboard/statistic/down-red.svg');
            color: #ef5736;
          }
        }
      }

      .main {
        @include font-headline-1;
        margin-bottom: 33px;

        &.-small {
          @include font-body-4;

          .row {
            display: flex;
            flex-direction: row;
          }
        }
      }

      .footer {
        &__row {
          display: flex;
          justify-content: space-between;
          gap: 20px;

          &:not(:last-child) {
            margin-bottom: 4px;
          }

          .key,
          .value {
            @include font-body-4;
          }

          .key {
            color: rgba($primary-black, 0.55);
          }

          .value {
            @include font-headline-5;
          }
        }
      }
    }
  }
}
