&--lead-profile {
  table {
    background-color: rgba($g-color-secondary, 0.02);
    text-align: left;
    border-collapse: separate;
    border-spacing: 0;
    box-sizing: border-box;
    width: 100%;
    position: relative;

    td {
      padding: 0;
    }

    .empty {
      color: rgba($g-color-secondary, 0.1);
    }

    tbody {
      tr {
        td {
          @include font-body-3;

          padding: 0 20px;
          background-color: transparent;
          border-right: 1px solid $g-color-gray;
          border-top: 1px solid rgba($g-color-gray, 0.4);

          &:last-child {
            padding-right: 0;
          }

          .content-wrapper {
            padding: 8px 0;
            border-bottom: none;
          }

          &:nth-last-of-type(3) {
            padding: 0 20px;
            text-align: left;
            vertical-align: top;
          }

          &:first-of-type {
            width: 160px;
          }

          &:last-of-type {
            padding-left: 0;
            background-color: $g-color-white;
            border-right: none;
            border-top: none;

            .content-wrapper {
              padding-left: 20px;
              border-top: 1px solid rgba($g-color-gray, 0.4);
            }
          }

          &[rowspan] {
            font-weight: bold;
          }

          &.--empty {
            border-radius: 10px;
          }
        }

        &:first-of-type {
          td {
            &:first-of-type {
              border-top: none;
            }

            &:last-of-type {
              border-top-right-radius: 10px;

              .content-wrapper {
                border-top: none;
                white-space: normal;
              }
            }
          }
        }

        &:last-of-type {
          td:last-of-type {
            border-bottom-right-radius: 10px;
          }
        }
      }
    }
  }
}
