.c-page-dashboard-header {
  $root: &;

  .p500-ui-form-field.p500-ui-form-field--range-datepicker {
    gap: 15px;

    & .p500-ui-form-field__daterange.-first {
      @media (min-width: 481px) {
        margin-right: 3px;
      }
    }
  }

  @include h-general-transition;
  position: sticky;
  top: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
  background-color: $g-color-white;
  z-index: 1001;
  transform: translateY(-23px);
  padding: 25px 15px 0;
  margin: 0 -15px;

  .title {
    display: none;
    @include font-headline-1;
    margin-right: auto;
  }

  &.-scroll {
    box-shadow: 0 2px 6px rgba($g-color-primary, 0.07);
    padding-top: 14px;
    padding-bottom: 14px;

    .title {
      @include font-headline-3;
    }
  }

  @include media-breakpoint-up(lg) {
    & {
      margin: 0 -32px;
      padding-left: 32px;
      padding-right: 32px;
    }

    .title {
      display: block;
    }
  }

  .settings {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 15px;

    .refresh {
      button {
        @include h-general-transition(background-color, color);
        border-radius: 8px;
        background-color: rgba($g-color-primary, 0.1);
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          color: $g-color-white;
          background-color: $g-color-primary;
        }
      }
    }

    .update {
      display: flex;
      align-items: center;
      gap: 8px;
      white-space: nowrap;

      .disabled {
        color: rgba($g-color-secondary, 0.4);
      }

      span {
        border-radius: $g-value-border-radius-xl;
        background-color: rgba(0, 14, 75, 0.03);
        padding: 4px 10px;
        display: flex;

        time {
          min-width: 17px;
          font-weight: 700;
        }
      }

      .p500-ui-form-field.p500-ui-form-field--slide-toggle .p500-ui-form-field__button.-active {
        background-color: #2db1aa;
        border-color: #2db1aa;
      }
    }
  }
}
