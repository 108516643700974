&--permissions {
  #{$root} {
    &__header {
      min-height: 0;
      padding-left: 24px;
      margin-bottom: -15px;
      z-index: 3;
      position: relative;
    }

    &__permission {
      width: 100%;
      margin-bottom: 16px;

      &:last-child,
      &.dropdown--is--shown {
        margin-bottom: 0;
      }
    }

    &__subgroup {
      padding-left: 8px;
      padding-top: 6px;
      padding-bottom: 6px;
      border-left: 1px solid $g-color-gray;
      margin-left: 18px;
      margin-bottom: 14px;

      .col-12 {
        &:last-child {
          .c-form-field--d {
            margin-bottom: 0;
          }
        }
      }
    }

    &__title {
      @include font-body-3;
      margin-right: 0;
      position: relative;

      &:before {
        width: calc(100% + 18px);
        height: 100%;
        background-color: $g-color-white;
        left: -9px;
        top: 0;
        position: absolute;
        content: '';
        display: block;
        z-index: -1;
      }
    }

    &__body {
      padding: 22px 12px;
    }
  }

  .columns-row {
    margin-left: 0 -28px;
  }

  .dropdown__body {
    position: initial !important;
  }

  .-row {
    margin-bottom: 8px;
  }

  .dropdown--is--shown {
    #{$root} {
      &__arrow {
        &:after {
          content: '\e936';
        }
      }
    }
  }

  .column {
    padding: 0 28px;
  }

  @include media-breakpoint-up(lg) {
    .column:not(:nth-child(3n)) {
      &:after {
        display: block;
        height: calc(100% - 10px);
        width: 1px;
        background-color: rgba($g-color-gray, 0.4);
        content: '';
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
}
