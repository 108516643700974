&--filters {
  &#{$root} {
    @include h-general-transition(background-color);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    background-color: rgba($g-color-secondary, 0.03);
    color: $g-color-primary;
    border-radius: 8px;

    &:hover {
      color: $g-color-white;
      background-color: $g-color-primary;
    }
  }
}
