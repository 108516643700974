&--img-uploader {
  #{$root} {
    &__label-inner {
      display: flex;
      justify-content: space-between;
    }

    &__file-input {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      cursor: pointer;
      opacity: 0;

      &:focus {
        outline: none;
      }
    }

    &__placeholder {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: rgba($g-color-primary, 0.04);
      padding: 41px 10px;
      border: 1px dashed $g-color-gray;
      border-radius: 5px;
      @include font-body-4;
      @include h-general-transition();

      &:hover {
        border-color: $g-color-secondary;
      }
    }

    &__uploaded {
      background-color: #f7f8fa;
      padding: 12px;
      border-radius: 5px;
      display: flex;
    }

    &__preview {
      flex-basis: 50%;
      margin-right: 10px;
      border-radius: 5px;
      border: 1px solid $g-color-gray;
      background-color: $g-color-white;
      overflow: hidden;
      max-height: 120px;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }

    &__data {
      flex-basis: 50%;
      margin-left: 10px;

      &-row {
        margin-bottom: 10px;
        @include font-body-4;
      }

      &-key {
        color: rgba($g-color-secondary, 0.5);
        margin-right: 8px;
      }
    }
  }
}
