.c-language-menu {
  span[class*='icon-language-'] {
    width: 20px;
    height: 20px;
    line-height: 100%;

    &:before {
      content: '';
      background-size: 20px;
      width: 20px;
      height: 20px;
      display: inline-block;
    }
  }
  .icon-language-en:before {
    background-image: url('../../../assets/new-img/flags/US.png');
  }
  .icon-language-pt:before {
    background-image: url('../../../assets/new-img/flags/BR.png');
  }
}
