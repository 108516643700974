.c-menu-list {
  $root: &;

  cursor: default;
  position: absolute;
  right: 0;
  left: 0;
  padding: 20px 15px;

  @include media-breakpoint-up(sm) {
    left: -190px;
  }

  li {
    list-style-type: none;
  }

  &__control {
    justify-content: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    background: $g-color-white;
    border-radius: $g-value-border-radius-xl;
    opacity: 0.5;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    &:hover {
      opacity: 1;
    }

    &-subtitle {
      @include font-headline-4;
      position: absolute;
      top: 83%;
      left: 10px;
      color: $g-color-white;
      margin: 0;
      background: $g-color-primary;
      border-radius: 3px;
      width: 21px;
      height: 16px;
      padding: 1px 0 0;
      text-align: center;
    }
  }

  &.-floating {
    @include media-breakpoint-up(lg) {
      bottom: 0;
      left: 0;
    }

    @include media-breakpoint-down(mx) {
      top: -4px;
      right: 0;
    }
  }

  &__content {
    padding: 0;
    margin-bottom: 0;
  }

  &__title {
    @include font-headline-4;
    color: rgba($g-color-secondary, 0.3);
    margin-bottom: 4px;
  }

  &__item {
    @include font-body-3;
    display: flex;
    align-items: center;
    padding: 15px 0;
    color: $g-color-secondary;
    cursor: pointer;
    white-space: nowrap;

    a {
      color: inherit;
      text-decoration: inherit;

      .h-icon {
        vertical-align: middle;
      }
    }

    a,
    span {
      &:hover {
        color: $g-color-primary;
      }
    }

    &.-red {
      color: $g-color-red;

      .h-icon {
        color: $g-color-red;
      }

      &:hover {
        color: $g-color-red-hover;

        .h-icon {
          color: $g-color-red-hover;
        }
      }
    }

    .h-icon {
      display: inline-block;
      padding-bottom: 2px;
      font-size: 20px;
      margin-right: 16px;
      color: $g-color-primary;
    }
  }

  &__label {
    @include font-body-3;
    color: $g-color-secondary;
    cursor: pointer;
    white-space: nowrap;
  }

  &.-box {
    background-color: $g-color-white;
    border-radius: 20px;
    box-shadow: 10px 30px 60px rgba(0, 14, 75, 0.15);
    padding: 20px 30px 10px 20px;
    box-sizing: border-box;
    min-width: 200px;
    width: fit-content;
    z-index: 1;

    #{$root} {
      &__item {
        padding: 10px 0;

        &:not(:last-child) {
          border-bottom: none;
        }

        .h-icon {
          height: 20px;
          font-size: 20px;
          color: inherit;
        }
      }

      &__title {
        margin-bottom: 10px;
      }
    }
  }

  &.-profile-menu {
    position: static;
    min-width: 220px;
    padding-bottom: 25px;

    @include media-breakpoint-up(sm) {
      position: absolute;
      left: -210px;
      margin-top: 0;
    }

    @include media-breakpoint-up(lg) {
      left: 0;
    }
  }

  &.-tickers {
    position: static;
    padding: 4px 0;
    min-width: auto;
    box-shadow: 0 10px 20px rgba($g-color-secondary, 0.05);
    border-radius: 8px;
    cursor: default;

    #{$root} {
      &__item {
        padding: 10px 14px;
        cursor: default;

        &:not(:last-child) {
          border-bottom: 1px solid rgba($g-color-gray, 0.5);
        }
      }

      &__value {
        display: inline-block;
        color: $g-color-secondary;
        margin-right: 4px;

        &--primary {
          color: rgba($color: $g-color-secondary, $alpha: 0.3);

          @include media-breakpoint-up(sm) {
            color: $g-color-primary;
          }
        }
      }

      &__key {
        display: inline-block;
        color: rgba($g-color-secondary, 0.5);
      }
    }

    &.-floating {
      margin: 0;
      box-shadow: 10px 30px 60px rgba($g-color-secondary, 0.15);
    }
  }

  &.-switch {
    min-width: 150px;
    padding: 15px 20px 10px;

    @include media-breakpoint-up(sm) {
      position: absolute;
      top: -4px;
      right: 0;
      transform: translate(0, 0);
    }

    @include media-breakpoint-up(lg) {
      top: auto;
      right: auto;
      bottom: 0;
      left: 0;
    }

    #{$root} {
      &__item {
        padding: 5px 10px;
        margin-left: -10px;
        margin-right: -10px;
        border-radius: $g-value-border-radius-xl;
        @include h-general-transition();
        position: relative;

        &:not(:last-child) {
          margin-bottom: 4px;

          &:after {
            content: '';
            position: absolute;
            display: block;
            bottom: -2px;
            left: 0;
            width: 100%;
            height: 1px;
            border-radius: 2px;
            background-color: rgba($g-color-gray, 0.4);
          }
        }

        &:hover {
          color: $g-color-primary;
          background: rgba($g-color-primary, 0.07);
        }
      }
    }
  }

  @import './c-menu-list--notifications';
}
