&--split-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;

  #{$root} {
    &__line {
      width: 1px;
      height: auto;
      flex-grow: 1;
      background-color: $g-color-primary;
      margin-bottom: 0;

      &.-black {
        background-color: $g-color-black;
      }
    }

    &__label {
      @include font-body-4;
      max-width: 48px;
      text-align: center;
      margin-top: 4px;
      margin-bottom: 4px;
      color: $g-color-primary;

      &.-black {
        color: $g-color-black;
      }
    }
  }
}
