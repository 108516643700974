&--typed-new {
  display: flex;
  align-items: baseline;
  gap: 6px;

  #{$root} {
    &__type {
      @include font-headline-5;
      padding: 0 4px;
      color: $g-color-secondary;
      border-radius: 2px;
    }

    &__name {
      @include font-headline-5;
      color: $g-color-secondary;
    }
  }
  &_bordered {
    background-color: #000e4b1a;
    padding: 5px;
    border-radius: 5px;
  }
}
