&--info {
  max-width: 100%;
  gap: 28px;

  &.-profile-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
  }

  &--blue {
    background-color: rgba($g-color-light-blue, 0.8);
    padding: 0 18px 18px;

    ul {
      li {
        margin-bottom: 8px;
      }
    }
  }

  &--white {
    margin-top: 10px;
    ul {
      li {
        margin-bottom: 8px;
      }
    }
  }

  &--footer {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #e6e6e6;

    .key,
    .value {
      margin-right: 8px;
      margin-top: 12px;
    }
  }

  & p {
    display: flex;
    flex-wrap: wrap;
    word-break: break-word;
  }

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .title {
    @include font-headline-2;
    margin-bottom: 10px;
  }

  ul {
    width: 100%;
    min-width: 100%;
    list-style: none;
    padding: 18px;
    border: 1px solid $g-color-gray;
    border-radius: $g-value-border-radius-xl;
    flex: 1;

    @include media-breakpoint-up(sm) {
      min-width: initial;
      min-width: 220px;
    }

    &:first-child {
      margin-left: 0;
    }

    li {
      &:last-child {
        margin-bottom: 0;
      }
    }
    li.image {
      display: flex;
      align-items: center;

      img {
        max-width: 100%;
        width: 100%;
      }

      .small-preview {
        @include h-general-transition();
        display: inline-block;
        width: 25px;
        height: 25px;
        cursor: pointer;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        img {
          max-width: 100%;
          width: 100%;
          border-radius: 5px;
        }
      }

      .big-preview {
        display: none;
      }

      .small-preview:hover {
        &::before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background-color: rgba($g-color-primary, 0.3);
        }
        & + .big-preview {
          display: block;
          position: absolute;
          transform: translate(60%, 0%);
          max-width: 200px;
          width: 200px;
          z-index: 10;
          box-shadow: 0 0 15px 0 rgb(0 0 0 / 15%);
          padding: 5px;
          border-radius: 20px;
          background-color: #fff;
          img {
            border-radius: 20px;
          }
        }
      }
    }
  }

  .key {
    color: rgba($g-color-secondary, 0.5);
    margin-right: 8px;
    white-space: nowrap;
  }

  .value {
    display: block;
    margin-right: 10px;

    &__link {
      margin-left: 5px;
    }

    @include media-breakpoint-up(sm) {
      display: inline-block;
    }
  }

  &.-border-top {
    padding-top: 12px;
    border-top: 1px solid #e6e6e6;
  }

  &.-border-bottom-radius {
    border-bottom-left-radius: $g-value-border-radius-xl;
    border-bottom-right-radius: $g-value-border-radius-xl;
  }
}
