$steps: 30;

@mixin mt-x-list {
  @for $i from 1 through $steps {
    .mt-#{$i} {
      margin-top: $i + px !important;
    }
  }
}

@mixin mr-x-list {
  @for $i from 1 through $steps {
    .mr-#{$i} {
      margin-right: $i + px !important;
    }
  }
}

@mixin mb-x-list {
  @for $i from 1 through $steps {
    .mb-#{$i} {
      margin-bottom: $i + px !important;
    }
  }
}

@mixin ml-x-list {
  @for $i from 1 through $steps {
    .ml-#{$i} {
      margin-left: $i + px !important;
    }
  }
}

@mixin -mb-x-list {
  @for $i from 1 through $steps {
    .-mb-#{$i} {
      margin-bottom: -$i + px !important;
    }
  }
}

@mixin -mr-x-list {
  @for $i from 1 through $steps {
    .-mr-#{$i} {
      margin-right: -$i + px !important;
    }
  }
}

@include mt-x-list;
@include mr-x-list;
@include mb-x-list;
@include ml-x-list;
@include -mr-x-list;
@include -mb-x-list;
