.c-settings {
  @mixin list-item {
    padding: 6px;
    border-radius: $g-value-border-radius-xl;
    border-width: 1px;
    border-style: dashed;
  }

  $root: &;
  position: fixed;
  display: block;
  background-color: $g-color-white;
  z-index: $z-settings;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @include media-breakpoint-up(sm) {
    left: auto;
    width: 360px;
    border-radius: $g-value-border-radius-xl;
    border: 10px solid $g-color-white;
    right: 26px;
    top: 170px;
    height: calc(100% - 170px);
  }

  @media (min-width: 650px) {
    top: 128px;
    height: calc(100% - 128px);
  }

  @include media-breakpoint-up(lg) {
    height: calc(100% - 64px);
    top: 64px;
  }

  &__body {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;

    &.-no-overflow {
      overflow-x: initial;
      overflow-y: initial;
    }

    @include media-breakpoint-up(sm) {
      border-bottom: none;
    }
  }

  &__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 14px;
    background-color: $g-natural-light-gray;

    @include media-breakpoint-up(sm) {
      padding: 20px 0;
      border-radius: $g-value-border-radius-xl;
    }
  }

  &__select {
    margin: 0 15px 20px 15px;

    @include media-breakpoint-up(sm) {
      margin: 0 20px 14px 20px;
    }
  }

  &__items {
    margin: 0 15px;

    @include media-breakpoint-up(sm) {
      margin: 0 20px;
    }
  }

  &__items-label {
    @include font-body-4;
    opacity: 0.3;
    margin-bottom: 6px;
  }

  &__item {
    @include list-item;
    border-color: transparent;
    transition: background-color $g-value-animation-duration;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover:not(.c-settings__item-all) {
      background-color: $g-color-white;
    }

    &.cdk-drag-preview {
      background-color: $g-color-white;
    }
  }

  &__item-all {
    @include font-headline-4;
  }

  &__item-buttons {
    display: none;

    @include media-breakpoint-down(md) {
      display: flex;
    }
  }

  &__arrow {
    @include media-breakpoint-down(md) {
      display: flex;
      width: 30px;
      height: 30px;
      background: rgba($g-color-dark-blue, 0.05);
      color: rgba($g-color-dark-blue, 0.5);
      border-radius: 8px;
      justify-content: center;
      align-items: center;
      margin-right: 14px;
    }
  }

  &__title {
    @include font-headline-3;
    padding: 0 15px;
    margin-bottom: 20px;
    border-bottom: none;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media-breakpoint-up(sm) {
      padding: 0 20px 14px 20px;
      border-bottom: 1px solid $g-color-secondary;
      border-bottom: none;
      margin-bottom: 0;
    }
  }

  .c-filters__footer {
    padding-top: 15px;
    border-top: none;
  }

  &.-dropdown {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;

    @include media-breakpoint-up(sm) {
      position: absolute;
      top: calc(100% + 12px);
      right: 0;
      height: auto;
    }
  }

  .cdk-drag-placeholder {
    @include list-item;
    border-color: $g-color-primary;
    height: 34px;
  }

  .p500-ui-form-field {
    & .p500-ui-form-field__label-inner {
      cursor: move;
    }

    &.p500-ui-form-field--checkbox .p500-ui-form-field__label {
      cursor: pointer;
    }

    @media (max-width: 600px) {
      & .p500-ui-form-field__label-inner {
        cursor: pointer;
      }

      &.p500-ui-form-field--checkbox .p500-ui-form-field__label {
        cursor: pointer;
      }
    }
  }
}
