&--pop-up {
  z-index: 1020;
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px 55px;
  gap: 20px;
  border-radius: $g-value-border-radius-xl;
  background-color: $g-color-white;
  box-shadow: 0 20px 100px 0 rgba($g-color-black, 0.15);

  @include media-breakpoint-up(sm) {
    width: auto;
    top: unset;
    right: 20px;
    bottom: 20px;
  }

  #{$root} {
    &__close {
      position: absolute;
      font-size: 24px;
      line-height: 1em;
      display: block;
      right: 18px;
      top: 18px;
      opacity: 0.3;
      cursor: pointer;
      @include h-general-transition(opacity);

      &:hover {
        opacity: 1;
      }
    }

    &__img {
      display: none;
      width: 103px;
      height: 93px;

      @include media-breakpoint-up(sm) {
        display: block;
      }
    }

    &__title {
      @include font-headline-2;
    }

    &__button {
      min-width: 80px;
      justify-content: center;
    }
  }
}
