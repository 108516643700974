.c-navigation {
  $root: &;

  padding: 0 20px;

  &__children {
    display: none;
    background-color: $g-color-white;
    border-radius: $g-value-border-radius-xl;
    padding: 9px 0;
    box-shadow: 0 10px 20px rgba($g-color-secondary, 0.05);
    margin-top: 4px;
    margin-bottom: 8px;
  }

  &__child {
    display: block;
    @include font-body-3;
    padding: 5px 10px 5px 30px;
    border-radius: 0;
    border-left: 2px solid transparent;

    color: $g-color-secondary;

    &:hover {
      color: $g-color-primary;
      text-decoration: none;
    }

    &.is-active {
      color: $g-color-primary;
      border-color: $g-color-primary;
      font-weight: 700;
    }

    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }

  &__menu-item {
    &.is-active {
      color: $g-color-primary;
    }

    &.-no-children {
      &:after {
        content: '';
        display: none;
        width: 8px;
        margin-bottom: 3px;
        padding-left: 0;
        height: 8px;
        background: $g-color-primary;
        border-radius: 50%;
      }

      &.is-active {
        &:after {
          display: block;
        }
      }
    }
  }

  &__section {
    &:first-child {
      margin-top: 8px;
    }

    &.is-opened {
      #{$root} {
        &__menu-item {
          color: $g-color-primary;

          &:after {
            opacity: 1;
            transform: scale(1, -1);
            color: $g-color-primary;
          }
        }

        &__children {
          display: block;
        }
      }
    }
  }
}
