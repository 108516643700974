&--vertical {
  width: 1px;
  display: block;

  #{$root} {
    &__line {
      height: 100%;
      min-height: 78px;
    }
  }
}
