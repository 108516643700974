&-row {
  flex: 1;
  position: relative;

  &__container {
    @include h-general-transition;
    display: flex;
    align-items: center;
    background: $g-color-white;
    border: 2px solid transparent;
    box-shadow: 0 3px 10px rgba($g-color-dark-blue, 0.03);
    margin-bottom: 8px;
    min-width: 100%;

    &,
    &:active::before {
      border-radius: 5px;
    }

    &.-loading {
      @include skeleton;
      opacity: 1;
      height: 56px;
    }

    &.-not-active {
      opacity: 0.5;

      .p500-ui-circled-button {
        background-color: rgba($g-color-dark-blue, 0.15);
        opacity: 1;

        &:hover,
        &:active,
        &:focus {
          background-color: #0f5ef7;
        }
      }

      .p500-ui-circled-button--warn {
        &:hover,
        &:active,
        &:focus {
          background-color: #dd0d0d;
        }
      }
    }

    &:focus,
    &:hover {
      border-color: rgba($g-color-dark-blue, 0.08);
    }

    .-section {
      display: flex;
      align-items: center;
      gap: 5px;
      overflow: hidden;
      white-space: nowrap;
      padding: 11px $row-section-padding;

      button {
        z-index: 1;
      }

      .ico {
        height: 28px;
      }

      &.name {
        min-width: $name-min-width;
        width: $name-width;
        padding-left: 0;

        button {
          @include reset-btn;
        }

        .mark {
          @include font-headline-5;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 45px;
          min-width: 45px;
          height: 28px;
          border-radius: 6px;

          &.-dark-blue {
            background: rgba($g-color-dark-blue, 0.05);
          }

          &.-pink {
            background: rgba(133, 55, 229, 0.1);
            color: $color-orange;
            width: 40px;
          }
        }

        .arrow {
          @include h-general-transition;
          opacity: 0;

          .p500-ui-icon {
            font-size: 14px;
            font-weight: bold;
            color: rgba($g-color-dark-blue, 0.35);
          }

          &.-has-child {
            opacity: 1;
          }

          &.-open {
            transform: rotate(90deg);
          }
        }
      }

      &.pql {
        min-width: $pql-min-width;
        width: $pql-width;
        background-color: $g-color-white;
        z-index: 2;
      }

      &.settings {
        min-width: $settings-min-width;
        width: $settings-width;
        gap: 0;
        background-color: $g-color-white;
        z-index: 3;
      }

      &.controls {
        margin-left: auto;
        justify-content: end;
        background-color: $g-color-white;
        z-index: 4;

        button:disabled {
          pointer-events: none;
        }
      }
    }
  }

  &__child {
    padding-left: 30px;
  }
}
