&--tables {
  #{$root} {
    &__item {
      white-space: nowrap;
      cursor: pointer;

      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        margin: 4px 0;
        background-color: $g-color-gray;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }

    &__item-text {
      display: block;
      padding: 5px 10px;
      border-radius: $g-value-border-radius-xl;
      min-width: 120px;
      @include font-body-3;
      @include h-general-transition;

      &:hover {
        color: $g-color-primary;
        background-color: rgba($g-color-primary, 0.1);
      }
    }
  }

  &.sticky-actions {
    .p500-ui-dropdown__header {
      height: 16px;
    }

    .c-dropdown__button {
      height: auto;
      padding: 0;
    }

    &.-new-dropdown-buttons {
      .main-button-text .c-dropdown__button-text {
        @include font-headline-4;
      }

      .p500-ui-dropdown {
        & > .p500-ui-dropdown__header {
          display: flex;
        }

        &__body {
          .p500-ui-dropdown__body-inner {
            box-shadow: 0 2px 15px 0 rgba(0, 9, 50, 0.15);
            border-radius: 4px;
            padding: 0;

            .p500-ui-dropdown__item {
              padding: 8px 12px;

              &:hover {
                background-color: #f1f3fe;
              }
            }

            .p500-ui-dropdown__item:after {
              display: none;
            }

            .p500-ui-dropdown__item-text,
            .p500-ui-dropdown__item-text:hover {
              @include font-body-3;
              display: flex;
              justify-content: flex-start;
              gap: 8px;
              padding: 0;
              transition-property: none;
              color: inherit;
              background-color: inherit;
              min-width: 0;

              p500-ui-icon {
                display: flex;
                flex-direction: column;
                justify-content: center;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}
