.c-line {
  $root: &;
  position: relative;

  &__line {
    background-color: $g-color-gray;
    border-radius: 1px;
  }

  &__label {
    @include font-headline-3;
  }

  @import 'c-line--vertical';
  @import 'c-line--horizontal';
  @import 'c-line--split-label';
}
