@font-face {
  font-family: 'platform500';
  src:  url('fonts/platform500.eot?is5td8');
  src:  url('fonts/platform500.eot?is5td8#iefix') format('embedded-opentype'),
    url('fonts/platform500.ttf?is5td8') format('truetype'),
    url('fonts/platform500.woff?is5td8') format('woff'),
    url('fonts/platform500.svg?is5td8#platform500') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="p500-ui-icon-"], [class*=" p500-ui-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'platform500' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.p500-ui-icon-account:before {
  content: "\ea99";
}
.p500-ui-icon-amazon:before {
  content: "\eb35";
}
.p500-ui-icon-android:before {
  content: "\eb37";
}
.p500-ui-icon-arrows-circle-down:before {
  content: "\eb39";
}
.p500-ui-icon-arrows-circle-left:before {
  content: "\eb3b";
}
.p500-ui-icon-arrows-circle-right:before {
  content: "\eb3c";
}
.p500-ui-icon-arrows-circle-up:before {
  content: "\eb3d";
}
.p500-ui-icon-arrows-down:before {
  content: "\eb3e";
}
.p500-ui-icon-arrows-left:before {
  content: "\eb3f";
}
.p500-ui-icon-arrows-right:before {
  content: "\eb40";
}
.p500-ui-icon-arrows-select-down:before {
  content: "\eb41";
}
.p500-ui-icon-arrows-select-left:before {
  content: "\eb42";
}
.p500-ui-icon-arrows-select-right:before {
  content: "\eb43";
}
.p500-ui-icon-arrows-select-up:before {
  content: "\eb44";
}
.p500-ui-icon-arrows-up:before {
  content: "\eb45";
}
.p500-ui-icon-auth-as:before {
  content: "\eb46";
}
.p500-ui-icon-bin:before {
  content: "\eb47";
}
.p500-ui-icon-browser:before {
  content: "\e9b6";
}
.p500-ui-icon-buttons-book:before {
  content: "\eb48";
}
.p500-ui-icon-buttons-chat:before {
  content: "\eb49";
}
.p500-ui-icon-buttons-compare:before {
  content: "\eb4a";
}
.p500-ui-icon-buttons-copy-big:before {
  content: "\eb4b";
}
.p500-ui-icon-buttons-email:before {
  content: "\eb4c";
}
.p500-ui-icon-buttons-load:before {
  content: "\eb4d";
}
.p500-ui-icon-buttons-pencil:before {
  content: "\eb4e";
}
.p500-ui-icon-buttons-plane:before {
  content: "\eb4f";
}
.p500-ui-icon-buttons-start:before {
  content: "\eb50";
}
.p500-ui-icon-chat:before {
  content: "\eb51";
}
.p500-ui-icon-check:before {
  content: "\eb52";
}
.p500-ui-icon-click-tracking:before {
  content: "\eb53";
}
.p500-ui-icon-cloud-provider:before {
  content: "\eb54";
}
.p500-ui-icon-cloud-provider1:before {
  content: "\eb55";
}
.p500-ui-icon-company:before {
  content: "\eb56";
}
.p500-ui-icon-company-strategy:before {
  content: "\eb57";
}
.p500-ui-icon-control-refresh-bold:before {
  content: "\eb58";
}
.p500-ui-icon-controls-close:before {
  content: "\eb59";
}
.p500-ui-icon-controls-crown:before {
  content: "\eb5a";
}
.p500-ui-icon-controls-date:before {
  content: "\eb5b";
}
.p500-ui-icon-controls-download:before {
  content: "\eb5c";
}
.p500-ui-icon-controls-filters:before {
  content: "\eb5d";
}
.p500-ui-icon-controls-menu:before {
  content: "\eb5e";
}
.p500-ui-icon-controls-minus:before {
  content: "\eb5f";
}
.p500-ui-icon-controls-play-outline:before {
  content: "\eb60";
}
.p500-ui-icon-controls-plus:before {
  content: "\eb61";
}
.p500-ui-icon-controls-refresh:before {
  content: "\eb62";
}
.p500-ui-icon-credit:before {
  content: "\eb63";
}
.p500-ui-icon-currency-1:before {
  content: "\eb64";
}
.p500-ui-icon-domains:before {
  content: "\eb65";
}
.p500-ui-icon-dots-vertical-drag:before {
  content: "\ebe2";
}
.p500-ui-icon-download:before {
  content: "\eb66";
}
.p500-ui-icon-empty:before {
  content: "\eb67";
}
.p500-ui-icon-exit:before {
  content: "\eb68";
}
.p500-ui-icon-facebook:before {
  content: "\eb69";
}
.p500-ui-icon-filter:before {
  content: "\eb6a";
}
.p500-ui-icon-goal:before {
  content: "\eb6b";
}
.p500-ui-icon-groups:before {
  content: "\eb6c";
}
.p500-ui-icon-groups1:before {
  content: "\eb6d";
}
.p500-ui-icon-information:before {
  content: "\eb6e";
}
.p500-ui-icon-insert:before {
  content: "\eb6f";
}
.p500-ui-icon-instagram:before {
  content: "\eb70";
}
.p500-ui-icon-intergraions:before {
  content: "\eb71";
}
.p500-ui-icon-ios:before {
  content: "\eb72";
}
.p500-ui-icon-irev:before {
  content: "\eb73";
}
.p500-ui-icon-link:before {
  content: "\eb74";
}
.p500-ui-icon-link-2:before {
  content: "\ebe5";
}
.p500-ui-icon-linkedin:before {
  content: "\eb75";
}
.p500-ui-icon-mac-os:before {
  content: "\eb76";
}
.p500-ui-icon-managment-2:before {
  content: "\eb77";
}
.p500-ui-icon-menu-advertiser:before {
  content: "\eb78";
}
.p500-ui-icon-menu-affilate:before {
  content: "\eb79";
}
.p500-ui-icon-menu-bag:before {
  content: "\eb7a";
}
.p500-ui-icon-menu-code:before {
  content: "\eb7b";
}
.p500-ui-icon-menu-communications:before {
  content: "\eb7c";
}
.p500-ui-icon-menu-control:before {
  content: "\eb7d";
}
.p500-ui-icon-menu-external:before {
  content: "\eb7e";
}
.p500-ui-icon-menu-eye:before {
  content: "\eb7f";
}
.p500-ui-icon-menu-finance:before {
  content: "\eb80";
}
.p500-ui-icon-menu-folders:before {
  content: "\eb81";
}
.p500-ui-icon-menu-home:before {
  content: "\eb82";
}
.p500-ui-icon-menu-landings:before {
  content: "\eb83";
}
.p500-ui-icon-menu-managment:before {
  content: "\eb84";
}
.p500-ui-icon-menu-notification:before {
  content: "\eb85";
}
.p500-ui-icon-menu-reporting:before {
  content: "\eb86";
}
.p500-ui-icon-menu-speaker:before {
  content: "\eb87";
}
.p500-ui-icon-menu-template:before {
  content: "\eb88";
}
.p500-ui-icon-menu-tools:before {
  content: "\eb89";
}
.p500-ui-icon-menu-traffic-control:before {
  content: "\eb8a";
}
.p500-ui-icon-menu-traffic-data:before {
  content: "\eb8b";
}
.p500-ui-icon-other-apple:before {
  content: "\eb8c";
}
.p500-ui-icon-other-boolean:before {
  content: "\eb8d";
}
.p500-ui-icon-other-box:before {
  content: "\eb8e";
}
.p500-ui-icon-other-collapse:before {
  content: "\eb8f";
}
.p500-ui-icon-other-danger:before {
  content: "\eb90";
}
.p500-ui-icon-other-date:before {
  content: "\eb91";
}
.p500-ui-icon-other-email:before {
  content: "\eb92";
}
.p500-ui-icon-other-expand:before {
  content: "\eb93";
}
.p500-ui-icon-other-key:before {
  content: "\eb94";
}
.p500-ui-icon-other-learning:before {
  content: "\eb95";
}
.p500-ui-icon-other-list:before {
  content: "\eb96";
}
.p500-ui-icon-other-menu:before {
  content: "\eb97";
}
.p500-ui-icon-other-metrics:before {
  content: "\eb98";
}
.p500-ui-icon-other-metrics-1:before {
  content: "\eb9a";
}
.p500-ui-icon-other-metrics-2:before {
  content: "\eb9b";
}
.p500-ui-icon-other-os:before {
  content: "\eb99";
}
.p500-ui-icon-other-password:before {
  content: "\eb9c";
}
.p500-ui-icon-other-payout:before {
  content: "\eb9d";
}
.p500-ui-icon-other-phone:before {
  content: "\eb9e";
}
.p500-ui-icon-other-pie-chart:before {
  content: "\eb9f";
}
.p500-ui-icon-other-planet:before {
  content: "\eba0";
}
.p500-ui-icon-other-podium:before {
  content: "\eba1";
}
.p500-ui-icon-other-revenue:before {
  content: "\eba2";
}
.p500-ui-icon-other-server:before {
  content: "\eba3";
}
.p500-ui-icon-other-target:before {
  content: "\eba4";
}
.p500-ui-icon-other-transfer:before {
  content: "\eba5";
}
.p500-ui-icon-other-tree:before {
  content: "\eba6";
}
.p500-ui-icon-other-typo:before {
  content: "\eba7";
}
.p500-ui-icon-other-upload:before {
  content: "\eba8";
}
.p500-ui-icon-other-user:before {
  content: "\eba9";
}
.p500-ui-icon-other-user-1:before {
  content: "\ebaa";
}
.p500-ui-icon-other-warning:before {
  content: "\ebab";
}
.p500-ui-icon-other-wiki:before {
  content: "\ebac";
}
.p500-ui-icon-pause:before {
  content: "\ebad";
}
.p500-ui-icon-pdf:before {
  content: "\ebae";
}
.p500-ui-icon-products:before {
  content: "\ebaf";
}
.p500-ui-icon-question:before {
  content: "\ebb0";
}
.p500-ui-icon-question2:before {
  content: "\ebb1";
}
.p500-ui-icon-reporting-brand:before {
  content: "\ebb2";
}
.p500-ui-icon-reporting-conversion:before {
  content: "\ebb3";
}
.p500-ui-icon-reporting-event:before {
  content: "\ebb4";
}
.p500-ui-icon-reporting-ftd:before {
  content: "\ebb5";
}
.p500-ui-icon-reporting-funnel:before {
  content: "\ebb6";
}
.p500-ui-icon-reporting-lead:before {
  content: "\ebb7";
}
.p500-ui-icon-reporting-rejected-leads:before {
  content: "\ebb9";
}
.p500-ui-icon-reporting-steps:before {
  content: "\ebbb";
}
.p500-ui-icon-revenue:before {
  content: "\ebbd";
}
.p500-ui-icon-rule:before {
  content: "\ebbf";
}
.p500-ui-icon-search:before {
  content: "\ebc1";
}
.p500-ui-icon-secure:before {
  content: "\ebc3";
}
.p500-ui-icon-secure-2:before {
  content: "\ebc5";
}
.p500-ui-icon-security:before {
  content: "\ebc7";
}
.p500-ui-icon-servers:before {
  content: "\ebc9";
}
.p500-ui-icon-settings:before {
  content: "\ebcb";
}
.p500-ui-icon-skype:before {
  content: "\ebcd";
}
.p500-ui-icon-support:before {
  content: "\ebcf";
}
.p500-ui-icon-timestamps:before {
  content: "\ebd1";
}
.p500-ui-icon-twitter:before {
  content: "\ebd3";
}
.p500-ui-icon-wallet:before {
  content: "\ebd5";
}
.p500-ui-icon-welcome:before {
  content: "\ebd7";
}
.p500-ui-icon-windows:before {
  content: "\ebd9";
}
.p500-ui-icon-workers:before {
  content: "\ebdb";
}
.p500-ui-icon-workflow:before {
  content: "\ebde";
}
.p500-ui-icon-youtube:before {
  content: "\ebe0";
}
.p500-ui-icon-edit-02:before {
  content: "\ecdc";
}
.p500-ui-icon-activity:before {
  content: "\e900";
}
.p500-ui-icon-alarm-clock:before {
  content: "\e901";
}
.p500-ui-icon-alarm-clock-check:before {
  content: "\e902";
}
.p500-ui-icon-alarm-clock-minus:before {
  content: "\e903";
}
.p500-ui-icon-alarm-clock-off:before {
  content: "\e904";
}
.p500-ui-icon-alarm-clock-plus:before {
  content: "\e905";
}
.p500-ui-icon-anchor:before {
  content: "\e906";
}
.p500-ui-icon-bus:before {
  content: "\e907";
}
.p500-ui-icon-calendar-check-01:before {
  content: "\e908";
}
.p500-ui-icon-calendar-check-02:before {
  content: "\e909";
}
.p500-ui-icon-calendar-date:before {
  content: "\e90a";
}
.p500-ui-icon-calendar-heart-01:before {
  content: "\e90b";
}
.p500-ui-icon-calendar-heart-02:before {
  content: "\e90c";
}
.p500-ui-icon-calendar-minus-01:before {
  content: "\e90d";
}
.p500-ui-icon-calendar-minus-02:before {
  content: "\e90e";
}
.p500-ui-icon-calendar-plus-01:before {
  content: "\e90f";
}
.p500-ui-icon-calendar-plus-02:before {
  content: "\e910";
}
.p500-ui-icon-camera-lens:before {
  content: "\e911";
}
.p500-ui-icon-car-01:before {
  content: "\e912";
}
.p500-ui-icon-car-02:before {
  content: "\e913";
}
.p500-ui-icon-check-circle-broken:before {
  content: "\e914";
}
.p500-ui-icon-clapperboard:before {
  content: "\e915";
}
.p500-ui-icon-clock-check:before {
  content: "\e916";
}
.p500-ui-icon-clock-fast-forward:before {
  content: "\e917";
}
.p500-ui-icon-clock-plus:before {
  content: "\e918";
}
.p500-ui-icon-clock-refresh:before {
  content: "\e919";
}
.p500-ui-icon-clock-rewind:before {
  content: "\e91a";
}
.p500-ui-icon-clock-snooze:before {
  content: "\e91b";
}
.p500-ui-icon-clock-stopwatch:before {
  content: "\e91c";
}
.p500-ui-icon-cloud-02:before {
  content: "\e91d";
}
.p500-ui-icon-cloud-03:before {
  content: "\e91e";
}
.p500-ui-icon-cloud-lightning:before {
  content: "\e91f";
}
.p500-ui-icon-cloud-moon:before {
  content: "\e920";
}
.p500-ui-icon-cloud-off:before {
  content: "\e921";
}
.p500-ui-icon-cloud-raining-01:before {
  content: "\e922";
}
.p500-ui-icon-cloud-raining-02:before {
  content: "\e923";
}
.p500-ui-icon-cloud-raining-03:before {
  content: "\e924";
}
.p500-ui-icon-cloud-raining-04:before {
  content: "\e925";
}
.p500-ui-icon-cloud-raining-05:before {
  content: "\e926";
}
.p500-ui-icon-cloud-raining-06:before {
  content: "\e927";
}
.p500-ui-icon-cloud-snowing-01:before {
  content: "\e928";
}
.p500-ui-icon-cloud-snowing-02:before {
  content: "\e929";
}
.p500-ui-icon-cloud-sun-01:before {
  content: "\e92a";
}
.p500-ui-icon-cloud-sun-02:before {
  content: "\e92b";
}
.p500-ui-icon-cloud-sun-03:before {
  content: "\e92c";
}
.p500-ui-icon-compass-01:before {
  content: "\e92d";
}
.p500-ui-icon-compass-02:before {
  content: "\e92e";
}
.p500-ui-icon-compass-03:before {
  content: "\e92f";
}
.p500-ui-icon-cursor-box:before {
  content: "\e930";
}
.p500-ui-icon-dice-1:before {
  content: "\e931";
}
.p500-ui-icon-droplets-01:before {
  content: "\e932";
}
.p500-ui-icon-droplets-02:before {
  content: "\e933";
}
.p500-ui-icon-droplets-03:before {
  content: "\e934";
}
.p500-ui-icon-flag-01:before {
  content: "\e935";
}
.p500-ui-icon-flag-02:before {
  content: "\e936";
}
.p500-ui-icon-flag-03:before {
  content: "\e937";
}
.p500-ui-icon-flag-04:before {
  content: "\e938";
}
.p500-ui-icon-flag-05:before {
  content: "\e939";
}
.p500-ui-icon-flag-06:before {
  content: "\e93a";
}
.p500-ui-icon-globe-03:before {
  content: "\e93b";
}
.p500-ui-icon-globe-05:before {
  content: "\e93c";
}
.p500-ui-icon-globe-06:before {
  content: "\e93d";
}
.p500-ui-icon-google-chrome:before {
  content: "\e93e";
}
.p500-ui-icon-hard-drive:before {
  content: "\e93f";
}
.p500-ui-icon-hearts:before {
  content: "\e940";
}
.p500-ui-icon-hexagon-02:before {
  content: "\e941";
}
.p500-ui-icon-hourglass-01:before {
  content: "\e942";
}
.p500-ui-icon-hourglass-02:before {
  content: "\e943";
}
.p500-ui-icon-hourglass-03:before {
  content: "\e944";
}
.p500-ui-icon-hurricane-01:before {
  content: "\e945";
}
.p500-ui-icon-hurricane-02:before {
  content: "\e946";
}
.p500-ui-icon-hurricane-03:before {
  content: "\e947";
}
.p500-ui-icon-infinity:before {
  content: "\e948";
}
.p500-ui-icon-lightning-02:before {
  content: "\e949";
}
.p500-ui-icon-loading-03:before {
  content: "\e94a";
}
.p500-ui-icon-luggage-01:before {
  content: "\e94b";
}
.p500-ui-icon-map-01:before {
  content: "\e94c";
}
.p500-ui-icon-map-02:before {
  content: "\e94d";
}
.p500-ui-icon-mark:before {
  content: "\e94e";
}
.p500-ui-icon-marker-pin-01:before {
  content: "\e94f";
}
.p500-ui-icon-marker-pin-02:before {
  content: "\e950";
}
.p500-ui-icon-marker-pin-03:before {
  content: "\e951";
}
.p500-ui-icon-marker-pin-04:before {
  content: "\e952";
}
.p500-ui-icon-marker-pin-05:before {
  content: "\e953";
}
.p500-ui-icon-marker-pin-06:before {
  content: "\e954";
}
.p500-ui-icon-medical-cross:before {
  content: "\e955";
}
.p500-ui-icon-moon-01:before {
  content: "\e956";
}
.p500-ui-icon-moon-02:before {
  content: "\e957";
}
.p500-ui-icon-moon-eclipse:before {
  content: "\e958";
}
.p500-ui-icon-moon-star:before {
  content: "\e959";
}
.p500-ui-icon-passport:before {
  content: "\e95a";
}
.p500-ui-icon-piggy-bank-01:before {
  content: "\e95b";
}
.p500-ui-icon-piggy-bank-02:before {
  content: "\e95c";
}
.p500-ui-icon-plane:before {
  content: "\e95d";
}
.p500-ui-icon-power-01:before {
  content: "\e95e";
}
.p500-ui-icon-receipt:before {
  content: "\e95f";
}
.p500-ui-icon-receipt-check:before {
  content: "\e960";
}
.p500-ui-icon-rocket-01:before {
  content: "\e961";
}
.p500-ui-icon-rocket-02:before {
  content: "\e962";
}
.p500-ui-icon-safe:before {
  content: "\e963";
}
.p500-ui-icon-share-07:before {
  content: "\e964";
}
.p500-ui-icon-shopping-bag-01:before {
  content: "\e965";
}
.p500-ui-icon-shopping-bag-02:before {
  content: "\e966";
}
.p500-ui-icon-shopping-bag-03:before {
  content: "\e967";
}
.p500-ui-icon-simcard:before {
  content: "\e968";
}
.p500-ui-icon-snowflake-01:before {
  content: "\e969";
}
.p500-ui-icon-snowflake-02:before {
  content: "\e96a";
}
.p500-ui-icon-star-07:before {
  content: "\e96b";
}
.p500-ui-icon-stars-01:before {
  content: "\e96c";
}
.p500-ui-icon-stars-02:before {
  content: "\e96d";
}
.p500-ui-icon-stars-03:before {
  content: "\e96e";
}
.p500-ui-icon-sun:before {
  content: "\e96f";
}
.p500-ui-icon-sunrise:before {
  content: "\e970";
}
.p500-ui-icon-sunset:before {
  content: "\e971";
}
.p500-ui-icon-sun-setting-01:before {
  content: "\e972";
}
.p500-ui-icon-sun-setting-02:before {
  content: "\e973";
}
.p500-ui-icon-sun-setting-03:before {
  content: "\e974";
}
.p500-ui-icon-thermometer-01:before {
  content: "\e975";
}
.p500-ui-icon-thermometer-02:before {
  content: "\e976";
}
.p500-ui-icon-thermometer-03:before {
  content: "\e977";
}
.p500-ui-icon-thermometer-cold:before {
  content: "\e978";
}
.p500-ui-icon-thermometer-warm:before {
  content: "\e979";
}
.p500-ui-icon-ticket-01:before {
  content: "\e97a";
}
.p500-ui-icon-ticket-02:before {
  content: "\e97b";
}
.p500-ui-icon-train:before {
  content: "\e97c";
}
.p500-ui-icon-tram:before {
  content: "\e97d";
}
.p500-ui-icon-truck-01:before {
  content: "\e97e";
}
.p500-ui-icon-truck-02:before {
  content: "\e97f";
}
.p500-ui-icon-umbrella-01:before {
  content: "\e980";
}
.p500-ui-icon-umbrella-02:before {
  content: "\e981";
}
.p500-ui-icon-umbrella-03:before {
  content: "\e982";
}
.p500-ui-icon-usb-flash-drive:before {
  content: "\e983";
}
.p500-ui-icon-variable:before {
  content: "\e984";
}
.p500-ui-icon-wallet-01:before {
  content: "\e985";
}
.p500-ui-icon-wallet-03:before {
  content: "\e986";
}
.p500-ui-icon-wallet-04:before {
  content: "\e987";
}
.p500-ui-icon-wallet-05:before {
  content: "\e988";
}
.p500-ui-icon-watch-circle:before {
  content: "\e989";
}
.p500-ui-icon-watch-square:before {
  content: "\e98a";
}
.p500-ui-icon-waves:before {
  content: "\e98b";
}
.p500-ui-icon-wind-01:before {
  content: "\e98c";
}
.p500-ui-icon-wind-02:before {
  content: "\e98d";
}
.p500-ui-icon-wind-03:before {
  content: "\e98e";
}
.p500-ui-icon-atom-01:before {
  content: "\e98f";
}
.p500-ui-icon-atom-02:before {
  content: "\e990";
}
.p500-ui-icon-award-01:before {
  content: "\e991";
}
.p500-ui-icon-award-02:before {
  content: "\e992";
}
.p500-ui-icon-award-03:before {
  content: "\e993";
}
.p500-ui-icon-award-04:before {
  content: "\e994";
}
.p500-ui-icon-backpack:before {
  content: "\e995";
}
.p500-ui-icon-bank:before {
  content: "\e996";
}
.p500-ui-icon-bank-note-01:before {
  content: "\e997";
}
.p500-ui-icon-bank-note-02:before {
  content: "\e998";
}
.p500-ui-icon-bank-note-03:before {
  content: "\e999";
}
.p500-ui-icon-beaker-01:before {
  content: "\e99a";
}
.p500-ui-icon-beaker-02:before {
  content: "\e99b";
}
.p500-ui-icon-bold-01:before {
  content: "\e99c";
}
.p500-ui-icon-bold-02:before {
  content: "\e99d";
}
.p500-ui-icon-book-closed:before {
  content: "\e99e";
}
.p500-ui-icon-book-open-01:before {
  content: "\e99f";
}
.p500-ui-icon-book-open-02:before {
  content: "\e9a0";
}
.p500-ui-icon-briefcase-02:before {
  content: "\e9a1";
}
.p500-ui-icon-building-08:before {
  content: "\e9a2";
}
.p500-ui-icon-camera-01:before {
  content: "\e9a3";
}
.p500-ui-icon-camera-02:before {
  content: "\e9a4";
}
.p500-ui-icon-camera-03:before {
  content: "\e9a5";
}
.p500-ui-icon-camera-off:before {
  content: "\e9a6";
}
.p500-ui-icon-camera-plus:before {
  content: "\e9a7";
}
.p500-ui-icon-certificate-01:before {
  content: "\e9a8";
}
.p500-ui-icon-certificate-02:before {
  content: "\e9a9";
}
.p500-ui-icon-coins-01:before {
  content: "\e9aa";
}
.p500-ui-icon-coins-02:before {
  content: "\e9ab";
}
.p500-ui-icon-coins-03:before {
  content: "\e9ac";
}
.p500-ui-icon-coins-04:before {
  content: "\e9ad";
}
.p500-ui-icon-coins-stacked-01:before {
  content: "\e9ae";
}
.p500-ui-icon-coins-stacked-03:before {
  content: "\e9af";
}
.p500-ui-icon-coins-stacked-04:before {
  content: "\e9b0";
}
.p500-ui-icon-credit-card-01:before {
  content: "\e9b1";
}
.p500-ui-icon-credit-card-check:before {
  content: "\e9b2";
}
.p500-ui-icon-credit-card-down:before {
  content: "\e9b3";
}
.p500-ui-icon-credit-card-download:before {
  content: "\e9b4";
}
.p500-ui-icon-credit-card-edit:before {
  content: "\e9b5";
}
.p500-ui-icon-credit-card-lock:before {
  content: "\e9b7";
}
.p500-ui-icon-credit-card-minus:before {
  content: "\e9b8";
}
.p500-ui-icon-credit-card-plus:before {
  content: "\e9b9";
}
.p500-ui-icon-credit-card-refresh:before {
  content: "\e9ba";
}
.p500-ui-icon-credit-card-search:before {
  content: "\e9bb";
}
.p500-ui-icon-credit-card-shield:before {
  content: "\e9bc";
}
.p500-ui-icon-credit-card-up:before {
  content: "\e9bd";
}
.p500-ui-icon-credit-card-upload:before {
  content: "\e9be";
}
.p500-ui-icon-credit-card-x:before {
  content: "\e9bf";
}
.p500-ui-icon-cryptocurrency-01:before {
  content: "\e9c0";
}
.p500-ui-icon-cryptocurrency-02:before {
  content: "\e9c1";
}
.p500-ui-icon-cryptocurrency-03:before {
  content: "\e9c2";
}
.p500-ui-icon-cryptocurrency-04:before {
  content: "\e9c3";
}
.p500-ui-icon-cube-03:before {
  content: "\e9c4";
}
.p500-ui-icon-cube-04:before {
  content: "\e9c5";
}
.p500-ui-icon-currency-bitcoin:before {
  content: "\e9c6";
}
.p500-ui-icon-currency-dollar:before {
  content: "\e9c7";
}
.p500-ui-icon-currency-ethereum:before {
  content: "\e9c8";
}
.p500-ui-icon-currency-euro:before {
  content: "\e9c9";
}
.p500-ui-icon-currency-pound:before {
  content: "\e9ca";
}
.p500-ui-icon-currency-ruble:before {
  content: "\e9cb";
}
.p500-ui-icon-currency-rupee:before {
  content: "\e9cc";
}
.p500-ui-icon-currency-yen:before {
  content: "\e9cd";
}
.p500-ui-icon-data:before {
  content: "\e9ce";
}
.p500-ui-icon-dataflow-03:before {
  content: "\e9cf";
}
.p500-ui-icon-diamond-01:before {
  content: "\e9d0";
}
.p500-ui-icon-diamond-02:before {
  content: "\e9d1";
}
.p500-ui-icon-file-attachment-01:before {
  content: "\e9d2";
}
.p500-ui-icon-file-attachment-02:before {
  content: "\e9d3";
}
.p500-ui-icon-gift-01:before {
  content: "\e9d4";
}
.p500-ui-icon-gift-02:before {
  content: "\e9d5";
}
.p500-ui-icon-glasses-01:before {
  content: "\e9d6";
}
.p500-ui-icon-glasses-02:before {
  content: "\e9d7";
}
.p500-ui-icon-globe-slated-01:before {
  content: "\e9d8";
}
.p500-ui-icon-globe-slated-02:before {
  content: "\e9d9";
}
.p500-ui-icon-graduation-hat-01:before {
  content: "\e9da";
}
.p500-ui-icon-graduation-hat-02:before {
  content: "\e9db";
}
.p500-ui-icon-heading-01:before {
  content: "\e9dc";
}
.p500-ui-icon-heading-02:before {
  content: "\e9dd";
}
.p500-ui-icon-heart-hand:before {
  content: "\e9de";
}
.p500-ui-icon-image-03:before {
  content: "\e9df";
}
.p500-ui-icon-image-05:before {
  content: "\e9e0";
}
.p500-ui-icon-image-indent-left:before {
  content: "\e9e1";
}
.p500-ui-icon-image-indent-right:before {
  content: "\e9e2";
}
.p500-ui-icon-image-user:before {
  content: "\e9e3";
}
.p500-ui-icon-italic-01:before {
  content: "\e9e4";
}
.p500-ui-icon-italic-02:before {
  content: "\e9e5";
}
.p500-ui-icon-left-indent-01:before {
  content: "\e9e6";
}
.p500-ui-icon-left-indent-02:before {
  content: "\e9e7";
}
.p500-ui-icon-letter-spacing-02:before {
  content: "\e9e8";
}
.p500-ui-icon-luggage-03:before {
  content: "\e9e9";
}
.p500-ui-icon-microscope:before {
  content: "\e9ea";
}
.p500-ui-icon-paragraph-spacing:before {
  content: "\e9eb";
}
.p500-ui-icon-paragraph-wrap:before {
  content: "\e9ec";
}
.p500-ui-icon-pilcrow-01:before {
  content: "\e9ed";
}
.p500-ui-icon-pilcrow-02:before {
  content: "\e9ee";
}
.p500-ui-icon-right-indent-01:before {
  content: "\e9ef";
}
.p500-ui-icon-right-indent-02:before {
  content: "\e9f0";
}
.p500-ui-icon-sale-02:before {
  content: "\e9f1";
}
.p500-ui-icon-sale-03:before {
  content: "\e9f2";
}
.p500-ui-icon-sale-04:before {
  content: "\e9f3";
}
.p500-ui-icon-scales-01:before {
  content: "\e9f4";
}
.p500-ui-icon-scales-02:before {
  content: "\e9f5";
}
.p500-ui-icon-shopping-cart-01:before {
  content: "\e9f6";
}
.p500-ui-icon-shopping-cart-02:before {
  content: "\e9f7";
}
.p500-ui-icon-shopping-cart-03:before {
  content: "\e9f8";
}
.p500-ui-icon-stand:before {
  content: "\e9f9";
}
.p500-ui-icon-sticker-circle:before {
  content: "\e9fa";
}
.p500-ui-icon-strikethrough-01:before {
  content: "\e9fb";
}
.p500-ui-icon-strikethrough-02:before {
  content: "\e9fc";
}
.p500-ui-icon-tag-01:before {
  content: "\e9fd";
}
.p500-ui-icon-tag-02:before {
  content: "\e9fe";
}
.p500-ui-icon-tag-03:before {
  content: "\e9ff";
}
.p500-ui-icon-telescope:before {
  content: "\ea00";
}
.p500-ui-icon-trophy-01:before {
  content: "\ea01";
}
.p500-ui-icon-trophy-02:before {
  content: "\ea02";
}
.p500-ui-icon-type-01:before {
  content: "\ea03";
}
.p500-ui-icon-type-02:before {
  content: "\ea04";
}
.p500-ui-icon-type-strikethrough-01:before {
  content: "\ea05";
}
.p500-ui-icon-type-strikethrough-02:before {
  content: "\ea06";
}
.p500-ui-icon-underline-01:before {
  content: "\ea07";
}
.p500-ui-icon-underline-02:before {
  content: "\ea08";
}
.p500-ui-icon-zoom-in:before {
  content: "\ea09";
}
.p500-ui-icon-zoom-out:before {
  content: "\ea0a";
}
.p500-ui-icon-sticker-circle1:before {
  content: "\ea5f";
}
.p500-ui-icon-pencil-01:before {
  content: "\ec40";
}
.p500-ui-icon-pencil-line:before {
  content: "\ec41";
}
.p500-ui-icon-attachment-02:before {
  content: "\ea0b";
}
.p500-ui-icon-bezier-curve-01:before {
  content: "\ea0c";
}
.p500-ui-icon-bezier-curve-02:before {
  content: "\ec42";
}
.p500-ui-icon-bezier-curve-03:before {
  content: "\ea0d";
}
.p500-ui-icon-brush-01:before {
  content: "\ea0e";
}
.p500-ui-icon-brush-02:before {
  content: "\ea0f";
}
.p500-ui-icon-brush-03:before {
  content: "\ea10";
}
.p500-ui-icon-circle-cut:before {
  content: "\ea11";
}
.p500-ui-icon-clipboard:before {
  content: "\ea12";
}
.p500-ui-icon-clipboard-attachment:before {
  content: "\ea13";
}
.p500-ui-icon-clipboard-check:before {
  content: "\ea14";
}
.p500-ui-icon-clipboard-download:before {
  content: "\ea15";
}
.p500-ui-icon-clipboard-minus:before {
  content: "\ea16";
}
.p500-ui-icon-clipboard-plus:before {
  content: "\ea17";
}
.p500-ui-icon-clipboard-x:before {
  content: "\ea18";
}
.p500-ui-icon-code-snippet-01:before {
  content: "\ea19";
}
.p500-ui-icon-colors:before {
  content: "\ea1a";
}
.p500-ui-icon-command:before {
  content: "\ea1b";
}
.p500-ui-icon-compass:before {
  content: "\ea1c";
}
.p500-ui-icon-contrast-01:before {
  content: "\ea1d";
}
.p500-ui-icon-contrast-02:before {
  content: "\ea1e";
}
.p500-ui-icon-contrast-03:before {
  content: "\ea1f";
}
.p500-ui-icon-crop-01:before {
  content: "\ea20";
}
.p500-ui-icon-crop-02:before {
  content: "\ea21";
}
.p500-ui-icon-cursor-click-01:before {
  content: "\ea22";
}
.p500-ui-icon-delete:before {
  content: "\ea23";
}
.p500-ui-icon-divider:before {
  content: "\ea24";
}
.p500-ui-icon-drop:before {
  content: "\ea25";
}
.p500-ui-icon-dropper:before {
  content: "\ec43";
}
.p500-ui-icon-eraser:before {
  content: "\ea26";
}
.p500-ui-icon-feather:before {
  content: "\ea27";
}
.p500-ui-icon-figma:before {
  content: "\ec44";
}
.p500-ui-icon-file-01:before {
  content: "\ec45";
}
.p500-ui-icon-file-05:before {
  content: "\ec46";
}
.p500-ui-icon-file-07:before {
  content: "\ec47";
}
.p500-ui-icon-file-attachment-03:before {
  content: "\ec48";
}
.p500-ui-icon-file-attachment-04:before {
  content: "\ec49";
}
.p500-ui-icon-file-attachment-05:before {
  content: "\ea28";
}
.p500-ui-icon-file-check-01:before {
  content: "\ea29";
}
.p500-ui-icon-file-check-02:before {
  content: "\ea2a";
}
.p500-ui-icon-file-check-03:before {
  content: "\ea2b";
}
.p500-ui-icon-file-code-01:before {
  content: "\ea2c";
}
.p500-ui-icon-file-code-02:before {
  content: "\ea2d";
}
.p500-ui-icon-file-download-01:before {
  content: "\ea2e";
}
.p500-ui-icon-file-download-02:before {
  content: "\ea2f";
}
.p500-ui-icon-file-download-03:before {
  content: "\ec4a";
}
.p500-ui-icon-file-heart-01:before {
  content: "\ea30";
}
.p500-ui-icon-file-heart-02:before {
  content: "\ea31";
}
.p500-ui-icon-file-heart-03:before {
  content: "\ea32";
}
.p500-ui-icon-file-lock-01:before {
  content: "\ea33";
}
.p500-ui-icon-file-lock-02:before {
  content: "\ea34";
}
.p500-ui-icon-file-lock-03:before {
  content: "\ea35";
}
.p500-ui-icon-file-minus-01:before {
  content: "\ea36";
}
.p500-ui-icon-file-minus-02:before {
  content: "\ea37";
}
.p500-ui-icon-file-minus-03:before {
  content: "\ea38";
}
.p500-ui-icon-file-plus-01:before {
  content: "\ea39";
}
.p500-ui-icon-file-plus-02:before {
  content: "\ea3a";
}
.p500-ui-icon-file-plus-03:before {
  content: "\ea3b";
}
.p500-ui-icon-file-question-01:before {
  content: "\ec4b";
}
.p500-ui-icon-file-question-02:before {
  content: "\ec4c";
}
.p500-ui-icon-file-question-03:before {
  content: "\ea3c";
}
.p500-ui-icon-file-search-01:before {
  content: "\ea3d";
}
.p500-ui-icon-file-shield-01:before {
  content: "\ea3e";
}
.p500-ui-icon-file-shield-02:before {
  content: "\ea3f";
}
.p500-ui-icon-file-shield-03:before {
  content: "\ec4d";
}
.p500-ui-icon-file-x-01:before {
  content: "\ea40";
}
.p500-ui-icon-file-x-02:before {
  content: "\ea41";
}
.p500-ui-icon-file-x-03:before {
  content: "\ea42";
}
.p500-ui-icon-folder-closed:before {
  content: "\ea43";
}
.p500-ui-icon-folder-code:before {
  content: "\ea44";
}
.p500-ui-icon-folder-lock:before {
  content: "\ea45";
}
.p500-ui-icon-folder-minus:before {
  content: "\ea46";
}
.p500-ui-icon-folder-question:before {
  content: "\ea47";
}
.p500-ui-icon-folder-search:before {
  content: "\ea48";
}
.p500-ui-icon-folder-shield:before {
  content: "\ea49";
}
.p500-ui-icon-folder-x:before {
  content: "\ea4a";
}
.p500-ui-icon-framer:before {
  content: "\ea4b";
}
.p500-ui-icon-hand:before {
  content: "\ea4c";
}
.p500-ui-icon-image-01:before {
  content: "\ea4d";
}
.p500-ui-icon-image-02:before {
  content: "\ea4e";
}
.p500-ui-icon-image-check:before {
  content: "\ea4f";
}
.p500-ui-icon-image-down:before {
  content: "\ea50";
}
.p500-ui-icon-image-left:before {
  content: "\ea51";
}
.p500-ui-icon-image-plus:before {
  content: "\ea52";
}
.p500-ui-icon-image-right:before {
  content: "\ea53";
}
.p500-ui-icon-image-up:before {
  content: "\ea54";
}
.p500-ui-icon-image-user-check:before {
  content: "\ea55";
}
.p500-ui-icon-image-user-down:before {
  content: "\ea56";
}
.p500-ui-icon-image-user-left:before {
  content: "\ea57";
}
.p500-ui-icon-image-user-plus:before {
  content: "\ea58";
}
.p500-ui-icon-image-user-right:before {
  content: "\ea59";
}
.p500-ui-icon-image-user-up:before {
  content: "\ea5a";
}
.p500-ui-icon-image-user-x:before {
  content: "\ea5b";
}
.p500-ui-icon-image-x:before {
  content: "\ea5c";
}
.p500-ui-icon-intersect-circle:before {
  content: "\ea5d";
}
.p500-ui-icon-intersect-square:before {
  content: "\ea5e";
}
.p500-ui-icon-italic-square:before {
  content: "\ea60";
}
.p500-ui-icon-magic-wand-01:before {
  content: "\ea61";
}
.p500-ui-icon-magic-wand-02:before {
  content: "\ea62";
}
.p500-ui-icon-move:before {
  content: "\ea63";
}
.p500-ui-icon-paint:before {
  content: "\ea64";
}
.p500-ui-icon-paint-pour:before {
  content: "\ea65";
}
.p500-ui-icon-palette:before {
  content: "\ea66";
}
.p500-ui-icon-paperclip:before {
  content: "\ea67";
}
.p500-ui-icon-pen-tool-01:before {
  content: "\ea68";
}
.p500-ui-icon-pen-tool-02:before {
  content: "\ea69";
}
.p500-ui-icon-pen-tool-minus:before {
  content: "\ec4e";
}
.p500-ui-icon-pen-tool-plus:before {
  content: "\ec4f";
}
.p500-ui-icon-pencil-02:before {
  content: "\ea6a";
}
.p500-ui-icon-pentagon:before {
  content: "\ea6b";
}
.p500-ui-icon-perspective-01:before {
  content: "\ea6c";
}
.p500-ui-icon-perspective-02:before {
  content: "\ea6d";
}
.p500-ui-icon-reflect-02:before {
  content: "\ea6e";
}
.p500-ui-icon-roller-brush:before {
  content: "\ea6f";
}
.p500-ui-icon-ruler:before {
  content: "\ea70";
}
.p500-ui-icon-scale-01:before {
  content: "\ea71";
}
.p500-ui-icon-scale-02:before {
  content: "\ea72";
}
.p500-ui-icon-scale-03:before {
  content: "\ea73";
}
.p500-ui-icon-scissors-01:before {
  content: "\ea74";
}
.p500-ui-icon-scissors-02:before {
  content: "\ea75";
}
.p500-ui-icon-scissors-cut-01:before {
  content: "\ea76";
}
.p500-ui-icon-scissors-cut-02:before {
  content: "\ea77";
}
.p500-ui-icon-skew:before {
  content: "\ea78";
}
.p500-ui-icon-sticker-square:before {
  content: "\ea79";
}
.p500-ui-icon-subscript:before {
  content: "\ea7a";
}
.p500-ui-icon-text-input:before {
  content: "\ea7b";
}
.p500-ui-icon-transform:before {
  content: "\ea7c";
}
.p500-ui-icon-type-square:before {
  content: "\ea7d";
}
.p500-ui-icon-airplay:before {
  content: "\ea7e";
}
.p500-ui-icon-airpods:before {
  content: "\ea7f";
}
.p500-ui-icon-align-center:before {
  content: "\ea80";
}
.p500-ui-icon-align-justify:before {
  content: "\ea81";
}
.p500-ui-icon-align-left:before {
  content: "\ea82";
}
.p500-ui-icon-align-right:before {
  content: "\ea83";
}
.p500-ui-icon-announcement-01:before {
  content: "\ea84";
}
.p500-ui-icon-announcement-02:before {
  content: "\ea85";
}
.p500-ui-icon-announcement-03:before {
  content: "\ea86";
}
.p500-ui-icon-archive:before {
  content: "\ea87";
}
.p500-ui-icon-battery-charging-01:before {
  content: "\ea88";
}
.p500-ui-icon-battery-charging-02:before {
  content: "\ea89";
}
.p500-ui-icon-battery-empty:before {
  content: "\ea8a";
}
.p500-ui-icon-battery-full:before {
  content: "\ea8b";
}
.p500-ui-icon-battery-low:before {
  content: "\ea8c";
}
.p500-ui-icon-battery-mid:before {
  content: "\ea8d";
}
.p500-ui-icon-bluetooth-connect:before {
  content: "\ea8e";
}
.p500-ui-icon-bluetooth-off:before {
  content: "\ea8f";
}
.p500-ui-icon-bluetooth-on:before {
  content: "\ea90";
}
.p500-ui-icon-bluetooth-signal:before {
  content: "\ea91";
}
.p500-ui-icon-brackets:before {
  content: "\ea92";
}
.p500-ui-icon-brackets-check:before {
  content: "\ea93";
}
.p500-ui-icon-brackets-ellipses:before {
  content: "\ea94";
}
.p500-ui-icon-brackets-minus:before {
  content: "\ea95";
}
.p500-ui-icon-brackets-plus:before {
  content: "\ea96";
}
.p500-ui-icon-brackets-slash:before {
  content: "\ea97";
}
.p500-ui-icon-brackets-x:before {
  content: "\ea98";
}
.p500-ui-icon-chrome-cast:before {
  content: "\ea9a";
}
.p500-ui-icon-code-01:before {
  content: "\ea9b";
}
.p500-ui-icon-code-browser:before {
  content: "\ea9c";
}
.p500-ui-icon-codepen:before {
  content: "\ea9d";
}
.p500-ui-icon-container:before {
  content: "\ea9e";
}
.p500-ui-icon-cpu-chip-01:before {
  content: "\ea9f";
}
.p500-ui-icon-cpu-chip-02:before {
  content: "\eaa0";
}
.p500-ui-icon-cube-02:before {
  content: "\eaa1";
}
.p500-ui-icon-cube-outline:before {
  content: "\eaa2";
}
.p500-ui-icon-cursor-01:before {
  content: "\eaa3";
}
.p500-ui-icon-cursor-02:before {
  content: "\eaa4";
}
.p500-ui-icon-cursor-03:before {
  content: "\eaa5";
}
.p500-ui-icon-cursor-04:before {
  content: "\eaa6";
}
.p500-ui-icon-database-01:before {
  content: "\eaa7";
}
.p500-ui-icon-database-03:before {
  content: "\eaa8";
}
.p500-ui-icon-dataflow-01:before {
  content: "\eaa9";
}
.p500-ui-icon-dataflow-02:before {
  content: "\eaaa";
}
.p500-ui-icon-dataflow-04:before {
  content: "\eaab";
}
.p500-ui-icon-dice-2:before {
  content: "\eaac";
}
.p500-ui-icon-dice-3:before {
  content: "\eaad";
}
.p500-ui-icon-dice-4:before {
  content: "\eaae";
}
.p500-ui-icon-dice-5:before {
  content: "\eaaf";
}
.p500-ui-icon-dice-6:before {
  content: "\eab0";
}
.p500-ui-icon-disc-01:before {
  content: "\eab1";
}
.p500-ui-icon-disc-02:before {
  content: "\eab2";
}
.p500-ui-icon-distribute-spacing-horizontal:before {
  content: "\eab3";
}
.p500-ui-icon-distribute-spacing-vertical:before {
  content: "\eab4";
}
.p500-ui-icon-face-id:before {
  content: "\eab5";
}
.p500-ui-icon-face-id-square:before {
  content: "\eab6";
}
.p500-ui-icon-fast-backward:before {
  content: "\eab7";
}
.p500-ui-icon-fast-forward:before {
  content: "\eab8";
}
.p500-ui-icon-film-01:before {
  content: "\eab9";
}
.p500-ui-icon-film-02:before {
  content: "\eaba";
}
.p500-ui-icon-film-03:before {
  content: "\eabb";
}
.p500-ui-icon-fingerprint-01:before {
  content: "\eabc";
}
.p500-ui-icon-fingerprint-02:before {
  content: "\eabd";
}
.p500-ui-icon-fingerprint-03:before {
  content: "\eabe";
}
.p500-ui-icon-fingerprint-04:before {
  content: "\eabf";
}
.p500-ui-icon-gaming-pad-01:before {
  content: "\eac0";
}
.p500-ui-icon-gaming-pad-02:before {
  content: "\eac1";
}
.p500-ui-icon-git-branch-01:before {
  content: "\eac2";
}
.p500-ui-icon-git-branch-01-1:before {
  content: "\eac3";
}
.p500-ui-icon-git-branch-02:before {
  content: "\eac4";
}
.p500-ui-icon-git-commit:before {
  content: "\eac5";
}
.p500-ui-icon-git-merge:before {
  content: "\eac6";
}
.p500-ui-icon-git-pull-request:before {
  content: "\eac7";
}
.p500-ui-icon-grid-02:before {
  content: "\eac8";
}
.p500-ui-icon-grid-03:before {
  content: "\eac9";
}
.p500-ui-icon-headphones-01:before {
  content: "\eaca";
}
.p500-ui-icon-key-01:before {
  content: "\eacb";
}
.p500-ui-icon-keyboard-01:before {
  content: "\eacc";
}
.p500-ui-icon-keyboard-02:before {
  content: "\eacd";
}
.p500-ui-icon-laptop-01:before {
  content: "\eace";
}
.p500-ui-icon-laptop-02:before {
  content: "\eacf";
}
.p500-ui-icon-letter-spacing-01:before {
  content: "\ead0";
}
.p500-ui-icon-lightbulb-01:before {
  content: "\ead1";
}
.p500-ui-icon-lightbulb-02:before {
  content: "\ead2";
}
.p500-ui-icon-lightbulb-03:before {
  content: "\ead3";
}
.p500-ui-icon-lightbulb-04:before {
  content: "\ead4";
}
.p500-ui-icon-lightbulb-05:before {
  content: "\ead5";
}
.p500-ui-icon-line-height:before {
  content: "\ead6";
}
.p500-ui-icon-lock-01:before {
  content: "\ead7";
}
.p500-ui-icon-lock-02:before {
  content: "\ead8";
}
.p500-ui-icon-lock-03:before {
  content: "\ead9";
}
.p500-ui-icon-lock-04:before {
  content: "\eada";
}
.p500-ui-icon-lock-unlocked-01:before {
  content: "\eadb";
}
.p500-ui-icon-lock-unlocked-02:before {
  content: "\eadc";
}
.p500-ui-icon-lock-unlocked-03:before {
  content: "\eadd";
}
.p500-ui-icon-lock-unlocked-04:before {
  content: "\eade";
}
.p500-ui-icon-maximize-02:before {
  content: "\eadf";
}
.p500-ui-icon-microphone-01:before {
  content: "\eae0";
}
.p500-ui-icon-microphone-02:before {
  content: "\eae1";
}
.p500-ui-icon-microphone-off-01:before {
  content: "\eae2";
}
.p500-ui-icon-microphone-off-02:before {
  content: "\eae3";
}
.p500-ui-icon-minimize-02:before {
  content: "\eae4";
}
.p500-ui-icon-modem-01:before {
  content: "\eae5";
}
.p500-ui-icon-modem-02:before {
  content: "\eae6";
}
.p500-ui-icon-monitor-01:before {
  content: "\eae7";
}
.p500-ui-icon-monitor-02:before {
  content: "\ec50";
}
.p500-ui-icon-monitor-03:before {
  content: "\ec51";
}
.p500-ui-icon-monitor-04:before {
  content: "\ec52";
}
.p500-ui-icon-monitor-05:before {
  content: "\eae8";
}
.p500-ui-icon-mouse:before {
  content: "\eae9";
}
.p500-ui-icon-music-note-01:before {
  content: "\eaea";
}
.p500-ui-icon-music-note-02:before {
  content: "\eaeb";
}
.p500-ui-icon-music-note-plus:before {
  content: "\eaec";
}
.p500-ui-icon-navigation-pointer-01:before {
  content: "\eaed";
}
.p500-ui-icon-navigation-pointer-02:before {
  content: "\eaee";
}
.p500-ui-icon-navigation-pointer-off-01:before {
  content: "\eaef";
}
.p500-ui-icon-navigation-pointer-off-02:before {
  content: "\eaf0";
}
.p500-ui-icon-package:before {
  content: "\eaf1";
}
.p500-ui-icon-package-check:before {
  content: "\eaf2";
}
.p500-ui-icon-package-minus:before {
  content: "\eaf3";
}
.p500-ui-icon-package-plus:before {
  content: "\eaf4";
}
.p500-ui-icon-package-search:before {
  content: "\eaf5";
}
.p500-ui-icon-package-x:before {
  content: "\eaf6";
}
.p500-ui-icon-passcode:before {
  content: "\eaf7";
}
.p500-ui-icon-phone-01:before {
  content: "\eaf8";
}
.p500-ui-icon-phone-02:before {
  content: "\eaf9";
}
.p500-ui-icon-podcast:before {
  content: "\eafa";
}
.p500-ui-icon-printer:before {
  content: "\eafb";
}
.p500-ui-icon-puzzle-piece-02:before {
  content: "\eafc";
}
.p500-ui-icon-qr-code-01:before {
  content: "\eafd";
}
.p500-ui-icon-qr-code-02:before {
  content: "\eafe";
}
.p500-ui-icon-recording-01:before {
  content: "\eaff";
}
.p500-ui-icon-recording-02:before {
  content: "\eb00";
}
.p500-ui-icon-rss-01:before {
  content: "\eb01";
}
.p500-ui-icon-rss-02:before {
  content: "\eb02";
}
.p500-ui-icon-scan:before {
  content: "\eb03";
}
.p500-ui-icon-server-02:before {
  content: "\eb04";
}
.p500-ui-icon-server-03:before {
  content: "\eb05";
}
.p500-ui-icon-server-04:before {
  content: "\eb06";
}
.p500-ui-icon-server-05:before {
  content: "\eb07";
}
.p500-ui-icon-server-06:before {
  content: "\eb08";
}
.p500-ui-icon-shield-01:before {
  content: "\eb09";
}
.p500-ui-icon-shield-02:before {
  content: "\eb0a";
}
.p500-ui-icon-shield-dollar:before {
  content: "\eb0b";
}
.p500-ui-icon-shield-off:before {
  content: "\eb0c";
}
.p500-ui-icon-shield-plus:before {
  content: "\eb0d";
}
.p500-ui-icon-shield-zap:before {
  content: "\eb0e";
}
.p500-ui-icon-signal-01:before {
  content: "\eb0f";
}
.p500-ui-icon-signal-02:before {
  content: "\eb10";
}
.p500-ui-icon-signal-03:before {
  content: "\eb11";
}
.p500-ui-icon-skip-back:before {
  content: "\eb12";
}
.p500-ui-icon-skip-forward:before {
  content: "\eb13";
}
.p500-ui-icon-sliders-01:before {
  content: "\eb14";
}
.p500-ui-icon-sliders-02:before {
  content: "\eb15";
}
.p500-ui-icon-sliders-04:before {
  content: "\eb16";
}
.p500-ui-icon-spacing-height-01:before {
  content: "\eb17";
}
.p500-ui-icon-spacing-height-02:before {
  content: "\eb18";
}
.p500-ui-icon-spacing-width-01:before {
  content: "\eb19";
}
.p500-ui-icon-spacing-width-02:before {
  content: "\eb1a";
}
.p500-ui-icon-speaker-01:before {
  content: "\eb1b";
}
.p500-ui-icon-speaker-02:before {
  content: "\eb1c";
}
.p500-ui-icon-speaker-03:before {
  content: "\eb1d";
}
.p500-ui-icon-square:before {
  content: "\eb1e";
}
.p500-ui-icon-star-02:before {
  content: "\eb1f";
}
.p500-ui-icon-star-03:before {
  content: "\eb20";
}
.p500-ui-icon-star-04:before {
  content: "\eb21";
}
.p500-ui-icon-star-05:before {
  content: "\eb22";
}
.p500-ui-icon-star-06:before {
  content: "\eb23";
}
.p500-ui-icon-tablet-01:before {
  content: "\eb24";
}
.p500-ui-icon-tablet-02:before {
  content: "\eb25";
}
.p500-ui-icon-terminal:before {
  content: "\eb26";
}
.p500-ui-icon-terminal-browser:before {
  content: "\eb27";
}
.p500-ui-icon-thumbs-down:before {
  content: "\eb28";
}
.p500-ui-icon-thumbs-up:before {
  content: "\eb29";
}
.p500-ui-icon-tv-01:before {
  content: "\eb2a";
}
.p500-ui-icon-tv-02:before {
  content: "\eb2b";
}
.p500-ui-icon-tv-03:before {
  content: "\eb2c";
}
.p500-ui-icon-video-recorder:before {
  content: "\eb2d";
}
.p500-ui-icon-video-recorder-off:before {
  content: "\eb2e";
}
.p500-ui-icon-volume-max:before {
  content: "\eb2f";
}
.p500-ui-icon-volume-min:before {
  content: "\eb30";
}
.p500-ui-icon-volume-minus:before {
  content: "\eb31";
}
.p500-ui-icon-volume-plus:before {
  content: "\eb32";
}
.p500-ui-icon-volume-x:before {
  content: "\eb33";
}
.p500-ui-icon-webcam-01:before {
  content: "\eb34";
}
.p500-ui-icon-webcam-02:before {
  content: "\eb36";
}
.p500-ui-icon-wifi:before {
  content: "\eb38";
}
.p500-ui-icon-wifi-off:before {
  content: "\eb3a";
}
.p500-ui-icon-align-bottom-02:before {
  content: "\ec53";
}
.p500-ui-icon-align-horizontal-centre-02:before {
  content: "\ec54";
}
.p500-ui-icon-align-left-02:before {
  content: "\ec55";
}
.p500-ui-icon-align-right-02:before {
  content: "\ec56";
}
.p500-ui-icon-align-top-arrow-02:before {
  content: "\ec57";
}
.p500-ui-icon-align-vertical-center-02:before {
  content: "\ec58";
}
.p500-ui-icon-annotation:before {
  content: "\ec59";
}
.p500-ui-icon-annotation-alert:before {
  content: "\ec5a";
}
.p500-ui-icon-annotation-check:before {
  content: "\ec5b";
}
.p500-ui-icon-annotation-dots:before {
  content: "\ec5c";
}
.p500-ui-icon-annotation-heart:before {
  content: "\ec5d";
}
.p500-ui-icon-annotation-info:before {
  content: "\ec5e";
}
.p500-ui-icon-annotation-plus:before {
  content: "\ec5f";
}
.p500-ui-icon-annotation-question:before {
  content: "\ec60";
}
.p500-ui-icon-annotation-x:before {
  content: "\ec61";
}
.p500-ui-icon-bell-01:before {
  content: "\ec62";
}
.p500-ui-icon-bell-02:before {
  content: "\ec63";
}
.p500-ui-icon-bell-04:before {
  content: "\ec64";
}
.p500-ui-icon-bell-minus:before {
  content: "\ec65";
}
.p500-ui-icon-bell-off-01:before {
  content: "\ec66";
}
.p500-ui-icon-bell-off-02:before {
  content: "\ec67";
}
.p500-ui-icon-bell-off-03:before {
  content: "\ec68";
}
.p500-ui-icon-bell-plus:before {
  content: "\ec69";
}
.p500-ui-icon-bell-ringing-01:before {
  content: "\ec6a";
}
.p500-ui-icon-bell-ringing-02:before {
  content: "\ec6b";
}
.p500-ui-icon-bell-ringing-03:before {
  content: "\ec6c";
}
.p500-ui-icon-bell-ringing-04:before {
  content: "\ec6d";
}
.p500-ui-icon-check-read:before {
  content: "\ec6e";
}
.p500-ui-icon-cloud-blank-02:before {
  content: "\ec6f";
}
.p500-ui-icon-columns-01:before {
  content: "\ec70";
}
.p500-ui-icon-columns-02:before {
  content: "\ec71";
}
.p500-ui-icon-columns-03:before {
  content: "\ec72";
}
.p500-ui-icon-copy-01:before {
  content: "\ec73";
}
.p500-ui-icon-copy-02:before {
  content: "\ec74";
}
.p500-ui-icon-copy-04:before {
  content: "\ec75";
}
.p500-ui-icon-copy-05:before {
  content: "\ec76";
}
.p500-ui-icon-copy-06:before {
  content: "\ec77";
}
.p500-ui-icon-copy-07:before {
  content: "\ec78";
}
.p500-ui-icon-flash-off:before {
  content: "\ec79";
}
.p500-ui-icon-flex-align-bottom:before {
  content: "\ec7a";
}
.p500-ui-icon-flex-align-left:before {
  content: "\ec7b";
}
.p500-ui-icon-flex-align-right:before {
  content: "\ec7c";
}
.p500-ui-icon-flex-align-top:before {
  content: "\ec7d";
}
.p500-ui-icon-grid-dots-blank:before {
  content: "\ec7e";
}
.p500-ui-icon-grid-dots-bottom:before {
  content: "\ec7f";
}
.p500-ui-icon-grid-dots-horizontal-center:before {
  content: "\ec80";
}
.p500-ui-icon-grid-dots-left:before {
  content: "\ec81";
}
.p500-ui-icon-grid-dots-outer:before {
  content: "\ec82";
}
.p500-ui-icon-grid-dots-right:before {
  content: "\ec83";
}
.p500-ui-icon-grid-dots-top:before {
  content: "\ec84";
}
.p500-ui-icon-grid-dots-vertical-center:before {
  content: "\ec85";
}
.p500-ui-icon-home-01:before {
  content: "\ec86";
}
.p500-ui-icon-home-03:before {
  content: "\ec87";
}
.p500-ui-icon-home-04:before {
  content: "\ec88";
}
.p500-ui-icon-home-05:before {
  content: "\ec89";
}
.p500-ui-icon-home-line:before {
  content: "\ec8a";
}
.p500-ui-icon-home-smile:before {
  content: "\ec8b";
}
.p500-ui-icon-inbox-01:before {
  content: "\ec8c";
}
.p500-ui-icon-inbox-02:before {
  content: "\ec8d";
}
.p500-ui-icon-layer-single:before {
  content: "\ec8e";
}
.p500-ui-icon-layers-three-01:before {
  content: "\ec8f";
}
.p500-ui-icon-layers-two-01:before {
  content: "\ec90";
}
.p500-ui-icon-layers-two-02:before {
  content: "\ec91";
}
.p500-ui-icon-layout-alt-02:before {
  content: "\ec92";
}
.p500-ui-icon-layout-alt-03:before {
  content: "\ec93";
}
.p500-ui-icon-layout-alt-04:before {
  content: "\ec94";
}
.p500-ui-icon-layout-bottom:before {
  content: "\ec95";
}
.p500-ui-icon-layout-grid-01:before {
  content: "\ec96";
}
.p500-ui-icon-layout-grid-02:before {
  content: "\ec97";
}
.p500-ui-icon-layout-left:before {
  content: "\ec98";
}
.p500-ui-icon-layout-right:before {
  content: "\ec99";
}
.p500-ui-icon-layout-top:before {
  content: "\ec9a";
}
.p500-ui-icon-life-buoy-01:before {
  content: "\ec9b";
}
.p500-ui-icon-life-buoy-02:before {
  content: "\ec9c";
}
.p500-ui-icon-link-01:before {
  content: "\ec9d";
}
.p500-ui-icon-link-03:before {
  content: "\ec9e";
}
.p500-ui-icon-link-04:before {
  content: "\ec9f";
}
.p500-ui-icon-link-05:before {
  content: "\eca0";
}
.p500-ui-icon-link-broken-01:before {
  content: "\eca1";
}
.p500-ui-icon-link-broken-02:before {
  content: "\eca2";
}
.p500-ui-icon-mail-03:before {
  content: "\eca3";
}
.p500-ui-icon-mail-04:before {
  content: "\eca4";
}
.p500-ui-icon-mail-05:before {
  content: "\eca5";
}
.p500-ui-icon-message-alert-circle:before {
  content: "\eca6";
}
.p500-ui-icon-message-alert-square:before {
  content: "\eca7";
}
.p500-ui-icon-message-chat-circle:before {
  content: "\eca8";
}
.p500-ui-icon-message-check-circle:before {
  content: "\eca9";
}
.p500-ui-icon-message-check-square:before {
  content: "\ecaa";
}
.p500-ui-icon-message-circle-01:before {
  content: "\ecab";
}
.p500-ui-icon-message-circle-02:before {
  content: "\ecac";
}
.p500-ui-icon-message-dots-circle:before {
  content: "\ecad";
}
.p500-ui-icon-message-dots-square:before {
  content: "\ecae";
}
.p500-ui-icon-message-heart-circle:before {
  content: "\ecaf";
}
.p500-ui-icon-message-heart-square:before {
  content: "\ecb0";
}
.p500-ui-icon-message-notification-circle:before {
  content: "\ecb1";
}
.p500-ui-icon-message-notification-square:before {
  content: "\ecb2";
}
.p500-ui-icon-message-plus-circle:before {
  content: "\ecb3";
}
.p500-ui-icon-message-plus-square:before {
  content: "\ecb4";
}
.p500-ui-icon-message-question-circle:before {
  content: "\ecb5";
}
.p500-ui-icon-message-question-square:before {
  content: "\ecb6";
}
.p500-ui-icon-message-smile-circle:before {
  content: "\ecb7";
}
.p500-ui-icon-message-smile-square:before {
  content: "\ecb8";
}
.p500-ui-icon-message-square-01:before {
  content: "\ecb9";
}
.p500-ui-icon-message-square-02:before {
  content: "\ecba";
}
.p500-ui-icon-message-text-circle-01:before {
  content: "\ecbb";
}
.p500-ui-icon-message-text-circle-02:before {
  content: "\ecbc";
}
.p500-ui-icon-message-text-square-01:before {
  content: "\ecbd";
}
.p500-ui-icon-message-text-square-02:before {
  content: "\ecbe";
}
.p500-ui-icon-message-x-circle:before {
  content: "\ecbf";
}
.p500-ui-icon-message-x-square:before {
  content: "\ecc0";
}
.p500-ui-icon-notification-box:before {
  content: "\ecc1";
}
.p500-ui-icon-notification-text:before {
  content: "\ecc2";
}
.p500-ui-icon-phone:before {
  content: "\ecc3";
}
.p500-ui-icon-phone-call-02:before {
  content: "\ecc4";
}
.p500-ui-icon-phone-hang-up:before {
  content: "\ecc5";
}
.p500-ui-icon-phone-incoming-01:before {
  content: "\ecc6";
}
.p500-ui-icon-phone-incoming-02:before {
  content: "\ecc7";
}
.p500-ui-icon-phone-outgoing-01:before {
  content: "\ecc8";
}
.p500-ui-icon-phone-outgoing-02:before {
  content: "\ecc9";
}
.p500-ui-icon-phone-pause:before {
  content: "\ecca";
}
.p500-ui-icon-phone-plus:before {
  content: "\eccb";
}
.p500-ui-icon-phone-x:before {
  content: "\eccc";
}
.p500-ui-icon-rows-01:before {
  content: "\eccd";
}
.p500-ui-icon-rows-02:before {
  content: "\ecce";
}
.p500-ui-icon-rows-03:before {
  content: "\eccf";
}
.p500-ui-icon-save-01:before {
  content: "\ecd0";
}
.p500-ui-icon-save-02:before {
  content: "\ecd1";
}
.p500-ui-icon-save-03:before {
  content: "\ecd2";
}
.p500-ui-icon-search-lg:before {
  content: "\ecd3";
}
.p500-ui-icon-search-md:before {
  content: "\ecd4";
}
.p500-ui-icon-search-refraction:before {
  content: "\ecd5";
}
.p500-ui-icon-send-02:before {
  content: "\ecd6";
}
.p500-ui-icon-send-03:before {
  content: "\ecd7";
}
.p500-ui-icon-table:before {
  content: "\ecd8";
}
.p500-ui-icon-voicemail:before {
  content: "\ecd9";
}
.p500-ui-icon-zap:before {
  content: "\ecda";
}
.p500-ui-icon-zap-fast:before {
  content: "\ecdb";
}
.p500-ui-icon-activity-heart:before {
  content: "\ebb8";
}
.p500-ui-icon-check-done-01:before {
  content: "\ebba";
}
.p500-ui-icon-check-done-02:before {
  content: "\ebbc";
}
.p500-ui-icon-check-heart:before {
  content: "\ebbe";
}
.p500-ui-icon-check-square-broken:before {
  content: "\ebc0";
}
.p500-ui-icon-check-verified-01:before {
  content: "\ebc2";
}
.p500-ui-icon-check-verified-02:before {
  content: "\ebc4";
}
.p500-ui-icon-check-verified-03:before {
  content: "\ebc6";
}
.p500-ui-icon-edit-05:before {
  content: "\ebc8";
}
.p500-ui-icon-face-content:before {
  content: "\ebca";
}
.p500-ui-icon-face-frown:before {
  content: "\ebcc";
}
.p500-ui-icon-face-happy:before {
  content: "\ebce";
}
.p500-ui-icon-face-neutral:before {
  content: "\ebd0";
}
.p500-ui-icon-face-sad:before {
  content: "\ebd2";
}
.p500-ui-icon-face-smile:before {
  content: "\ebd4";
}
.p500-ui-icon-face-wink:before {
  content: "\ebd6";
}
.p500-ui-icon-hash-01:before {
  content: "\ebd8";
}
.p500-ui-icon-hash-02:before {
  content: "\ebda";
}
.p500-ui-icon-heart:before {
  content: "\ebdc";
}
.p500-ui-icon-heart-rounded:before {
  content: "\ebdd";
}
.p500-ui-icon-pin-01:before {
  content: "\ebdf";
}
.p500-ui-icon-pin-02:before {
  content: "\ebe1";
}
.p500-ui-icon-settings-03:before {
  content: "\ebe3";
}
.p500-ui-icon-toggle-01-left:before {
  content: "\ebe4";
}
.p500-ui-icon-toggle-01-right:before {
  content: "\ebe6";
}
.p500-ui-icon-toggle-02-left:before {
  content: "\ebe7";
}
.p500-ui-icon-toggle-02-right:before {
  content: "\ebe8";
}
.p500-ui-icon-toggle-03-left:before {
  content: "\ebe9";
}
.p500-ui-icon-toggle-03-right:before {
  content: "\ebea";
}
.p500-ui-icon-tool-01:before {
  content: "\ebeb";
}
.p500-ui-icon-tool-02:before {
  content: "\ebec";
}
.p500-ui-icon-translate-01:before {
  content: "\ebed";
}
.p500-ui-icon-translate-02:before {
  content: "\ebee";
}
.p500-ui-icon-trash-02:before {
  content: "\ebef";
}
.p500-ui-icon-trash-03:before {
  content: "\ebf0";
}
.p500-ui-icon-trash-04:before {
  content: "\ebf1";
}
.p500-ui-icon-user-01:before {
  content: "\ebf2";
}
.p500-ui-icon-user-03:before {
  content: "\ebf3";
}
.p500-ui-icon-user-check-01:before {
  content: "\ebf4";
}
.p500-ui-icon-user-check-02:before {
  content: "\ebf5";
}
.p500-ui-icon-user-down-01:before {
  content: "\ebf6";
}
.p500-ui-icon-user-down-02:before {
  content: "\ebf7";
}
.p500-ui-icon-user-edit:before {
  content: "\ebf8";
}
.p500-ui-icon-user-hr:before {
  content: "\ebf9";
}
.p500-ui-icon-user-left-01:before {
  content: "\ebfa";
}
.p500-ui-icon-user-left-02:before {
  content: "\ebfb";
}
.p500-ui-icon-user-minus-01:before {
  content: "\ebfc";
}
.p500-ui-icon-user-minus-02:before {
  content: "\ebfd";
}
.p500-ui-icon-user-plus-01:before {
  content: "\ebfe";
}
.p500-ui-icon-user-plus-02:before {
  content: "\ebff";
}
.p500-ui-icon-user-plus-02-1:before {
  content: "\ec00";
}
.p500-ui-icon-user-right-01:before {
  content: "\ec01";
}
.p500-ui-icon-user-right-02:before {
  content: "\ec02";
}
.p500-ui-icon-users-02:before {
  content: "\ec03";
}
.p500-ui-icon-users-03:before {
  content: "\ec04";
}
.p500-ui-icon-users-check:before {
  content: "\ec05";
}
.p500-ui-icon-users-down:before {
  content: "\ec06";
}
.p500-ui-icon-users-edit:before {
  content: "\ec07";
}
.p500-ui-icon-users-left:before {
  content: "\ec08";
}
.p500-ui-icon-users-minus:before {
  content: "\ec09";
}
.p500-ui-icon-users-plus:before {
  content: "\ec0a";
}
.p500-ui-icon-user-square:before {
  content: "\ec0b";
}
.p500-ui-icon-users-right:before {
  content: "\ec0c";
}
.p500-ui-icon-users-up:before {
  content: "\ec0d";
}
.p500-ui-icon-users-x:before {
  content: "\ec0e";
}
.p500-ui-icon-user-up-01:before {
  content: "\ec0f";
}
.p500-ui-icon-user-up-02:before {
  content: "\ec10";
}
.p500-ui-icon-user-x-01:before {
  content: "\ec11";
}
.p500-ui-icon-user-x-02:before {
  content: "\ec12";
}
.p500-ui-icon-alert-hexagon:before {
  content: "\ec13";
}
.p500-ui-icon-alert-octagon:before {
  content: "\ec14";
}
.p500-ui-icon-alert-square:before {
  content: "\ec15";
}
.p500-ui-icon-arrows-triangle:before {
  content: "\ec16";
}
.p500-ui-icon-asterisk-01:before {
  content: "\ec17";
}
.p500-ui-icon-asterisk-02:before {
  content: "\ec18";
}
.p500-ui-icon-bold-square:before {
  content: "\ec19";
}
.p500-ui-icon-bookmark:before {
  content: "\ec1a";
}
.p500-ui-icon-bookmark-add:before {
  content: "\ec1b";
}
.p500-ui-icon-bookmark-check:before {
  content: "\ec1c";
}
.p500-ui-icon-bookmark-minus:before {
  content: "\ec1d";
}
.p500-ui-icon-bookmark-x:before {
  content: "\ec1e";
}
.p500-ui-icon-building-01:before {
  content: "\ec1f";
}
.p500-ui-icon-building-03:before {
  content: "\ec20";
}
.p500-ui-icon-building-04:before {
  content: "\ec21";
}
.p500-ui-icon-building-05:before {
  content: "\ec22";
}
.p500-ui-icon-building-06:before {
  content: "\ec23";
}
.p500-ui-icon-calculator:before {
  content: "\ec24";
}
.p500-ui-icon-check-circle:before {
  content: "\ec25";
}
.p500-ui-icon-check-square:before {
  content: "\ec26";
}
.p500-ui-icon-circle:before {
  content: "\ec27";
}
.p500-ui-icon-code-circle-01:before {
  content: "\ec28";
}
.p500-ui-icon-code-circle-02:before {
  content: "\ec29";
}
.p500-ui-icon-code-circle-03:before {
  content: "\ec2a";
}
.p500-ui-icon-code-square-01:before {
  content: "\ec2b";
}
.p500-ui-icon-code-square-02:before {
  content: "\ec2c";
}
.p500-ui-icon-currency-bitcoin-circle:before {
  content: "\ec2d";
}
.p500-ui-icon-currency-ethereum-circle:before {
  content: "\ec2e";
}
.p500-ui-icon-currency-euro-circle:before {
  content: "\ec2f";
}
.p500-ui-icon-currency-pound-circle:before {
  content: "\ec30";
}
.p500-ui-icon-currency-ruble-circle:before {
  content: "\ec31";
}
.p500-ui-icon-currency-rupee-circle:before {
  content: "\ec32";
}
.p500-ui-icon-currency-yen-circle:before {
  content: "\ec33";
}
.p500-ui-icon-divide-01:before {
  content: "\ec34";
}
.p500-ui-icon-divide-02:before {
  content: "\ec35";
}
.p500-ui-icon-divide-03:before {
  content: "\ec36";
}
.p500-ui-icon-dots-grid:before {
  content: "\ec37";
}
.p500-ui-icon-dots-vertical:before {
  content: "\ec38";
}
.p500-ui-icon-download-cloud-02:before {
  content: "\ec39";
}
.p500-ui-icon-edit-01:before {
  content: "\ec3a";
}
.p500-ui-icon-edit-03:before {
  content: "\ec3b";
}
.p500-ui-icon-edit-04:before {
  content: "\ec3c";
}
.p500-ui-icon-equal-not:before {
  content: "\ec3d";
}
.p500-ui-icon-eye-off:before {
  content: "\ec3e";
}
.p500-ui-icon-heading-square:before {
  content: "\ec3f";
}
.p500-ui-icon-heart-circle:before {
  content: "\ecdd";
}
.p500-ui-icon-heart-hexagon:before {
  content: "\ecde";
}
.p500-ui-icon-heart-octagon:before {
  content: "\ecdf";
}
.p500-ui-icon-heart-square:before {
  content: "\ece0";
}
.p500-ui-icon-help-hexagon:before {
  content: "\ece1";
}
.p500-ui-icon-help-octagon:before {
  content: "\ece2";
}
.p500-ui-icon-help-square:before {
  content: "\ece3";
}
.p500-ui-icon-hexagon-01:before {
  content: "\ece4";
}
.p500-ui-icon-image-04:before {
  content: "\ece5";
}
.p500-ui-icon-info-hexagon:before {
  content: "\ece6";
}
.p500-ui-icon-info-octagon:before {
  content: "\ece7";
}
.p500-ui-icon-info-square:before {
  content: "\ece8";
}
.p500-ui-icon-loading-01:before {
  content: "\ece9";
}
.p500-ui-icon-loading-02:before {
  content: "\ecea";
}
.p500-ui-icon-lock-keyhole-circle:before {
  content: "\eceb";
}
.p500-ui-icon-lock-keyhole-square:before {
  content: "\ecec";
}
.p500-ui-icon-medical-circle:before {
  content: "\eced";
}
.p500-ui-icon-medical-square:before {
  content: "\ecee";
}
.p500-ui-icon-menu-01:before {
  content: "\ecef";
}
.p500-ui-icon-menu-02:before {
  content: "\ecf0";
}
.p500-ui-icon-menu-03:before {
  content: "\ecf1";
}
.p500-ui-icon-menu-04:before {
  content: "\ecf2";
}
.p500-ui-icon-minus-circle:before {
  content: "\ecf3";
}
.p500-ui-icon-minus-square:before {
  content: "\ecf4";
}
.p500-ui-icon-octagon:before {
  content: "\ecf5";
}
.p500-ui-icon-pause-circle:before {
  content: "\ecf6";
}
.p500-ui-icon-pause-square:before {
  content: "\ecf7";
}
.p500-ui-icon-percent-01:before {
  content: "\ecf8";
}
.p500-ui-icon-percent-02:before {
  content: "\ecf9";
}
.p500-ui-icon-percent-03:before {
  content: "\ecfa";
}
.p500-ui-icon-pilcrow-square:before {
  content: "\ecfb";
}
.p500-ui-icon-play-square:before {
  content: "\ecfc";
}
.p500-ui-icon-plus-circle:before {
  content: "\ecfd";
}
.p500-ui-icon-plus-square:before {
  content: "\ecfe";
}
.p500-ui-icon-power-02:before {
  content: "\ecff";
}
.p500-ui-icon-power-03:before {
  content: "\ed00";
}
.p500-ui-icon-recording-03:before {
  content: "\ed01";
}
.p500-ui-icon-repeat-01:before {
  content: "\ed02";
}
.p500-ui-icon-repeat-02:before {
  content: "\ed03";
}
.p500-ui-icon-repeat-03:before {
  content: "\ed04";
}
.p500-ui-icon-sale-01:before {
  content: "\ed05";
}
.p500-ui-icon-slash-circle-02:before {
  content: "\ed06";
}
.p500-ui-icon-slash-divider:before {
  content: "\ed07";
}
.p500-ui-icon-slash-octagon:before {
  content: "\ed08";
}
.p500-ui-icon-speedometer-01:before {
  content: "\ed09";
}
.p500-ui-icon-speedometer-02:before {
  content: "\ed0a";
}
.p500-ui-icon-speedometer-03:before {
  content: "\ed0b";
}
.p500-ui-icon-speedometer-04:before {
  content: "\ed0c";
}
.p500-ui-icon-stop:before {
  content: "\ed0d";
}
.p500-ui-icon-stop-square:before {
  content: "\ed0e";
}
.p500-ui-icon-strikethrough-square:before {
  content: "\ed0f";
}
.p500-ui-icon-target-01:before {
  content: "\ed10";
}
.p500-ui-icon-target-03:before {
  content: "\ed11";
}
.p500-ui-icon-terminal-circle:before {
  content: "\ed12";
}
.p500-ui-icon-terminal-square:before {
  content: "\ed13";
}
.p500-ui-icon-triangle:before {
  content: "\ed14";
}
.p500-ui-icon-underline-square:before {
  content: "\ed15";
}
.p500-ui-icon-upload-cloud-01:before {
  content: "\ed16";
}
.p500-ui-icon-upload-cloud-02:before {
  content: "\ed17";
}
.p500-ui-icon-x-circle:before {
  content: "\ed18";
}
.p500-ui-icon-x-square:before {
  content: "\ed19";
}
.p500-ui-icon-zap-circle:before {
  content: "\ed1a";
}
.p500-ui-icon-zap-square:before {
  content: "\ed1b";
}
.p500-ui-icon-align-horizontal-centre-01:before {
  content: "\ed1c";
}
.p500-ui-icon-align-left-01:before {
  content: "\ed1d";
}
.p500-ui-icon-align-right-01:before {
  content: "\ed1e";
}
.p500-ui-icon-align-top-arrow-01:before {
  content: "\ed1f";
}
.p500-ui-icon-align-vertical-center-01:before {
  content: "\ed20";
}
.p500-ui-icon-arrow-block-down:before {
  content: "\ed21";
}
.p500-ui-icon-arrow-block-left:before {
  content: "\ed22";
}
.p500-ui-icon-arrow-block-right:before {
  content: "\ed23";
}
.p500-ui-icon-arrow-block-up:before {
  content: "\ed24";
}
.p500-ui-icon-arrow-circle-broken-down:before {
  content: "\ed25";
}
.p500-ui-icon-arrow-circle-broken-down-left:before {
  content: "\ed26";
}
.p500-ui-icon-arrow-circle-broken-down-right:before {
  content: "\ed27";
}
.p500-ui-icon-arrow-circle-broken-left:before {
  content: "\ed28";
}
.p500-ui-icon-arrow-circle-broken-right:before {
  content: "\ed29";
}
.p500-ui-icon-arrow-circle-broken-up:before {
  content: "\ed2a";
}
.p500-ui-icon-arrow-circle-broken-up-left:before {
  content: "\ed2b";
}
.p500-ui-icon-arrow-circle-broken-up-right:before {
  content: "\ed2c";
}
.p500-ui-icon-arrow-circle-down-left:before {
  content: "\ed2d";
}
.p500-ui-icon-arrow-circle-down-right:before {
  content: "\ed2e";
}
.p500-ui-icon-arrow-circle-up-left:before {
  content: "\ed2f";
}
.p500-ui-icon-arrow-circle-up-right:before {
  content: "\ed30";
}
.p500-ui-icon-arrow-down-1:before {
  content: "\ed31";
}
.p500-ui-icon-arrow-down-left:before {
  content: "\ed32";
}
.p500-ui-icon-arrow-down-right:before {
  content: "\ed33";
}
.p500-ui-icon-arrow-left-1:before {
  content: "\ed34";
}
.p500-ui-icon-arrow-narrow-down-left:before {
  content: "\ed35";
}
.p500-ui-icon-arrow-narrow-down-right:before {
  content: "\ed36";
}
.p500-ui-icon-arrow-narrow-up-left:before {
  content: "\ed37";
}
.p500-ui-icon-arrow-narrow-up-right:before {
  content: "\ed38";
}
.p500-ui-icon-arrow-right-1:before {
  content: "\ed39";
}
.p500-ui-icon-arrows-down-2:before {
  content: "\ed3a";
}
.p500-ui-icon-arrows-left-2:before {
  content: "\ed3b";
}
.p500-ui-icon-arrow-square-down:before {
  content: "\ed3c";
}
.p500-ui-icon-arrow-square-down-left:before {
  content: "\ed3d";
}
.p500-ui-icon-arrow-square-down-right:before {
  content: "\ed3e";
}
.p500-ui-icon-arrow-square-left:before {
  content: "\ed3f";
}
.p500-ui-icon-arrow-square-right:before {
  content: "\ed40";
}
.p500-ui-icon-arrow-square-up:before {
  content: "\ed41";
}
.p500-ui-icon-arrow-square-up-left:before {
  content: "\ed42";
}
.p500-ui-icon-arrow-square-up-right:before {
  content: "\ed43";
}
.p500-ui-icon-arrows-right-2:before {
  content: "\ed44";
}
.p500-ui-icon-arrows-up-2:before {
  content: "\ed45";
}
.p500-ui-icon-arrow-up-1:before {
  content: "\ed46";
}
.p500-ui-icon-arrow-up-left:before {
  content: "\ed47";
}
.p500-ui-icon-arrow-up-right:before {
  content: "\ed48";
}
.p500-ui-icon-chevron-down-double:before {
  content: "\ed49";
}
.p500-ui-icon-chevron-left-double:before {
  content: "\ed4a";
}
.p500-ui-icon-chevron-right-double:before {
  content: "\ed4b";
}
.p500-ui-icon-chevron-selector-horizontal:before {
  content: "\ed4c";
}
.p500-ui-icon-chevron-selector-vertical:before {
  content: "\ed4d";
}
.p500-ui-icon-chevron-up-double:before {
  content: "\ed4e";
}
.p500-ui-icon-corner-down-left:before {
  content: "\ed4f";
}
.p500-ui-icon-corner-down-right:before {
  content: "\ed50";
}
.p500-ui-icon-corner-left-down:before {
  content: "\ed51";
}
.p500-ui-icon-corner-left-up:before {
  content: "\ed52";
}
.p500-ui-icon-corner-right-down:before {
  content: "\ed53";
}
.p500-ui-icon-corner-right-up:before {
  content: "\ed54";
}
.p500-ui-icon-corner-up-left:before {
  content: "\ed55";
}
.p500-ui-icon-corner-up-right:before {
  content: "\ed56";
}
.p500-ui-icon-expand-02:before {
  content: "\ed57";
}
.p500-ui-icon-expand-03:before {
  content: "\ed58";
}
.p500-ui-icon-expand-05:before {
  content: "\ed59";
}
.p500-ui-icon-flip-backward:before {
  content: "\ed5a";
}
.p500-ui-icon-flip-forward:before {
  content: "\ed5b";
}
.p500-ui-icon-log-in-01:before {
  content: "\ed5c";
}
.p500-ui-icon-log-out-04:before {
  content: "\ed5d";
}
.p500-ui-icon-refresh-ccw-01:before {
  content: "\ed5e";
}
.p500-ui-icon-refresh-ccw-02:before {
  content: "\ed5f";
}
.p500-ui-icon-refresh-ccw-03:before {
  content: "\ed60";
}
.p500-ui-icon-refresh-ccw-04:before {
  content: "\ed61";
}
.p500-ui-icon-refresh-ccw-05:before {
  content: "\ed62";
}
.p500-ui-icon-refresh-cw-02:before {
  content: "\ed63";
}
.p500-ui-icon-refresh-cw-03:before {
  content: "\ed64";
}
.p500-ui-icon-refresh-cw-04:before {
  content: "\ed65";
}
.p500-ui-icon-refresh-cw-05:before {
  content: "\ed66";
}
.p500-ui-icon-reverse-left:before {
  content: "\ed67";
}
.p500-ui-icon-reverse-right:before {
  content: "\ed68";
}
.p500-ui-icon-share-05:before {
  content: "\ed69";
}
.p500-ui-icon-share-06:before {
  content: "\ed6a";
}
.p500-ui-icon-shuffle-02:before {
  content: "\ed6b";
}
.p500-ui-icon-switch-horizontal-02:before {
  content: "\ed6c";
}
.p500-ui-icon-switch-vertical-01:before {
  content: "\ed6d";
}
.p500-ui-icon-switch-vertical-02:before {
  content: "\ed6e";
}
.p500-ui-icon-upload-01:before {
  content: "\ed6f";
}
.p500-ui-icon-upload-02:before {
  content: "\ed70";
}
.p500-ui-icon-upload-04:before {
  content: "\ed71";
}
