&__table {
  background-color: $g-color-white;
  border-radius: $g-value-border-radius-xl;
  position: relative;

  .-align-right {
    text-align: right;
    justify-content: flex-end;
  }

  table {
    text-align: left;
    border-collapse: separate;
    border-spacing: 0;
    box-sizing: border-box;
    width: 100%;
    position: relative;
    white-space: nowrap;

    #{$root} {
      &__empty {
        color: rgba($g-color-secondary, 0.5);
      }
    }

    &.-adaptive-width {
      min-width: initial;
    }

    th,
    td {
      border-right: 1px solid rgba(230, 230, 230, 0.6);

      &:last-child {
        border-right: none;
      }
    }

    thead {
      border-radius: $g-value-border-radius-xl;

      th {
        @include font-headline-4;
        border-left: none;
        background: $g-color-light-blue;
        border-bottom: 1px solid $g-color-gray;
        padding: 8px 10px;

        &:first-child {
          border-top-left-radius: 8px;
        }

        &:last-child {
          border-top-right-radius: 8px;
        }
      }
    }

    tr {
      td {
        padding: 8px 10px;
        border-left: none;
        border-bottom: 1px solid hsla(0, 0%, 90.2%, 0.4);
        transition: background-color 0.15s;

        &.text-top {
          vertical-align: top;
        }

        .--tree-payload {
          max-width: 400px;
          overflow: hidden;
        }
      }

      &:last-child {
        td {
          border-bottom: none;
        }
      }

      &:nth-child(even) {
        background-color: rgba(0, 14, 75, 0.02);
      }

      &:hover {
        background-color: $g-color-table-hover;
      }
    }
  }

  &--invoice {
    table tr:nth-child(even) {
      background-color: transparent;
    }
  }

  &-earning {
    gtd-ui-field-default-money {
      .-red {
        color: $g-color-black;
      }
    }
    .p500-ui-form-field.p500-ui-form-field--slide-toggle {
      .p500-ui-form-field__button.-active-earning-green {
        border-color: $g-color-turquoise;
        background-color: $g-color-turquoise;
      }
    }
  }

  // can be removed
  table tr.c-widget__table--header {
    background-color: rgba(0, 14, 75, 0.02);

    td {
      font-weight: bold;
    }
  }

  @import 'c-widget__table--dashboard';
}
