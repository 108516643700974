.c-tags {
  $root: &;

  &__inner {
    display: flex;
    justify-content: space-between;
  }

  &__tag {
    @include font-headline-5;
    display: inline-block;
    border-radius: 5px;
    margin-right: 4px;
    padding: 0 6px;
  }

  &--primary {
    #{$root} {
      &__tag {
        color: $g-color-primary;
        background-color: $g-color-light-blue;
      }
    }
  }

  &--gray {
    #{$root} {
      &__tag {
        color: rgba($g-color-secondary, 0.5);
        background-color: rgba($g-color-secondary, 0.05);
      }
    }
  }

  &--secondary {
    #{$root} {
      &__tag {
        margin-bottom: 5px;
        color: $g-color-secondary;
        background-color: rgba($g-color-secondary, 0.08);
      }
    }
  }
}
