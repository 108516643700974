.c-term {
  display: flex;
  padding: 10px 0;
  background-color: $g-color-white;
  border-radius: $g-value-border-radius-xl;
  cursor: grab;

  @include media-breakpoint-down(sm) {
    cursor: default;
  }

  &.-drag-disabled {
    cursor: default;
  }

  &__order-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgba($g-color-secondary, 0.2);
    padding-right: 14px;
    padding-left: 6px;

    &.-invisible {
      opacity: 0;

      .c-term__order-down,
      .c-term__order-up {
        cursor: default;
      }
    }
  }

  &__order-up,
  &__order-down {
    padding: 4px;
    cursor: pointer;

    &.disabled {
      pointer-events: none;
    }

    &:hover {
      color: $g-color-primary;
    }
  }

  &__icon {
    width: 38px;
    height: 38px;
    background-color: $g-color-light-blue;
    color: $g-color-primary;
    border-radius: $g-value-border-radius-xl;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-right: 14px;

    &.-number {
      border-radius: 5px;
      font-weight: bold;
      margin-bottom: 4px;
      margin-top: 4px;
      width: 28px;
      height: 28px;
    }
  }

  &__term-data {
    flex-grow: 1;
  }

  &__label {
    @include font-headline-4;
    margin-right: 8px;
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media (max-width: 480px) {
      max-width: 125px;
    }
  }

  &__format {
    @include font-body-3;
    color: rgba($g-color-secondary, 0.3);
    display: inline-flex;
    align-items: center;
    vertical-align: bottom;

    &:before {
      content: '';
      display: inline-block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: rgba($g-color-secondary, 0.1);
      margin-right: 8px;
    }
  }

  &__description {
    color: rgba($g-color-secondary, 0.6);
  }

  &__checkbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    margin-right: 10px;
  }
}

.cdk-drag-preview {
  .c-term {
    width: 460px;
    box-shadow: 0 20px 100px rgba($g-color-black, 0.15);
    position: relative;
    pointer-events: auto;
    cursor: grabbing;
  }
}
