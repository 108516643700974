.c-page-outline {
  $root: &;

  &.-no-padding {
    margin: -22px -32px;
    padding: 22px 32px;

    @media screen and (max-width: 1024px) {
      margin: -20px -18px;
      padding: 20px 18px;
    }
  }

  &.-gray {
    background-color: $g-color-gray-background;
  }

  .input-label {
    @include font-body-4;
    color: rgba($g-color-dark-blue, 0.7);
  }
}
