.c-view-card {
  &__inner {
    padding: 14px;
    border-radius: 5px;
    background-color: rgba($g-color-secondary, 0.02);
  }

  &__header {
    @include font-body-4;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > span {
      color: rgba($g-color-secondary, 0.7);
    }

    > .h-icon {
      font-size: 14px;
      color: $g-color-primary;
      margin-left: auto;
    }
  }
}
