.c-field-value {
  $root: &;
  display: inline-block;

  &--hidden {
    #{$root} {
      &__inner {
        @include font-headline-3;
        top: 4px;
        position: relative;
      }

      &__toggle {
        @include font-body-5;
        margin-left: 10px;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &.is-shown {
      #{$root} {
        &__inner {
          @include font-body-3;
          top: 0;
        }
      }
    }
  }

  &__icon {
    color: rgba($g-color-primary, 0.3);
    margin-right: 0.5em;

    &:first-child {
      margin-right: 0.5em;
    }

    &.is-active {
      color: $g-color-primary;
    }
  }

  &__text {
    display: inline-flex;
    align-items: center;

    &.-light-gray {
      color: rgba($g-color-secondary, 0.2);
    }

    &.-gray {
      color: rgba($g-color-secondary, 0.5);
    }

    &.-red {
      color: $g-color-red;
    }

    &.-green {
      color: #2db1aa;
    }

    &.-primary {
      color: $g-color-primary;
    }

    &.-link {
      text-decoration: none;
      background-color: transparent;
      cursor: pointer;
    }

    &.-changed {
      @include font-headline-4;
      color: $g-color-yellow;
    }

    &.-white {
      color: $g-color-white;
    }
  }

  &--logs {
    #{$root} {
      &__key {
        @include font-headline-4;
        color: rgba(0, 14, 75, 0.3);
        margin-bottom: 2px;
      }
    }
  }

  &__deliver {
    margin: 0 0.5em;
  }

  &__shape-circle {
    width: 16px;
    height: 16px;
    border: 1px solid $g-color-gray;
    border-radius: 50%;
    margin-left: 11px;

    &.-sale-status {
      width: 8px;
      height: 8px;
      border: none;
      display: inline-block;
      margin-left: 0;
    }

    &.-active {
      background-color: $g-color-green;
    }
    &.-pending {
      background-color: $g-color-yellow;
    }
    &.-blocked {
      background-color: $g-color-red;
    }
    &.-waiting {
      background-color: $g-color-primary;
    }
    &.-paused {
      background-color: $g-color-gray;
    }
    &.-sent {
      background-color: $g-color-dark-cyan;
    }
  }

  &--key {
    display: flex;
    white-space: nowrap;

    #{$root} {
      &__key {
        display: inline-block;
        color: rgba($g-color-secondary, 0.5);
        margin-right: 8px;
      }
    }
  }

  &--key-protected {
    cursor: pointer;

    #{$root} {
      &__text {
        @include font-headline-4;
        color: rgba($g-color-secondary, 0.3);
      }
    }

    &:hover {
      #{$root} {
        &__text {
          color: $g-color-primary;
        }
      }
    }
  }

  &--view-mode {
    display: flex;
    align-items: center;

    #{$root} {
      &__icon {
        width: 22px;
        height: 22px;
        font-size: 14px;
        color: rgba($g-color-primary, 0.2);
        @include h-general-transition();
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background: $g-color-primary;
          color: $g-color-white;
        }
      }
    }
  }
}
