&--reports-creator {
  z-index: $z-widget-report-builder;

  &.-lower-z-index {
    z-index: $z-widget-report-builder - 1;
  }

  #{$root} {
    &__body {
      padding: 0;
      border: none;

      .c-line {
        margin-bottom: 14px;
      }
    }

    &__items {
      list-style: none;
      background-color: transparent;
      flex: 0 0 100%;
      padding: 14px 18px 0 14px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border: 1px solid $g-color-gray;
    }

    &__presets {
      width: 100%;
      max-width: 240px;
    }

    &__item {
      display: flex;
      align-self: center;
      align-items: center;
      padding: 15px 14px;
      border-radius: $g-value-border-radius-xl;
      border: 1px solid $g-color-gray;
      cursor: pointer;
      margin-right: 14px;
      margin-bottom: 14px;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        border-color: $g-color-primary;

        .title {
          color: $g-color-primary;
        }
      }

      &--is--active {
        background-color: $g-color-primary;
        border-color: $g-color-primary;
        pointer-events: none;

        .title,
        .p500-ui-icon {
          color: $g-color-white;
        }

        .c-icon__icon {
          color: $g-color-white;
        }

        &:hover {
          .title {
            color: $g-color-white;
          }
        }
      }

      .c-icon {
        height: 20px;
        width: 20px;
      }

      .title {
        @include font-body-3;
        margin-left: 10px;
      }

      &--preset {
        text-align: initial;
        padding: 0 24px 0 0;
        border-radius: 0;
        border: none;
        border-right: 1px solid $g-color-gray;

        &:hover {
          border-color: $g-color-gray;
        }
      }
    }

    &__group-settings,
    &__body-settings {
      width: 100%;
      padding: 18px;
      background-color: rgba($g-color-primary, 0.05);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;

      &.-comparing-analytics {
        padding: 20px 14px;
        border-radius: 10px;

        .c-widget__settings {
          margin: 0;
        }

        .c-line {
          margin: 0;
        }
      }

      &.-metrics {
        background: $g-color-sidebar-bg;
        background-position-x: center;
        background-position-y: bottom;
        justify-content: space-between;
        padding: 0 18px;
        height: 63px;

        &.-with-image {
          background: $g-color-sidebar-bg url(../../../assets/new-img/bg/bg-metrics-settings.svg) no-repeat right 0;

          @media (max-width: 1406px) {
            background-position-x: 103px;
          }
        }

        .-metrics-desktop {
          display: flex;
          overflow-x: scroll;
          column-gap: 10px;

          @media (max-width: 601px) {
            display: none;
          }
        }

        .-additional-text {
          display: flex;

          @media (max-width: 1482px) {
            display: none;
          }
        }
      }

      .-right-border {
        padding-right: 12px;
        border-right: 1px solid $g-color-gray;
        border-radius: 1px;
      }

      &__info-text {
        @include font-body-3;
        flex-direction: column;
        display: flex;
        justify-content: center;
        margin-bottom: 4px;
        color: rgba($g-color-dark-blue, 0.7);
        white-space: nowrap;

        &.-icon-square {
          p500-ui-icon {
            background-color: rgba($g-color-violet, 0.1);
            border-radius: 5px;
            margin: 0;
            padding: 6px;
          }
        }

        &.-grey {
          color: rgba($g-color-dark-blue, 0.7);
        }

        &.-black {
          color: $g-color-black;
        }

        .-icon {
          @include font-headline-3;
          margin: 6px;
          color: $g-color-violet;
        }
      }
    }

    &__settings {
      width: 100%;
      margin-bottom: 20px;

      &.--conditions {
        padding: 18px;
        background-color: rgba($g-color-secondary, 0.03);
        border-radius: 10px;
        margin-top: 20px;
      }
    }

    &__divider {
      width: 0;
      border-left: 1px solid $g-color-gray;
      margin-right: 20px;
      margin-bottom: 14px;
      display: none;

      @include media-breakpoint-up(sm) {
        display: block;
      }
    }
  }

  .-metrics-mobile {
    display: none;

    .-metrics {
      height: auto;
      padding-bottom: 13px;
      flex-wrap: wrap;
      justify-content: flex-start;
      row-gap: 8px;
      column-gap: 10px;
    }

    @media (max-width: 601px) {
      display: flex;
    }
  }
}
