&--state--error {
  label {
    color: $g-color-red;
    opacity: 0.8;
  }

  #{$root} {
    input,
    select,
    textarea,
    &__input,
    &__select,
    &__textarea {
      border-color: $g-color-red;

      &::placeholder {
        color: $g-color-red;
        font-weight: 400;
        opacity: 0.8;
      }

      &:focus {
        border-color: $g-color-red;

        & + #{$root}__message {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  .ng-value-container {
    border-color: $g-color-red;

    .ng-placeholder {
      color: $g-color-red;
      font-weight: 400;
      opacity: 0.8;
    }
  }

  #{$root}__message {
    position: absolute;
    top: calc(100% + 7px);
    font-size: 11px;
    line-height: 1em;
    font-weight: 300;
    background-color: $g-color-red;
    color: $g-color-white;
    border-radius: 5px;
    padding: 5px 7px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    opacity: 0;
    @include h-general-transition;
    @include fadeIn($duration: $g-value-animation-duration, $function: $g-value-animation-function);

    &:before {
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 4px 4px 4px;
      border-color: transparent transparent $g-color-red transparent;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -4px;
    }
  }
}
