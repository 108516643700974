.err-msg-tooltip {
  @include font-body-4;
  padding: 5px 7px;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 10px);
  background-color: #f70f0f;
  color: #fff;
  border-radius: 5px;
  z-index: 1;

  &::before {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 4px 4px;
    border-color: transparent transparent #f70f0f;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -4px;
  }
}
